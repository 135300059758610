// useConditionalRecaptcha.ts

import { useRecaptcha } from './ReCaptcha' // Adjust the path as needed

interface ConditionalRecaptchaContextProps {
  executeRecaptcha?: (action: string) => Promise<string | null>
  isRecaptchaReady?: boolean
}

export const useConditionalRecaptcha = (): ConditionalRecaptchaContextProps => {
  const ENABLE_RECAPTCHA = process.env.REACT_APP_ENABLE_RECAPTCHA === 'true'
  if (ENABLE_RECAPTCHA) {
    return useRecaptcha()
  }

  return {
    executeRecaptcha: async () => {
      console.debug('reCAPTCHA is not enabled or ready')
      return null
    },
    isRecaptchaReady: false,
  }
}

import React from 'react'
import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'

interface TextButtonProps {
  text: string
  bgColor?: string
  color: string
  tabIndex?: number
  style?: React.CSSProperties // Changed from string to React.CSSProperties
  disabled?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void // Accept onClick props
}

const StyledButton = styled(Button)<{ bgcolor?: string; textcolor: string }>(
  ({ theme, bgcolor, textcolor }) => ({
    backgroundColor: bgcolor ?? 'var(--primary-300)',
    color: `${textcolor} !important`,
    borderRadius: '8px',
    width: 'fit-content',
    height: '40px',
    fontWeight: '500',
    fontFamily: 'IBM Plex Sans',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: bgcolor ?? 'var(--primary-300)',
      opacity: 0.9,
    },
  }),
)

const TextButton: React.FC<TextButtonProps> = ({
  text,
  bgColor,
  color,
  style,
  disabled,
  tabIndex,
  onClick, // Destructure onClick from props
}) => {
  return (
    <StyledButton
      bgcolor={bgColor}
      textcolor={color}
      disabled={disabled}
      style={style}
      tabIndex={tabIndex}
      onClick={onClick} // Pass onClick to the StyledButton
    >
      {text}
    </StyledButton>
  )
}

export default TextButton

import axios from 'axios'
import Cookies from 'js-cookie'
import i18n from 'i18next'

// Create an instance of Axios with custom configurations
const axiosInstance = axios.create({})
/**
 * Request interceptor: for each request to the server,
 * attach the CSRF token if it exists.
 */
axiosInstance.interceptors.request.use(async (config) => {
  try {
    const currentLanguage = i18n.language
    if (config.url?.includes('source_document')) {
      return { ...config }
    } else {
      const csrfToken = Cookies.get('csrftoken')
      config.headers['X-CSRFToken'] = csrfToken
      config.headers['Accept-Language'] = currentLanguage
      return { ...config }
    }
  } catch (err: any) {
    throw new Error(
      `axios# Problem with request during pre-flight phase: ${err}.`,
    )
  }
})

/** Response interceptor */
axiosInstance.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response?.status) {
      const statusCode = err.response.status
      // exempt below urls
      if (
        err.config.url !== '/api/accounts/login/' &&
        err.config.url !== '/api/accounts/forgot-password/' &&
        !err.config.url.includes('/api/accounts/reset-password/')
      ) {
        if (statusCode === 401 || statusCode === 403) {
          localStorage.clear()
          window.location.href = '/'
        }
      }
    }

    throw err
  },
)

export default axiosInstance

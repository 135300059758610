import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import backend from 'i18next-xhr-backend'

// First, configure i18next with plugins and initialization options
const language = process.env.REACT_APP_I18N_LANG ?? 'en'

i18n
  .use(backend)
  .use(initReactI18next)
  .init({
    lng: language,
    fallbackLng: 'en',
    load: 'languageOnly', // This loads only the specified language avoiding fallbacks to default ns.
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    interpolation: {
      escapeValue: false,
    },
    saveMissing: true, // Enable during development to save missing keys
    missingKeyHandler: function (lng, ns, key, fallbackValue) {
      console.debug(
        `Missing translation: ${lng}, ${ns}, ${key}, ${fallbackValue}`,
      )
    },
  })

export default i18n

// Handling language change
const changeLanguage = (language) => {
  i18n.changeLanguage(language, (err, t) => {
    if (err) return console.debug('error changing language', err)
    console.debug('language changed')
  })
}

changeLanguage(language) // Change to Japanese

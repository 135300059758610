import React, { useState, useRef, useEffect } from 'react'

// Import your variables.scss to use the variables
import '../../styles/variables.scss'
import {
  Box,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Tab,
  TablePagination,
  Tabs,
  TextField,
  Typography,
  InputLabel,
  CircularProgress,
} from '@mui/material'
import { ReactComponent as MagniferIcon } from '../../assets/images/magnifer.svg'
import OutlineTextButton from '../../components/button/textOutlineButton'
import TextButton from '../../components/button/textButton'
import type { MRT_ColumnDef, MRT_SortingState } from 'material-react-table'
import type { SelectChangeEvent } from '@mui/material/Select'
import { ReactComponent as AddIcon } from '../../assets/images/add.svg'
import ButtonIcon from '../../components/button/iconButton'
import TableComponent from '../../components/table/table'
import '../project-setting/project-setting.scss'
import FormDialog from '../../components/form-dialog/formDialog'
import InputBox from '../../components/input/input'
import { SingleUpload } from '../../components/file-upload/singleUpload'
import ByteConverter from '../../components/file-upload/byteConverter'
import { enqueueSnackbar } from 'notistack'
import CancelConformationFormDialog from '../../components/form-dialog/cancel-conformation'
import ActionDialog from './addMembers'
import { createProjectAPI } from '../home/home-api-services'
import {
  getPreviousFilesAPI,
  getTargetDocumentDataAPI,
  replaceProjectAPI,
} from './project-setting-api-serive'
import FailedIcon from '../../assets/images/failed.svg'
import DateFormatter from '../../utils/dateFormatter'
import { ReactComponent as XIcon } from '../../assets/images/x.svg'
import TruncateText from '../../components/tooltip/truncate'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { getLocalizedPath } from '../../utils/shareLocalizePath'
// const controllerRef = useRef<AbortController | null>(null)

interface TargetDocsProps {
  projectId: any
}

interface RowData {
  id: number
  name: string
  is_previous_version: boolean
  status: string
  matched_results: number
  best_rank: number
  created_by: string
  created_at: string
  last_modified_at: string
  last_modified_by: string
  action: any

  is_previous_file: boolean
}

interface PreviousFileRow {
  id: number
  name: string
  created_by: string
  created_at: string
}

const rows: RowData[] = []

export const TargetDocumentTab: React.FC<TargetDocsProps> = (props) => {
  // Accessing projectId from props
  const { projectId } = props
  const controllerRef = useRef<AbortController | null>(null)
  const [searchTerm, setNewSearchTerm] = useState('')
  const [completeSearchTerm, setCompleteSearchTerm] = useState('')
  const [targetId, setTargetId] = useState<number>()

  const [isMounted, setIsMounted] = useState<boolean | any>(false)

  // Table states
  const [isLoading, setTargetDocumentLoading] = useState<boolean | any>(false)
  const [isPrevFilesLoading, setPrevFilesLoading] = useState<boolean | any>(
    false,
  )

  const [targetDocs, setTargetDocuments] = useState<any[]>([])
  const [previousFiles, setPreviousFilesData] = useState<any[]>([])

  // Pagination use state
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState(() => {
    const savedRowsPerPage = localStorage.getItem(`targetDocRowsPerPage`)
    return savedRowsPerPage ? parseInt(savedRowsPerPage, 10) : 10
  })
  const [rowCount, setRowCount] = useState<any>(1)
  const [sorting, setSorting] = useState<MRT_SortingState>([])

  const [filter, setFilter] = useState('active')

  const [file, setFile] = useState<File | null>(null)
  const [uploadProgress, setUploadProgress] = useState<any>(null)
  const [isProgressComplete, setIsProgressComplete] = useState<boolean>(false)
  const [isFileSizeValid, setFileSizeValidation] = useState<boolean>(true)
  // Cancel upload
  const [isCancelUploadConfirmDialogOpen, setCancelUploadConfirmDialogOpen] =
    useState(false)
  const [cancelResult, setCancelResult] = useState<string>()

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files ? e.target.files[0] : null
    setFile(selectedFile)
    if (selectedFile && selectedFile.size < maxFileSize) {
      setFileSizeValidation(true)
    } else {
      setFileSizeValidation(false)
    }
  }
  // Cancel Replace
  const [isCancelReplaceConfirmDialogOpen, setCancelReplaceConfirmDialogOpen] =
    useState(false)
  // Pervious Versions
  const [isPerviousVersionDialogOpen, setPreviousVersionsDialog] =
    useState(false)
  const disableReplaceButton =
    !file || uploadProgress !== null || !isFileSizeValid

  const rows: RowData[] = []
  const prevFilesRows: RowData[] = []
  const { t, i18n } = useTranslation()
  const currentLanguage = i18n.language

  const maxFileSize = 30 * 1024 * 1024
  const [
    isReplaceTargetDocumentDialogOpen,
    setOpenReplaceTargetDocumentDialog,
  ] = useState(false)

  useEffect(() => {
    setIsMounted(true) // Set isMounted to true when component is mounted
    return () => {
      setIsMounted(false) // Set isMounted to false when component is unmounted
    }
  }, [])

  useEffect(() => {
    if (isMounted) {
      getTargetDocumentData()
    }
  }, [isMounted, page, rowsPerPage, completeSearchTerm])

  // To handle cancelation of file upload.
  useEffect(() => {
    if (cancelResult === 'confirm') {
      handleFileUploadCancel()
    } else {
      setCancelUploadConfirmDialogOpen(false)
    }
  }, [cancelResult])
  useEffect(() => {
    const savedRowsPerPage = localStorage.getItem(`targetDocRowsPerPage`)
    if (savedRowsPerPage) {
      setRowsPerPage(parseInt(savedRowsPerPage, 10))
    }
  }, [location])

  useEffect(() => {
    localStorage.setItem(`targetDocRowsPerPage`, rowsPerPage.toString())
  }, [rowsPerPage])
  const getTargetDocumentData = () => {
    const params = {
      page_size: rowsPerPage,
      page: page + 1,
      type: filter,
      query: completeSearchTerm,
    }

    setTargetDocumentLoading(true)
    getTargetDocumentDataAPI(params, projectId)
      .then((res) => {
        if (res) {
          setTargetDocumentLoading(false)
          setTargetDocuments([...res.data])
          setRowCount(res.total_count)
        }
      })
      .catch((error) => {
        if (error?.response?.request?.status !== 500) {
          enqueueSnackbar(t(`Something went wrong`), {
            variant: 'error',
            persist: true,
          })
        }
      })
  }

  const getPreviousFiles = (targetID: any) => {
    setPrevFilesLoading(true)
    getPreviousFilesAPI(projectId, targetID)
      .then((res) => {
        if (res) {
          setPrevFilesLoading(false)
          setPreviousFilesData([...res.data])
        }
      })
      .catch((error) => {
        if (error?.response?.request?.status !== 500) {
          enqueueSnackbar(t(`Something went wrong`), {
            variant: 'error',
            persist: true,
          })
        }
      })
  }

  // Pagination
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  //  Handle Search
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewSearchTerm(event.target.value)
  }

  const handleSearch = () => {
    setPage(0)

    setCompleteSearchTerm(searchTerm)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }
  const handleOpenReplaceConfirmDialog = () => {
    setCancelReplaceConfirmDialogOpen(true)
  }

  const handlCloseCancelReplaceConfirmDialog = (result: string) => {
    // setCancelResult(result) // Set the result received from the dialog
    if (result === 'confirm') {
      setCancelReplaceConfirmDialogOpen(false)
      setOpenReplaceTargetDocumentDialog(true)
    } else {
      setCancelReplaceConfirmDialogOpen(false)
    }
    setFileSizeValidation(true)
  }
  // Open previous Version dialog
  const handleOpenPreviousVersionsDialog = (targetID) => {
    setPreviousVersionsDialog(true)

    getPreviousFiles(targetID)
  }
  const handlClosePreviousVersionsDialog = (result?: string) => {
    // setCancelResult(result) // Set the result received from the dialog
    setPreviousVersionsDialog(false)
  }

  const handleCloseReplaceTargetDocumentDialog = () => {
    setOpenReplaceTargetDocumentDialog(false)
  }

  // To set cancel conformation dialog box
  const handleOpenCancelUploadConfirmDialog = () => {
    setCancelUploadConfirmDialogOpen(true)
  }
  const handlCloseCancelUploadConfirmDialog = (result: string) => {
    setCancelResult(result) // Set the result received from the dialog
    setCancelUploadConfirmDialogOpen(false)
  }
  const handleFileUploadCancel = () => {
    if (controllerRef.current) {
      controllerRef.current.abort()
      setCancelUploadConfirmDialogOpen(false)
      enqueueSnackbar(t(`Cancelled file upload`), {
        variant: 'error',
        persist: true,
      })
      setFile(null)
      setUploadProgress(null)
    }
    handleCloseReplaceTargetDocumentDialog()
    // setIsCreateProjectOpen(false)
  }

  // To set file and project name through props & close dialog
  const replaceProject = (e: any) => {
    setIsProgressComplete(false)
    // Set up AbortController
    const controller = new AbortController()
    // controllerRef.current = controller
    var productFormData = new FormData()

    if (file) {
      const onUploadProgress = (progressEvent: any) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        )
        setUploadProgress(
          (prevProgress: { filename: string; progress: number }) => ({
            filename: file.name,
            progress: Math.max(progress, prevProgress?.progress ?? 0),
          }),
        )
      }

      productFormData.append('target_document', file)

      replaceProjectAPI(
        productFormData,
        projectId,
        targetId,
        onUploadProgress,
        controller.signal,
      )
        .then((res) => {
          if (res.status === 200) {
            // const projectId = res.data['project_id']
            setFile(null)
            setUploadProgress(null)
            setIsProgressComplete(true)
            handleCloseReplaceTargetDocumentDialog()
            enqueueSnackbar(`${res.data.detail}`, {
              variant: 'success',
              persist: true,
            })
            getTargetDocumentData()
          }
        })
        .catch((error) => {
          handleCloseReplaceTargetDocumentDialog()
          setFile(null)
          setOpenReplaceTargetDocumentDialog(true)

          setUploadProgress(null)
          setFileSizeValidation(true)
          if (
            error?.response?.request?.status !== 500 &&
            error?.response?.request?.status !== 502
          ) {
            if (error?.response?.data?.detail)
              enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
                variant: 'error',
                persist: true,
              })
          }
          if (error?.response?.request?.status === 502) {
            enqueueSnackbar(`Something went wrong`, {
              variant: 'error',
              persist: true,
            })
          }
        })
    }
  }
  const formatDateToLocalTime = (utcDateString) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const date = moment(utcDateString)
    const formattedDate = date.format('YYYY/MM/DD')
    const formattedTime = date.format('HH:mm')
    return `${formattedDate} ${formattedTime}`
  }

  // The columns definition should match the MRT_ColumnDef type
  const columns: Array<MRT_ColumnDef<RowData>> = [
    {
      accessorKey: 'name',
      header: 'FILE NAME',

      enableSorting: false,
      accessorFn: (row) => (
        <div>
          <Link
            to={getLocalizedPath(
              currentLanguage,
              `/projects?project_id=${projectId}&target_id=${row.id}`,
            )}
          >
            <div className="blue-header-font">
              {row.name.length > 25 ? (
                <TruncateText text={row?.name} maxLength={25} />
              ) : (
                row?.name
              )}
            </div>
          </Link>
          {row.is_previous_version ? (
            <div
              className="grey-subtitle-font underline"
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                handleOpenPreviousVersionsDialog(row?.id)
              }}
            >
              {t('Previous Versions')}
            </div>
          ) : (
            <></>
          )}
        </div>
      ),
    },
    {
      accessorKey: 'status',
      header: t('STATUS'),

      enableSorting: false,
      accessorFn: (row) => (
        <div>
          <div className="grey-header-font">
            {row.status}
            <span>
              {row.status === 'failed' && (
                <Box>
                  <img
                    src={FailedIcon}
                    alt="Failed"
                    style={{ margin: '0 3px' }}
                  ></img>
                </Box>
              )}
            </span>
          </div>
          {/* {row.matched_results < 100 ? (
            <div className="grey-subtitle-font">{row.matched_results}%</div>
          ) : (
            <div className="green-subtitle-font ">{row.matched_results}%</div>
          )} */}
        </div>
      ),
      // grow: true,
    },
    {
      accessorKey: 'created_by',
      header: t('UPLOADED BY'),

      enableSorting: false,
      accessorFn: (row) => (
        <div>
          <div className="grey-header-font">{row.created_by}</div>
          <div className="grey-subtitle-font">
            <DateFormatter dateString={formatDateToLocalTime(row.created_at)} />
          </div>
        </div>
      ),
    },
    {
      accessorKey: 'last_modified_by',
      header: t('UPDATED BY'),

      enableSorting: false,
      accessorFn: (row) => (
        <div>
          <div className="grey-header-font">{row.last_modified_by}</div>
          <div className="grey-subtitle-font">
            <DateFormatter dateString={row.last_modified_at.toString()} />
          </div>
        </div>
      ),
    },
    {
      accessorKey: 'action',
      header: t('ACTIONS'),
      size: 40,
      enableSorting: false,
      accessorFn: (row) => (
        <div>
          <OutlineTextButton
            text={t('Replace')}
            bgColor={'#fff'}
            color={'var(--primary-600)'}
            outlineColor={'var(--primary-600)'}
            // bgColor={
            //   disableInviteButton
            //     ? 'var(--primary-300)' // Use primary-300 for the disable color
            //     : 'var(--primary-600)'
            // }
            // disabled={disableInviteButton}
            onClick={() => {
              handleOpenReplaceConfirmDialog()
              setTargetId(row.id)
            }}
            style={{
              width: '71px',
              height: '34px',
              fontSize: '12px',
              fontWeight: '500',
              lineHeight: '18px',
            }}
          />
        </div>
      ),
    },
  ]

  const columnsPreviousFiles: Array<MRT_ColumnDef<RowData>> = [
    {
      accessorKey: 'name',
      header: t('FILE NAME'),
      maxSize: 120,
      enableSorting: false,
      accessorFn: (row) => (
        <div>
          <div className="blue-header-font ">
            {row.name.length > 55 ? (
              <TruncateText text={row?.name} maxLength={55} />
            ) : (
              row?.name
            )}
          </div>
        </div>
      ),
    },
    {
      accessorKey: 'created_by',
      header: t('UPLOADED BY'),
      size: 120,
      enableSorting: false,
      accessorFn: (row) => (
        <div>
          <div className="grey-header-font">
            {row.created_by.length > 28 ? (
              <TruncateText text={row?.created_by} maxLength={28} />
            ) : (
              row?.created_by
            )}
          </div>
          <div className="grey-subtitle-font">
            <DateFormatter dateString={formatDateToLocalTime(row.created_at)} />
          </div>
        </div>
      ),
    },
  ]
  return (
    <div className="tabs-wrapper">
      <div className="search-bar-container">
        <div className="filters">
          <TextField
            id="search-projects"
            placeholder={t('Search Documents')}
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleSearchChange}
            onKeyDown={handleKeyPress}
            style={{
              width: '500px',
              flex: 1,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MagniferIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <>
                    {searchTerm.length > 0 && (
                      <XIcon
                        className="global-close-btn"
                        onClick={() => {
                          setCompleteSearchTerm('')
                          setNewSearchTerm('')
                        }}
                      />
                    )}
                  </>
                </InputAdornment>
              ),
              style: {
                borderTopLeftRadius: '8px',
                borderBottomLeftRadius: '8px',
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
              },
            }}
          />
          <TextButton
            text={t('Search')}
            color={'var(--gray-white)'}
            bgColor={searchTerm ? 'var(--primary-600)' : 'var(--primary-300)'}
            disabled={!searchTerm}
            onClick={handleSearch}
            style={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              // To create the effect of the button being attached to the search bar
            }}
          />
        </div>
      </div>

      <div className="tabs-table-container">
        {isLoading ? (
          <div style={{ padding: '11rem' }}>
            <CircularProgress />
          </div>
        ) : (
          <TableComponent
            rows={targetDocs}
            columns={columns}
            errorMessage={t('No documents found')}
            sorting={sorting}
            setSorting={setSorting}
          />
        )}
      </div>
      {targetDocs.length > 0 && !isLoading && (
        <TablePagination
          component="div"
          page={page}
          count={rowCount}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 20, 30]}
          labelRowsPerPage={t('Items per page:')}
        />
      )}

      <ActionDialog
        open={isPerviousVersionDialogOpen}
        maxWidth="sm"
        title={t('Previous Files')}
        onClose={() => {
          handlClosePreviousVersionsDialog()
        }}
      >
        <div
          style={{
            border: '1px solid var(--gray-200)',
            borderRadius: '8px',
            width: '34rem',
          }}
        >
          {isPrevFilesLoading ? (
            <div style={{ padding: '9rem', textAlign: 'center' }}>
              <CircularProgress />
            </div>
          ) : (
            <TableComponent
              rows={previousFiles}
              columns={columnsPreviousFiles}
              isSort={false}
              sorting={[]}
              enableBottomTool={false}
              errorMessage={t('No files found')}
            />
          )}
        </div>
      </ActionDialog>

      <FormDialog
        open={isReplaceTargetDocumentDialogOpen}
        title={t('Replace Target Document')}
        onClose={() => {
          uploadProgress >= 0
            ? handleCloseReplaceTargetDocumentDialog()
            : handleOpenCancelUploadConfirmDialog()
        }}
        setFile={setFile}
        setUploadProgress={setUploadProgress}
      >
        <Stack display={'flex'} direction={'column'} gap={2}>
          {/* Dialog ProjectName input field */}

          <FormControl variant="standard">
            {/* Dialog Upload Part using SingleUpload component */}
            <SingleUpload
              setFile={setFile}
              file={file}
              handleFileChange={handleFileChange}
              uploadProgress={uploadProgress}
              isProgressComplete={isProgressComplete}
              placeholder={t('Add a Target Document')}
              documentTitle={t('Target Document')}
              buttonName={t('Choose')}
              color={'var(--gray-white)'}
              bgColor={
                uploadProgress && !isProgressComplete
                  ? 'var(--primary-300)'
                  : 'var(--primary-600)'
              }
              style={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderTopRightRadius: '8px',
                borderBottomRightRadius: '8px',
                marginTop: '25px',
                paddingTop: '9px',
                paddingBottom: '8px',
                minWidth: 'fit-content',
              }}
            />
            <div className="file-size-error">
              {!isFileSizeValid && <>{t('File exceeds maximum limit')}</>}
            </div>
            <div className="selected-file-size">
              {file && uploadProgress >= 0 && (
                <ByteConverter bytes={file?.size} decimal={2} />
              )}
            </div>
          </FormControl>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {uploadProgress <= 0 ? (
              <TextButton
                text={t('Replace')}
                color={'#fff'}
                bgColor={
                  disableReplaceButton
                    ? 'var(--primary-300)' // Use primary-300 for the disable color
                    : 'var(--primary-600)'
                }
                disabled={disableReplaceButton}
                onClick={replaceProject}
                style={{
                  width: '100%',
                  fontSize: '14px',
                  textTransform: 'capitalize',
                  boxShadow: 'none',
                  padding: '8px 0',
                }}
              />
            ) : (
              <OutlineTextButton
                text={t('Cancel')}
                bgColor={'#fff'}
                color={'var(--primary-600)'}
                outlineColor={'var(--primary-600)'}
                // bgColor={
                //   disableInviteButton
                //     ? 'var(--primary-300)' // Use primary-300 for the disable color
                //     : 'var(--primary-600)'
                // }
                // disabled={disableInviteButton}
                // onClick={onInviteClick}
                style={{
                  width: '100%',
                  height: '42px',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
                onClick={handleOpenCancelUploadConfirmDialog}
                // onClick={handleFileUploadCancel}
              />
            )}
          </div>
        </Stack>
      </FormDialog>

      {/*  On cancel of upload file */}
      <CancelConformationFormDialog
        open={isCancelUploadConfirmDialogOpen}
        onClose={handlCloseCancelUploadConfirmDialog}
        message={t('Your progress will be lost, Are you sure?')}
      ></CancelConformationFormDialog>

      <CancelConformationFormDialog
        open={isCancelReplaceConfirmDialogOpen}
        onClose={handlCloseCancelReplaceConfirmDialog}
        message={t(
          'Replacing target document is a long process. We should start background process again. Are you sure?',
        )}
      ></CancelConformationFormDialog>
    </div>
  )
}
export default TargetDocumentTab

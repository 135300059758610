import {
  Routes,
  Route,
  useLocation,
  Navigate,
  useNavigate,
} from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Topbar from './components/topbar/topbar'
import Home from './modules/home/home'
import InviteCode from './modules/auth/signup/inviteCode'
import Login from './modules/auth/signup/login'
import UploadSearchCollation from './modules/uploadSearchCollation/uploadSearchCollation'
import ForgotPasword from './modules/auth/signup/forgotPassword'
import UserManagement from './modules/user-management/userManagement'
import ResetPasswordCode from './modules/auth/signup/resetPasswordCode'
import ProtectedRoute from './utils/protected-route'
import ProjectSetting from './modules/project-setting/project-setting'
import ResultComponent from './modules/results/results'
import ErrorPage from './modules/Error/ErrorPage'

export default function Router(): JSX.Element {
  const location = useLocation()
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language
  const navigate = useNavigate()
  const [isChangingLanguage, setIsChangingLanguage] = useState(false)
  const ENABLE_RECAPTCHA = process.env.REACT_APP_ENABLE_RECAPTCHA === 'true'

  useEffect(() => {
    const pathname = location.pathname
    const langPrefix = pathname.split('/')[1]
    // Check if the langPrefix is a supported language
    if (['en', 'ja'].includes(langPrefix) && langPrefix !== currentLanguage) {
      setIsChangingLanguage(true)
      // Change the language in i18n
      i18n.changeLanguage(langPrefix).then(() => {
        // Update the currentLanguage with the new prefix
        const newPrefix = `/${langPrefix}${
          pathname.startsWith(`/${langPrefix}`)
            ? pathname.replace(`/${langPrefix}`, '')
            : pathname.startsWith(`/${currentLanguage}`)
              ? pathname.replace(`/${currentLanguage}`, '')
              : pathname
        }`
        navigate(`${newPrefix}${location.search}`, { replace: true })
        setIsChangingLanguage(false)
      })
    } else if (!['en', 'ja'].includes(langPrefix)) {
      // Default to the current language if no valid langPrefix is found
      const newPrefix = `/${currentLanguage}${pathname}`
      navigate(`${newPrefix}${location.search}`, { replace: true })
    }

    const onLoadHandler = () => {
      const recaptchaBadge: any = document.querySelector('.recaptcha-usage')
      if (ENABLE_RECAPTCHA) {
        if (recaptchaBadge) {
          if (
            pathname === `/${currentLanguage}/` ||
            pathname === `/${currentLanguage}` ||
            pathname.includes('reset-password') ||
            pathname.includes('set-password') ||
            pathname.includes('forgot-password')
          ) {
            recaptchaBadge.style.visibility = 'visible'
          } else {
            recaptchaBadge.style.visibility = 'hidden'
          }
        } else if (
          !recaptchaBadge &&
          (pathname === `/${currentLanguage}/` ||
            pathname === `/${currentLanguage}` ||
            pathname.includes('reset-password') ||
            pathname.includes('set-password') ||
            pathname.includes('forgot-password'))
        ) {
          setTimeout(onLoadHandler, 500) // Retry after 500ms
        }
      }
    }

    ENABLE_RECAPTCHA && requestAnimationFrame(onLoadHandler)
  }, [i18n, currentLanguage, location.pathname, location.search, navigate])

  return (
    <>
      {/* Top navigation bar */}
      {!isChangingLanguage &&
        location.pathname !== `/${currentLanguage}/signup` &&
        location.pathname !== `/${currentLanguage}/forgot-password` &&
        !location.pathname.includes('password') &&
        location.pathname !== `/${currentLanguage}/` &&
        location.pathname !== `/${currentLanguage}` && <Topbar />}

      <Routes>
        {/* Default redirect to English if no language is provided */}
        <Route path="/" element={<Navigate to="/en" replace />} />
        {/* Handle all paths without language prefix */}
        <Route
          path="/*"
          element={
            !location.pathname.startsWith('/en') &&
            !location.pathname.startsWith('/ja') ? (
              <Navigate
                to={`/${currentLanguage}${location.pathname}${location.search}`}
                replace
              />
            ) : (
              <Routes>
                <Route path="*" element={<ErrorPage />} />
              </Routes>
            )
          }
        />
        {/* Public routes */}
        <Route
          path={`/${currentLanguage}`}
          element={
            localStorage.getItem('isAuthenticated') &&
            localStorage.getItem('isAuthenticated') === 'true' ? (
              <Navigate to={`/${currentLanguage}/home`} />
            ) : (
              <Login />
            )
          }
        />
        <Route
          path={`/${currentLanguage}/accounts/set-password/:token`}
          element={<InviteCode />}
        />
        <Route
          path={`/${currentLanguage}/accounts/set-password/`}
          element={<InviteCode />}
        />
        <Route
          path={`/${currentLanguage}/forgot-password`}
          element={<ForgotPasword />}
        />
        <Route
          path={`/${currentLanguage}/accounts/reset-password/:token`}
          element={<ResetPasswordCode />}
        />
        <Route
          path={`/${currentLanguage}/accounts/reset-password/`}
          element={<ResetPasswordCode />}
        />
        <Route
          path={`/${currentLanguage}/home`}
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        {/* Route without filterParam */}
        <Route
          path={`/${currentLanguage}/user-management`}
          element={
            <Navigate
              to={`/${currentLanguage}/user-management/active`}
              replace
            />
          }
        />
        <Route
          path={`/${currentLanguage}/user-management/:filterParam`}
          element={
            <ProtectedRoute requiredRoles={['admin']}>
              <UserManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/${currentLanguage}/projects`}
          element={
            <ProtectedRoute>
              <UploadSearchCollation />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/${currentLanguage}/project-setting`}
          element={
            <ProtectedRoute requiredRoles={['admin', 'manager']}>
              <ProjectSetting />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/${currentLanguage}/result`}
          element={
            <ProtectedRoute>
              <ResultComponent />
            </ProtectedRoute>
          }
        />
        {/* Catch-all route for handling 404 - Not Found */}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  )
}

import axiosInstance from '../../services/http-interceptors'

export const BASE_URL = ''
const controller = new AbortController()

// function to call get API to get projects
export const getProjects = async (params?: any) => {
  try {
    const response = await axiosInstance.get(`/api/project/`, {
      params: params,
    })
    return response.data
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// function to call post API to create Project
export const createProjectAPI = async (
  data: any,
  onUploadProgress: (progressEvent: any) => void,
  signal,
) => {
  const response = await axiosInstance.post('/api/project/', data, {
    onUploadProgress: onUploadProgress,
    signal,
  })
  return response
}

// function to call delete API to delete Project
export const deleteProject = async (id: any) => {
  try {
    const response = await axiosInstance.delete(`/api/project/${id}/`)
    return response.data
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// function to call  API to delete Project
export const archiveAPI = async (id: any, params: any) => {
  try {
    const response = await axiosInstance.patch(
      `/api/project/${id}/archive/`,
      params,
    )
    return response.data
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

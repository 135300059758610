/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useContext, useEffect, useState } from 'react'
import Tip from '../../lib/Tip'
import { PdfHighlighter } from '../../lib/PdfHighlighter'
import PdfLoader from '../../lib/PdfLoader'
import { Spinner } from '../spinner/spinner'
import Highlight from '../../lib/Highlight'
import Popup from '../../lib/Popup'
import {
  ProjectContext,
  StatusContext,
  highlightsContext,
} from '../../context/globalContext'
import AreaHighlight from '../../lib/AreaHighlight'
import {
  createROI,
  createSourcePhrase,
  getAreaList,
  getProjectStatus,
  getSourcePhraseByPhraseId,
  getSourcePhrasesByAreaId,
  updateROI,
  updateSourcePhraseByPhraseId,
} from '../../services/collation-service'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { useSearchParams } from 'react-router-dom'

interface Props {
  targetOnly?: any
  isTarget?: boolean
  hideSourceControls?: boolean
  setIstarget?: any
  sourceTitle: string
  projectId: any
}

const parseIdFromHash = () => {
  const hash = window.location.hash.substr(1)
  const parts = hash.split(';')
  const state = {
    phrase: parts[0].slice('phrase-'.length) || '',
    collation: parts.length > 1 ? parts[1].slice('collation-'.length) : '',
  }
  return state.phrase
}
export const SourcePdf = ({
  targetOnly,
  isTarget,
  setIstarget,
  hideSourceControls,
  sourceTitle,
  projectId,
}: Props) => {
  const [numPages, setNumPages] = useState(0)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [pagesRotation, setPagesRotation] = useState<number>(0)
  const [pdfTitle, setPdfTitle] = useState<string>(sourceTitle ?? '')
  const [url, setUrl] = useState<string>('')
  const [tempHighlight, setTempHighlight] = useState<any>([])
  const {
    selectedHighlight,
    highlightAllStates,
    collationName,
    handleGetAreaList,
    handleGetROIs,
    isPhraseEditMode,
    setIsPhraseEditMode,
    setSelectedHighlight,
    setCollationName,
    areaList,
    isManualCollationInProgress,
    isPhraseAddMode,
    setSelectedHighlightId,
    selectedHighlightId,
    handleProjectStatus,
  } = useContext(highlightsContext)
  const {
    setCollationStatus,
    setTargetDocumentStatus,
    setSourcePhraseStatus,
    setProjectStatus,
    setTargetFinalizeStatus,
  } = useContext<any>(StatusContext)
  const [queryParameters] = useSearchParams()
  const areaId = queryParameters.get('area_id')
  const { t } = useTranslation()
  let scrollViewerTo: any = (highlight: any) => {}

  const scrollToHighlightFromHash = () => {
    const highlightId = parseIdFromHash()
    const highlight = getHighlightById(highlightId)
    if (highlight) {
      scrollViewerTo(highlight)
    }
  }
  useEffect(() => {
    if (projectId) {
      handleProjectStatus(projectId)
    }
    if (projectId && !url) {
      setUrl(`/api/project/${projectId}/source_document/`)
    }
  }, [projectId])

  useEffect(() => {
    // Ensure selectedHighlight is an array and has at least one element
    if (selectedHighlight?.length > 0) {
      // Safely access nested properties
      const rects = selectedHighlight[0]?.position?.rects

      // Check if rects is not an array
      if (!Array.isArray(rects)) {
        setSelectedHighlight([])
      }
    }
  }, [selectedHighlight])

  useEffect(() => {
    window.addEventListener('hashchange', scrollToHighlightFromHash, false)
    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('hashchange', scrollToHighlightFromHash, false)
    }
  }, []) // Empty dependency array to run the effect only once on component mount

  const getHighlightById = (id) => {
    return selectedHighlight?.find((highlight) => highlight.id === +id)
  }

  useEffect(() => {
    setPdfTitle(sourceTitle)
  }, [sourceTitle])

  const addSourcePhrase = (highlight: any, comment: string) => {
    const phraseFormData = {
      text: highlight.content.text,
      position: highlight.position,
      area: comment,
    }

    createSourcePhrase(projectId, phraseFormData)
      .then((res) => {
        if (res.status === 200 && projectId) {
          handleProjectStatus(projectId)
          handleGetAreaList(projectId)
          document.location.hash = `phrase-${res.data.data.id}`
          if (
            areaList.some(
              (item) =>
                (item.name === collationName || item.name === comment) &&
                item.name !== '',
            )
          ) {
            const area = areaList.find((d) => {
              return (
                (d.name === collationName || d.name === comment) &&
                d.name !== ''
              )
            })
            setIsPhraseEditMode({
              isEditMode: false,
              areaId: area.id,
              phraseId: res.data.data.id,
              phrases: [],
            })
          } else {
            setIsPhraseEditMode({
              isEditMode: false,
              areaId: null,
              phraseId: null,
              phrases: [],
            })
          }
        }
        setCollationName('')
      })
      .catch((error) => {
        setIsPhraseEditMode({
          isEditMode: false,
          areaId: null,
          phraseId: null,
          phrases: [],
        })
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (error?.response?.data?.detail && axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          setCollationName('')
          enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        } else if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }
  const addROI = (highlight: any, comment: string) => {
    const customPosition = {
      x1: highlight?.position?.boundingRect?.x1,
      y1: highlight?.position?.boundingRect?.y1,
      x2: highlight?.position?.boundingRect?.x2,
      y2: highlight?.position?.boundingRect?.y2,
      width: highlight?.position?.boundingRect?.width,
      height: highlight?.position?.boundingRect?.height,
      pageNumber: highlight?.position?.pageNumber,
    }
    const phraseFormData = {
      area: comment,
      position: customPosition,
    }

    createROI(projectId, phraseFormData)
      .then((res) => {
        if (res.status === 200 && projectId) {
          handleProjectStatus(projectId)
          handleGetROIs(projectId)
        }
      })
      .catch((error) => {
        setIsPhraseEditMode({
          isEditMode: false,
          areaId: null,
          phraseId: null,
          phrases: [],
        })
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (error?.response?.data?.detail && axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }
  const handleUpdateROI = (highlight: any, comment: string) => {
    const body = {
      area: comment === '-' ? null : comment,
    }
    updateROI(projectId, body, isPhraseEditMode.phraseId)
      .then((res) => {
        if (res.status === 200 && projectId) {
          handleProjectStatus(projectId)
          handleGetROIs(projectId)
          setCollationName('')
          setIsPhraseEditMode({
            isEditMode: false,
            areaId: null,
            phraseId: null,
            phrases: [],
          })
        }
      })
      .catch((error) => {
        setIsPhraseEditMode({
          isEditMode: false,
          areaId: null,
          phraseId: null,
          phrases: [],
        })
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }
  const updateSourcePhrase = (highlight: any, comment: string) => {
    const phraseId = isPhraseEditMode.phraseId
    const areaId = isPhraseEditMode.areaId
    const phraseFormData = {
      text: highlight.content.text,
      position: highlight.position,
      area: comment,
    }

    updateSourcePhraseByPhraseId(projectId, phraseFormData, phraseId)
      .then((res) => {
        if (res.status === 200 && projectId) {
          handleProjectStatus(projectId)
          setCollationName('')
          document.location.hash = `phrase-${phraseId}`
          setIsPhraseEditMode({
            isEditMode: false,
            areaId,
            phraseId,
            phrases: [],
          })
        }
      })
      .catch((error) => {
        setIsPhraseEditMode({
          isEditMode: false,
          areaId: null,
          phraseId: null,
          phrases: [],
        })
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }

  const handleCloseTip = (hideTipAndSelection) => {
    hideTipAndSelection()
    setCollationName('')
    setIsPhraseEditMode({
      isEditMode: false,
      areaId: null,
      phraseId: null,
      phrases: [],
    })
  }

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
    >
      {/* Render a PdfHighlighter with the generated PDF document */}
      {url && (
        <PdfLoader
          url={url}
          beforeLoad={<Spinner />}
          setPdfTitle={setPdfTitle}
          setNumPages={setNumPages}
          setCurrentPage={setCurrentPage}
        >
          {(pdfDocument) => (
            <PdfHighlighter
              pdfDocument={pdfDocument}
              targetOnly={targetOnly}
              setIstarget={setIstarget}
              hideSourceControls={hideSourceControls}
              isTarget={isTarget}
              fromTarget={false}
              page={currentPage}
              totalPages={numPages}
              pagesRotation={pagesRotation}
              setPagesRotation={setPagesRotation}
              pdfTitle={pdfTitle}
              isSearchPossible={true}
              highlightAllStates={highlightAllStates}
              areaId={areaId}
              t={t}
              enableAreaSelection={(event) => {
                return event.altKey
              }}
              // onScrollChange={resetHash}
              // pdfScaleValue="page-width"
              scrollRef={(scrollTo) => {
                scrollViewerTo = scrollTo
                scrollToHighlightFromHash()
              }}
              onSelectionFinished={(
                position,
                content,
                hideTipAndSelection,
                transformSelection,
              ) =>
                !isManualCollationInProgress ? (
                  <Tip
                    onOpen={transformSelection}
                    handleClose={() => {
                      setTempHighlight([])
                      const selection: any = window.getSelection()
                      selection.rangeCount > 0 && selection.removeAllRanges()
                      handleCloseTip(hideTipAndSelection)
                      if (isPhraseAddMode.phraseId && isPhraseAddMode.phrases) {
                        setSelectedHighlight(isPhraseAddMode.phrases)
                        setSelectedHighlightId(isPhraseAddMode.phraseId)
                        document.location.hash = `phrase-${isPhraseAddMode.phraseId}`
                      }
                    }}
                    collationName={collationName}
                    isEditMode={isPhraseEditMode.isEditMode}
                    onConfirm={(comment) => {
                      if (isPhraseEditMode.isEditMode) {
                        content?.image
                          ? handleUpdateROI(
                              { content, position },
                              collationName,
                            )
                          : updateSourcePhrase(
                              { content, position },
                              collationName,
                            )
                      } else {
                        content?.image
                          ? addROI({ content, position }, comment)
                          : addSourcePhrase({ content, position }, comment)
                      }
                      handleCloseTip(hideTipAndSelection)
                    }}
                    t={t}
                  />
                ) : (
                  <></>
                )
              }
              highlightTransform={(
                highlight,
                isScrolledTo,
                index,
                setTip,
                hideTip,
                viewportToScaled,
                screenshot,
              ) => {
                const isTextHighlight = !highlight.content?.image
                const component = isTextHighlight ? (
                  <Highlight
                    isScrolledTo={isScrolledTo}
                    position={highlight.position}
                    highlightAllStates={highlightAllStates}
                    areaId={areaId}
                    fromTarget={false}
                    selectedId={document.location.hash.slice('#phrase-'.length)}
                    highlightId={highlight.id}
                  />
                ) : (
                  <AreaHighlight
                    isScrolledTo={isScrolledTo}
                    highlight={highlight}
                  />
                )
                return (
                  <Popup
                    onMouseOver={() => {}}
                    onMouseOut={() => {}}
                    popupContent={<></>}
                    key={index} // Use `index` as key here but consider using something more unique
                  >
                    {component}
                  </Popup>
                )
              }}
              highlights={
                tempHighlight.length > 0 ? tempHighlight : selectedHighlight
              }
              setTempHighlight={setTempHighlight}
              sourceId={projectId}
            />
          )}
        </PdfLoader>
      )}
    </div>
  )
}

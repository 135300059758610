import { Typography } from '@mui/material'

export default function ErrorPage(): JSX.Element {
  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
      }}
    >
      <Typography variant="h1">404</Typography>
      <Typography fontWeight={'600'} fontSize={'22px'}>
        Not found
      </Typography>
      <Typography fontSize={'12px'}>
        The resource requested could not be found on this server!
      </Typography>
    </div>
  )
}

import React from 'react'
import LightTooltip from './tooltip'

interface TruncateTextProps {
  text?: string
  maxLength: number
}

class TruncateText extends React.Component<TruncateTextProps> {
  truncateString(str: string, maxLength: number) {
    if (str.length <= maxLength) {
      return str
    } else {
      return str.substring(0, maxLength) + '...'
    }
  }

  render() {
    const { text, maxLength } = this.props
    if (!text) return null // handle case when text is not provided
    const truncatedText: any = this.truncateString(text, maxLength)

    return (
      <span>
        <LightTooltip title={text} placement="bottom">
          <span>{truncatedText}</span>
        </LightTooltip>
      </span>
    )
  }
}

export default TruncateText

import axiosInstance from '../../services/http-interceptors'
const controllers = new Map<string, AbortController>()
const createOrReuseController = (key: string) => {
  if (controllers.has(key)) {
    // Abort the previous request
    controllers.get(key)?.abort()
  }
  // Create a new controller and store it
  const controller = new AbortController()
  controllers.set(key, controller)
  return controller
}

// function to call get API to get projects
export const getResultsAPI = async (projectId: any, params: any) => {
  const controller = createOrReuseController(`getResults-${projectId}`)
  try {
    const response = await axiosInstance.get(
      `/api/project/${projectId}/result/`,
      {
        params,
        signal: controller.signal,
      },
    )
    return response.data
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

export const downloadAPI = async (projecId: any) => {
  const response = await axiosInstance.get(
    `/api/project/${projecId}/result/download/`,
  )
  return response
}

export const approvedOrUnapproveAPI = async (
  projectID: any,
  sourcePhraseId: any,
  collationResultId: any,
  decisionVal: boolean,
) => {
  try {
    const response = await axiosInstance.patch(
      `/api/project/${projectID}/source_phrase/${sourcePhraseId}/collation/${collationResultId}/`,
      {
        approval: decisionVal,
      },
    )
    return response.data
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

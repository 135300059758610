import React from 'react'

// Import your variables.scss to use the variables
import '../../styles/variables.scss'
import { CircularProgress } from '@mui/material'

interface Props {}

export const Spinner: React.FC<Props> = (props) => {
  return (
    <div
      style={{
        paddingTop: '20rem',
      }}
    >
      <CircularProgress />
    </div>
  )
}

import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from 'react'
import { useLocalStorage } from './useLocalStorage'
import { UserContext } from '../context/globalContext'
import { meAPI } from '../modules/auth/signup/auth-services'
import { enqueueSnackbar } from 'notistack'
import axios from 'axios'

interface AuthContextProps {
  isAuthenticated: boolean
  userRole: string | null
  loading: boolean
  loginUser: (role: string) => void
  logoutUser: () => void
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined)

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useLocalStorage(
    'isAuthenticated',
    false,
  )
  const { userRole, setUserRole } = useContext(UserContext)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (isAuthenticated) {
      meAPI()
        .then((response) => {
          if (response.role) {
            setUserRole(response.role.id)
          }
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
          if (axios.isCancel(error)) {
            console.debug('Request canceled:', error)
          } else if (
            error?.response?.data?.detail &&
            error.response.request.status === 403
          ) {
            enqueueSnackbar(`${error?.response?.data?.detail}.`, {
              variant: 'error',
              persist: true,
            })
          }
          if (error?.response?.request?.status === 502) {
            enqueueSnackbar(`Something went wrong`, {
              variant: 'error',
              persist: true,
            })
          }
        })
    } else {
      setLoading(false)
    }
  }, [isAuthenticated, setUserRole])

  const loginUser = (role: string) => {
    // Perform authentication logic
    setIsAuthenticated(true)
    setUserRole(role)
  }

  const logoutUser = () => {
    // Perform logout logic
    setIsAuthenticated(false)
    setUserRole(null)
  }

  const value = useMemo(
    () => ({
      isAuthenticated,
      userRole: userRole ?? '',
      loading,
      loginUser,
      logoutUser,
    }),
    [isAuthenticated, userRole],
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

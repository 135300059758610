import { Button, InputLabel, LinearProgress, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import InputBox from '../input/input'
import TextButton from '../button/textButton'
import ByteConverter from './byteConverter'
import { useTranslation } from 'react-i18next'

interface Props {
  handleFileChange: any
  color: string
  bgColor: string
  placeholder?: string
  disabled?: boolean
  style?: React.CSSProperties
  file?: any
  setFile?: any
  uploadProgress?: any
  isProgressComplete?: boolean
  buttonName: string
  exsistingSrcDocName?: string
  documentTitle?: string
}
export const SingleUpload = ({
  handleFileChange,
  file,
  setFile,
  placeholder,
  uploadProgress,
  isProgressComplete,
  style,
  bgColor,
  color,
  buttonName,
  exsistingSrcDocName,
  documentTitle,
}: Props) => {
  const [pdfFileName, setPdfFileName] = useState<any>('')
  const { t } = useTranslation()
  // When file props are changed we need to update the pdfFile state
  useEffect(() => {
    if (file) {
      setPdfFileName(file.name)
    } else if (exsistingSrcDocName) {
      setPdfFileName(exsistingSrcDocName)
    } else {
      setPdfFileName('')
    }
  }, [file, exsistingSrcDocName])
  const disableBrowseButton = uploadProgress !== null

  return (
    <div className="upload-wrapper">
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <InputLabel shrink htmlFor="upload-input">
          {t(`${documentTitle}`)}
        </InputLabel>
        <InputBox
          id="upload-input"
          placeholder={placeholder}
          value={pdfFileName}
          readOnly
          style={{
            width: '100%',
            fontSize: '14px',
            fontWeight: '400',
          }}
          inputProps={{
            style: {
              width: '100%',
              borderTopRightRadius: '0px',
              borderBottomRightRadius: '0px',
              marginTop: '9px',
            },
            disabled: {
              color: 'red !important', // Set color to red when input is disabled
            },
          }}
        />
        {/* On select file upload */}

        <TextButton
          text={buttonName}
          bgColor={bgColor}
          color={color}
          style={{
            ...style,
            textTransform: 'capitalize',
          }}
          disabled={disableBrowseButton}
          onClick={() => document.getElementById('file-upload-input')?.click()}
        />
        {/* Input tag is hidden here, triggered by TextButton */}
        <input
          id="file-upload-input"
          hidden
          accept="application/pdf"
          onChange={handleFileChange}
          type="file"
          value=""
        />
      </div>
      {uploadProgress && !isProgressComplete ? (
        <Stack pt={2}>
          <div
            style={{
              color: 'var(--primary-600)',
              fontSize: '14px',
              fontWeight: '500',
              marginBottom: '4px',
            }}
          >
            {`${uploadProgress.progress}%`}
          </div>

          <LinearProgress
            variant={
              uploadProgress.progress < 100 && !isProgressComplete
                ? 'determinate'
                : 'indeterminate'
            }
            value={uploadProgress?.progress}
          />
          {/* <div
            style={{
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '4px',
              }}
            >
              <span>
                <ByteConverter
                  bytes={(file.size * uploadProgress.progress) / 100}
                  decimal={2}
                />
              </span>
              <span> of </span>
              <span>
                <ByteConverter bytes={file.size} decimal={2} />
              </span>
            </div>
            
          </div> */}
        </Stack>
      ) : null}
    </div>
  )
}

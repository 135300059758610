import axiosInstance from '../../../services/http-interceptors'

export const BASE_URL = ''
const controller = new AbortController()

// function to call get API to get projects
export const meAPI = async () => {
  try {
    const response = await axiosInstance.get(`/api/accounts/user/me/`)
    return response.data
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}
/**
 * Performs a login request to the server. *
 * @param {any} data - The login credentials to be sent to the server.
 * @returns {Promise<any>} - A promise that resolves with the response data upon successful login.
 */
export const login = async (data: any) => {
  try {
    const response = await axiosInstance.post(`/api/accounts/login/`, data)
    return response.data
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}
/**
 * Performs a logout request to the server. *
 * * @returns {Promise<any>} - A promise that resolves with the response data upon successful logout.
 */
export const logoutAPI = async () => {
  try {
    const response = await axiosInstance.post(`/api/accounts/logout/`, '')
    return response.data
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}
/**
 * Performs a login request to the server. *
 * @param {any} data - The login credentials to be sent to the server.
 * @returns {Promise<any>} - A promise that resolves with the response data upon successful login.
 */
export const forgotPassword = async (data: any) => {
  try {
    const response = await axiosInstance.post(
      `/api/accounts/forgot-password/`,
      data,
    )
    return response.data
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// reset passwor endpoint call */
export const resetPassword = async (data: any) => {
  const obj: any = {
    new_password: data.new_password,
    confirm_password: data.confirm_password,
  }

  if (data.token) {
    obj.token = data.token
  }
  try {
    const response = await axiosInstance.post(
      `/api/accounts/reset-password/${data.reset_password_code}/`,
      obj,
    )
    return response.data
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

export const inviteCode = async (data: any) => {
  const obj: any = {
    email: data.email,
    new_password: data.new_password,
    confirm_password: data.confirm_password,
    remember_me: data.remember_me,
  }
  if (data.token) {
    obj.token = data.token
  }

  try {
    const response = await axiosInstance.post(
      `/api/accounts/set-password/${data.invite_code}/`,
      obj,
    )
    return response.data
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

import { Stack, TextField } from '@mui/material'
import '../../auth/signup/authpage.scss'

import { Controller, useForm } from 'react-hook-form'
import TextButton from '../../../components/button/textButton'
import { enqueueSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { forgotPassword } from './auth-services'
import { useTranslation } from 'react-i18next'
import { getLocalizedPath } from '../../../utils/shareLocalizePath'
import axios from 'axios'
import { useRecaptcha } from '../../../components/reCaptcha/ReCaptcha'
import { useConditionalRecaptcha } from '../../../components/reCaptcha/useConditionalRecaptcha'

interface Props {}
interface FormData {
  email: string
}

function ForgotPasword({}: Props): JSX.Element {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormData>()
  const navigate = useNavigate()

  const [isLinkSent, setIsLinkSent] = useState(false)
  const [apiStatus, setApiResponse] = useState(false)
  const { t, i18n } = useTranslation()
  const currentLanguage = i18n.language
  const [reCaptchaToken, setReCaptchaToken] = useState<string | null>(null)
  const { executeRecaptcha, isRecaptchaReady } = useConditionalRecaptcha()

  // Check environment variable
  const ENABLE_RECAPTCHA = process.env.REACT_APP_ENABLE_RECAPTCHA === 'true'

  useEffect(() => {
    const verify = async () => {
      if (ENABLE_RECAPTCHA && isRecaptchaReady && executeRecaptcha) {
        const recaptchaToken = await executeRecaptcha('forgotPassword')
        setReCaptchaToken(recaptchaToken)
      }
    }
    verify()
  }, [ENABLE_RECAPTCHA, executeRecaptcha, isRecaptchaReady])

  const onSubmitResetPasswordForm = (data: FormData) => {
    if (!reCaptchaToken && ENABLE_RECAPTCHA) {
      enqueueSnackbar(t('Please complete the reCAPTCHA verification'), {
        variant: 'warning',
      })
      return
    }
    setApiResponse(true)
    const forgetPasswordData = {
      ...data,
      ...(ENABLE_RECAPTCHA && { token: reCaptchaToken }), // Add reCAPTCHA token to the request payload if reCAPTCHA is enabled
    }
    forgotPassword(forgetPasswordData)
      .then((response) => {
        if (response) {
          setIsLinkSent(true)
          enqueueSnackbar(`${response.detail}`, {
            variant: 'success',
            persist: true,
          })
          setApiResponse(false)
        }
      })
      .catch((error) => {
        setApiResponse(false)
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }

  const handleGoBack = () => {
    setIsLinkSent(false)
    navigate(`${getLocalizedPath(currentLanguage, '/')}`)
  }

  return (
    <div className="auth-global-page-wrapper">
      <Stack direction="column" justifyContent="center" alignItems="center">
        <h3>{t('Document Collation App')}</h3>
        <form>
          <Stack
            direction="column"
            justifyContent="start"
            alignItems="start"
            className="contents"
          >
            <Stack
              direction="column"
              justifyContent="start"
              alignItems="start"
              className="input-field-wrapper"
            >
              <h6 className="heading"> {t('Email')}</h6>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{ required: t('Please enter a valid email') }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="outlined-basic"
                    variant="outlined"
                    className="input-fields"
                    helperText={errors.email?.message}
                  />
                )}
              />
              {isLinkSent ? (
                <h6 style={{ color: '#057A55' }}>
                  {t('Reset password link has been sent to your email')}
                </h6>
              ) : (
                <h6 style={{ color: '#364861' }}>
                  {t('A reset password link will be sent to your email')}
                </h6>
              )}
            </Stack>

            {!isLinkSent ? (
              <TextButton
                text={t(`Reset Password`)}
                color={'#fff'}
                bgColor={
                  !isValid || apiStatus
                    ? 'var(--primary-300)'
                    : 'var(--primary-600)'
                }
                disabled={!isValid || apiStatus}
                onClick={handleSubmit(onSubmitResetPasswordForm)}
                style={{
                  width: '100%',
                }}
              />
            ) : (
              <TextButton
                text={t(`Return to Sign in`)}
                color={'#fff'}
                bgColor={!isValid ? 'var(--primary-300)' : 'var(--primary-600)'}
                disabled={!isValid}
                onClick={handleGoBack}
                style={{
                  width: '100%',
                  textTransform: 'none',
                }}
              />
            )}
          </Stack>
        </form>
        <div className="recaptcha-usage">
          {t('Protected by reCAPTCHA and subject to the Google')}{' '}
          <a href="https://www.google.com/intl/en/policies/privacy/">
            {t('Privacy Policy')}
          </a>{' '}
          {t('and')} &nbsp;
          <a href="https://www.google.com/intl/en/policies/terms/">
            {t('Terms of Service')}
          </a>
          .
        </div>
      </Stack>
    </div>
  )
}

export default ForgotPasword

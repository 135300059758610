import { useContext, useEffect, useState } from 'react'
import './App.css'
import Router from './router'
import { Stack } from '@mui/system'
import { SnackbarProvider } from './components/snackbar/snackbar'
import {
  IconButton,
  ThemeProvider,
  checkboxClasses,
  createTheme,
} from '@mui/material'
import { theme } from './styles/theme'
import GlobalContextProvider, { StatusContext } from './context/globalContext'
import { BrowserRouter } from 'react-router-dom'
import { type SnackbarKey, closeSnackbar } from 'notistack'
import { AuthProvider } from './utils/AuthContext'
import { useTranslation } from 'react-i18next'
import { RecaptchaProvider } from './components/reCaptcha/ReCaptcha'

function App(): JSX.Element {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const { t } = useTranslation()
  const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY!
  const ENABLE_RECAPTCHA = process.env.REACT_APP_ENABLE_RECAPTCHA === 'true'

  useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth)
    }
    window.addEventListener('resize', updateScreenWidth)
    return () => {
      window.removeEventListener('resize', updateScreenWidth)
    }
  }, [])
  // to fix dialog close error bug
  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (e.message === 'ResizeObserver loop limit exceeded') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        )
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        )
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none')
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none')
        }
      }
    })
  }, [])
  return (
    <BrowserRouter>
      <GlobalContextProvider>
        <div className="App">
          {/* Router - contains all routes */}
          {screenWidth >= 1280 ? (
            <ThemeProvider theme={theme}>
              <AuthProvider>
                <SnackbarProvider>
                  {ENABLE_RECAPTCHA ? (
                    <RecaptchaProvider siteKey={RECAPTCHA_SITE_KEY}>
                      <Router />
                    </RecaptchaProvider>
                  ) : (
                    <Router />
                  )}
                </SnackbarProvider>
              </AuthProvider>
            </ThemeProvider>
          ) : (
            <Stack
              direction={'column'}
              alignItems={'center'}
              justifyContent={'center'}
              height="100vh"
            >
              {t(
                'Not suitable screen size for display. Please make your window larger.',
              )}
            </Stack>
          )}
        </div>
      </GlobalContextProvider>
    </BrowserRouter>
  )
}

export default App

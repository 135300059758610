import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon,
  Box,
} from '@mui/material'
import { ReactComponent as XIcon } from '../../assets/images/x.svg'
// Import your variables.scss to use the variables
import '../../styles/variables.scss'

interface DynamicDialogProps {
  open: boolean
  title?: string
  children: React.ReactNode
  actions?: React.ReactNode
  maxWidth?: any
  onClose: () => void
}

export const ActionDialog: React.FC<DynamicDialogProps> = ({
  open,
  title,
  children,
  actions,
  maxWidth,
  onClose,
}) => {
  const handleClose = (e: any) => {
    onClose() // Close dialog
  }
  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth ?? `xs`}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <Box
        width={'full'}
        height={'32px'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'end'}
        padding={'5px 15px 0 0'}
      >
        <Icon className="global-close-btn">
          <XIcon title="Close" onClick={handleClose} />
        </Icon>
      </Box>
      <DialogTitle
        fontWeight={600}
        fontSize={'20px'}
        paddingTop={'0px !important'}
      >
        {title}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  )
}

export default ActionDialog

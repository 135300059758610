import axiosInstance from '../../services/http-interceptors'
// function to call get API to get projects
export const getProjectSettingData = async (id: any) => {
  try {
    const response = await axiosInstance.get(`/api/project/${id}/`)
    return response.data
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

export const getTargetDocumentDataAPI = async (params: any, id: any) => {
  try {
    const response = await axiosInstance.get(
      `/api/project/${id}/target_document/`,
      {
        params: params,
      },
    )
    return response.data
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

export const getPreviousFilesAPI = async (id: any, targetId: any) => {
  try {
    const response = await axiosInstance.get(
      `/api/project/${id}/target_document/${targetId}/previous_versions/`,
    )
    return response.data
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}
export const getPreviousSourceFilesAPI = async (id: any) => {
  try {
    const response = await axiosInstance.get(
      `/api/project/${id}/source_document/previous_versions/`,
    )
    return response.data
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// function to call post API to edit Project
export const editProjectAPI = async (
  data: any,
  id: any,
  onUploadProgress?: (progressEvent: any) => void,
  signal?,
) => {
  const response = await axiosInstance.patch(`/api/project/${id}/`, data, {
    onUploadProgress: onUploadProgress,
    signal,
  })
  return response
}

// function to call post API to edit Project
export const replaceProjectAPI = async (
  data: any,
  id: any,
  targetId: any,
  onUploadProgress: (progressEvent: any) => void,
  signal,
) => {
  const response = await axiosInstance.patch(
    `/api/project/${id}/target_document/${targetId}/`,
    data,
    {
      onUploadProgress: onUploadProgress,
      signal,
    },
  )
  return response
}

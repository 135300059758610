import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon,
  Box,
  Stack,
  Checkbox,
} from '@mui/material'
import { ReactComponent as XIcon } from '../../assets/images/x.svg'
import InfoIcon from '../../assets/images/error.svg'
import '../form-dialog/cancel-conformation.scss'
import TextButton from '../button/textButton'
import OutlineTextButton from '../button/textOutlineButton'
import { useTranslation } from 'react-i18next'
interface DynamicCancelDialogProps {
  open: boolean
  message: string
  cancelbtnText?: string
  children?: React.ReactNode
  actions?: React.ReactNode
  onClose?
  from?
  type?
  projectId?
}

const CancelDeleteConformationFormDialog: React.FC<
  DynamicCancelDialogProps
> = ({
  open,
  message,
  cancelbtnText,
  children,
  actions,
  onClose,
  from,
  type,
  projectId,
}) => {
  const [checked, setChecked] = useState(false)
  const { t } = useTranslation()
  const disableSaveButton = false

  const onComfirmCancel = (e?: any) => {
    onClose('confirm')
  }
  const onAbortCancel = (e?: any) => {
    onClose('abort') // Close dialog
  }

  useEffect(() => {
    // Initialize the state from localStorage
    if (type && from) {
      const isChecked =
        localStorage.getItem(
          `${type || 'delete'}_dialog_from_${from}_projectID_${projectId}`,
        ) === 'true'
      setChecked(isChecked)
    }
  }, [from, type])

  const handleChange = () => {
    const newChecked = !checked
    setChecked(newChecked)
    localStorage.setItem(
      `${type || 'delete'}_dialog_from_${from}_projectID_${projectId}`,
      JSON.stringify(newChecked),
    )
  }
  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={onAbortCancel}
      aria-labelledby="form-dialog-title"
      sx={{}}
    >
      <Box
        height={'28px'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'end'}
        padding={'8px 15px 0 0'}
      >
        <Icon className="global-close-btn">
          <XIcon title="Close" onClick={onAbortCancel} />
        </Icon>
      </Box>

      <Stack
        display={'flex'}
        direction={'column'}
        alignItems={'center'}
        style={{
          padding: '0 15px',
          width: '330px',
        }}
      >
        <img src={InfoIcon} alt="Info" className="infoIcon" />

        <DialogTitle className="message">{message}</DialogTitle>
        {(from === 'source_phrases' || from === 'collations') &&
          type !== 'finalize_area' &&
          type !== 'delete_area' && (
            <Box
              width={'90%'}
              display={'flex'}
              alignItems={'center'}
              paddingX={'15px'}
            >
              <Checkbox
                size={'small'}
                sx={{
                  padding: '0 5px 0 10px ',
                }}
                checked={checked}
                onChange={handleChange}
              />{' '}
              <span style={{ fontSize: '12px' }}>Do not show this again</span>
            </Box>
          )}
        <DialogContent>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'start',
              gap: '30px',
            }}
          >
            <TextButton
              text={t("Yes, I'm sure")}
              color={'#fff'}
              bgColor={disableSaveButton ? 'var(--red-100)' : 'var(--red-600)'}
              disabled={disableSaveButton}
              onClick={onComfirmCancel}
              style={{
                padding: '8px 18px',
              }}
              tabIndex={0}
            />
            <OutlineTextButton
              text={cancelbtnText ?? t('No')}
              bgColor={'#fff'}
              color={'var(--gray-800)'}
              outlineColor={'var(--gray-200)'}
              // bgColor={
              //   disableInviteButton
              //     ? 'var(--primary-300)' // Use primary-300 for the disable color
              //     : 'var(--primary-600)'
              // }
              // disabled={disableInviteButton}
              onClick={onAbortCancel}
              style={{
                padding: '8px 18px',
                width: '119px',
              }}
            />
          </div>
        </DialogContent>

        {actions && <DialogActions>{actions}</DialogActions>}
      </Stack>
    </Dialog>
  )
}
export default CancelDeleteConformationFormDialog

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  type ReactNode,
} from 'react'

// Define the type for the reCAPTCHA execute function
type ExecuteRecaptcha = (action: string) => Promise<string | null>

interface RecaptchaContextProps {
  executeRecaptcha: ExecuteRecaptcha
  isRecaptchaReady: boolean
}

// Create the context
const RecaptchaContext = createContext<RecaptchaContextProps | undefined>(
  undefined,
)

// Provider component
export const RecaptchaProvider: React.FC<{
  siteKey: string
  children: ReactNode
}> = ({ siteKey, children }) => {
  const [isRecaptchaReady, setIsRecaptchaReady] = useState(false)

  useEffect(() => {
    const initializeRecaptcha = () => {
      if (window.grecaptcha?.ready) {
        window.grecaptcha.ready(() => {
          console.debug('reCAPTCHA is ready')
          setIsRecaptchaReady(true)
        })
      } else {
        console.debug(
          'reCAPTCHA script loaded but grecaptcha is not initialized correctly',
        )
      }
    }
    const loadScript = () => {
      const script = document.createElement('script')
      script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`
      script.async = true

      script.onload = initializeRecaptcha

      script.onerror = () => {
        console.debug('Failed to load the reCAPTCHA script')
        setIsRecaptchaReady(false)
      }

      document.head.appendChild(script)
    }

    if (window.grecaptcha?.ready) {
      console.debug('grecaptcha is already available')
      initializeRecaptcha()
    } else {
      const existingScript = document.querySelector(
        `script[src*="recaptcha/api.js?render=${siteKey}"]`,
      )

      if (!existingScript) {
        console.debug('Loading reCAPTCHA script')
        loadScript()
      } else {
        console.debug('reCAPTCHA script already exists')
        existingScript.addEventListener('load', initializeRecaptcha)
      }
    }

    return () => {
      const script = document.querySelector(
        `script[src*="recaptcha/api.js?render=${siteKey}"]`,
      )
      if (script) {
        script.removeEventListener('load', initializeRecaptcha)
        document.head.removeChild(script)
      }
    }
  }, [siteKey])

  const executeRecaptcha = useCallback<ExecuteRecaptcha>(
    async (action: string) => {
      if (
        isRecaptchaReady &&
        window.grecaptcha &&
        typeof window.grecaptcha.execute === 'function'
      ) {
        try {
          console.debug('Executing reCAPTCHA action:', action)
          const token = await window.grecaptcha.execute(siteKey, { action })
          console.debug('reCAPTCHA token:', token)
          return token
        } catch (error) {
          console.debug('ReCaptcha execution failed:', error)
          return null
        }
      }
      console.debug(
        'ReCaptcha is not ready yet or execute function is not available',
      )
      return null
    },
    [isRecaptchaReady, siteKey],
  )

  return (
    <RecaptchaContext.Provider value={{ executeRecaptcha, isRecaptchaReady }}>
      {children}
    </RecaptchaContext.Provider>
  )
}

// Custom hook to use the reCAPTCHA context
export const useRecaptcha = (): RecaptchaContextProps => {
  const context = useContext(RecaptchaContext)
  if (!context) {
    throw new Error('useRecaptcha must be used within a RecaptchaProvider')
  }
  return context
}

import React from 'react'
import { Button, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'

interface ButtonIconProps {
  IconComponent: React.ElementType
  text: string
  bgColor: string
  disable?: boolean
  color: string
  style?: React.CSSProperties
  onClick?: () => void // Accept onClick props
}

const StyledButton = styled(Button)<{ bgcolor: string; textcolor: string }>(
  ({ theme, bgcolor, textcolor }) => ({
    backgroundColor: bgcolor,
    color: textcolor,
    borderRadius: '8px',
    width: 'fit-content',
    height: '40px',
    padding: '20px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: bgcolor,
      opacity: 0.9,
    },
  }),
)

const ButtonIcon: React.FC<ButtonIconProps> = ({
  IconComponent,
  text,
  bgColor,
  color,
  style,
  disable,
  onClick, // Destructure onClick from props
}) => {
  return (
    <StyledButton
      startIcon={
        disable ? (
          <CircularProgress size={15} style={{ color }} />
        ) : (
          <IconComponent />
        )
      }
      bgcolor={bgColor}
      textcolor={color}
      style={style}
      disabled={disable}
      onClick={onClick} // Pass onClick to the StyledButton
    >
      {text}
    </StyledButton>
  )
}

export default ButtonIcon

import { createTheme } from '@mui/material/styles'
import { ReactComponent as ArrowDownIcon } from '../assets/images/down_arrow.svg'

export const theme = createTheme({
  typography: {
    fontFamily: ['IBM Plex Sans'].join(','),
  },
  components: {
    MuiSelect: {
      defaultProps: {
        IconComponent: ArrowDownIcon,
        // sx: {
        //   '.MuiSelect-icon': {
        //     top: 'calc(50% - .6em)',
        //   },
        // },
      },
      styleOverrides: {
        select: {
          borderRadius: '8px',
          backgroundColor: '#ffffff', // Override select background color
          '&:focus': {
            backgroundColor: '#ffffff', // Maintain background color on focus
          },
        },
        icon: { margin: '0 5px ' },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          marginTop: '10px',
          '& .MuiPaper-root': {
            border: `1px solid var(--gray-200)`,
            borderRadius: '8px',
            boxShadow: `0px 1px 2px 0px #00000014`,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          fontSize: '14px',
          lineHeight: '21px',
          fontFamily: 'var(--theme-font)',
          '&:hover': {
            backgroundColor: '#f5f5f5', // Override hover background color for menu items
          },
          '&.Mui-selected': {
            backgroundColor: '#fff', // Override selected background color
            fontWeight: 600,
            color: 'var(--gray-600)',
            '&:hover': {
              backgroundColor: '#fff', // Override hover on selected background color
            },
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: '1px solid var(--gray-200)',
          // '&.Mui-expanded': {
          //   backgroundColor: 'var(--gray-200)',
          // },
          '&:before': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            height: 40,
            padding: '12px 16px',
            borderRadius: '8px',
            fontFamily: 'IBM Plex Sans',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'var(--gray-200)', // Change border color
            },
            '&:hover fieldset': {
              borderColor: 'var(--gray-200)', // Change border color on hover
            },
            '&.Mui-focused fieldset': {
              // boxShadow: `#1976d2 0 0 0 0.1rem`,
              boxShadow: `rgba(25, 118, 210, 0.25) 0 0 0 0.1rem`,
              borderColor: 'var(--primary-600)', // Change border color when focused
            },

            '& .MuiInputBase-input::placeholder': {
              color: 'var(--gray-600)', // Change placeholder color
            },
          },
          // '& .MuiInputBase-input': {
          //   color: 'var(--gray-800) !important',
          // },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          backgroundColor: 'var(--primary-300)',
          '& .MuiLinearProgress-bar1Determinate': {
            backgroundColor: 'var(--primary-600)',
          },
          '& .MuiLinearProgress-bar1Indeterminate': {
            backgroundColor: 'var(--primary-600)',
          },
          '& .MuiLinearProgress-bar2Indeterminate': {
            backgroundColor: 'var(--primary-600)',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: 'var(--gray-600)',
          fontSize: '18px',
          fontWeight: '600',
          fontFamily: 'IBM Plex Sans',

          '&.Mui-focused': {
            color: 'var(--gray-600)',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: 'var(--primary-600)',
          },
          '&.MuiSvgIcon-root': {
            borderRadius: '4px',
          },
          '&.Mui-disabled': {
            backgroundColor: 'var(-primary-300)',
          },
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: 'IBM Plex Sans',
          color: 'var(--gray-600)',
          fontWeight: '500',
          alignItems: 'flex-start !important',
          // '&[data-pinned="true"]:before': {
          //   backgroundColor: '#f8fafd !important',
          // },
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          '&.Mui-selected': {
            color: 'var(--primary-600)',
            fontWeight: '500',
            fontSize: '16px',
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'var(--theme-font)',
        },
        input: {
          color: 'var(--gray-800) !important',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'var(--theme-font)',

          '&.Mui-disabled': {
            color: 'var(--gray-white) !important',
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontFamily: 'var(--theme-font)',
        },
      },
    },

    MuiTablePagination: {
      styleOverrides: {
        select: {
          border: '1px solid var(--gray-200)',
          borderRadius: '8px',
          '&.MuiInputBase-input': {
            padding: '10px 40px 8px 10px !important',
            weight: '500',
          },
          // '& .MuiSelect-icon': {
          //   margin: '0 10px !important',
          // },
        },
        selectLabel: {
          fontFamily: 'var(--theme-font)',
        },
        displayedRows: {
          fontFamily: 'var(--theme-font)',
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: 'var(--primary-600)',
        },
      },
    },

    MuiBadge: {
      styleOverrides: {
        badge: {
          backgroundColor: 'var(--gray-100)',
          color: 'var(--primary-600)',
          fontFamily: 'var(--theme-font)',
          fontSize: '14px',
        },
      },
    },
  },
})

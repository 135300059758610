import React, { useEffect, useState, useRef, useMemo } from 'react'
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/legacy/build/pdf'
import type { PDFDocumentProxy } from 'pdfjs-dist'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'

interface Props {
  workerSrc?: string
  url?: string
  beforeLoad?: JSX.Element
  errorMessage?: JSX.Element
  children?: (pdfDocument: PDFDocumentProxy) => JSX.Element
  onError?: (error: Error) => void
  cMapUrl?: string
  cMapPacked?: boolean
  setPdfTitle?: (title: string) => void
  setNumPages?: (num: number) => void
  setCurrentPage?: (page: number) => void
}

const PdfLoader: React.FC<Props> = ({
  workerSrc = pdfjsWorker,
  url,
  beforeLoad,
  errorMessage,
  children,
  onError,
  cMapUrl,
  cMapPacked,
  setPdfTitle,
  setNumPages,
  setCurrentPage,
}) => {
  const [pdfDocument, setPdfDocument] = useState<PDFDocumentProxy | null>(null)
  const [error, setError] = useState<Error | null>(null)
  const documentRef = useRef<HTMLElement>(null)

  useEffect(() => {
    GlobalWorkerOptions.workerSrc = workerSrc
  }, [workerSrc])

  const documentLoadOptions = useMemo(
    () => ({
      url,
      cMapUrl,
      cMapPacked,
      ownerDocument: documentRef.current?.ownerDocument || document,
      isEvalSupported: false, // Disable eval support for security
    }),
    [url, cMapUrl, cMapPacked],
  )

  useEffect(() => {
    const loadDocument = async () => {
      try {
        const loadedPdfDocument = await getDocument(documentLoadOptions).promise
        setPdfDocument(loadedPdfDocument)
        setCurrentPage?.(1)
        setNumPages?.(loadedPdfDocument.numPages)
      } catch (e) {
        if (e instanceof Error) {
          setError(e)
          onError?.(e)
        }
      }
    }

    if (url) {
      loadDocument()
    }

    return () => {
      pdfDocument?.destroy()
    }
  }, [documentLoadOptions, setNumPages, setCurrentPage, onError])

  if (error) {
    return errorMessage ? React.cloneElement(errorMessage, { error }) : null
  }

  if (!pdfDocument || !children) {
    return beforeLoad || null
  }

  return <>{children(pdfDocument)}</>
}

export default PdfLoader

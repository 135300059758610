import axios from 'axios'
import axiosInstance from './http-interceptors'

const controllers = new Map<string, AbortController>()

const createOrReuseController = (key: string) => {
  if (controllers.has(key)) {
    // Abort the previous request
    controllers.get(key)?.abort()
  }
  // Create a new controller and store it
  const controller = new AbortController()
  controllers.set(key, controller)
  return controller
}

// Function to call get API to get project status
export const getProjectStatus = async (id: any) => {
  try {
    const response = await axiosInstance.get(`/api/project/${id}/status/`, {})
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

/* ----------Target Document APIs------------ */

// Function to call get API to get project by id
export const getProjectById = async (id: any) => {
  const controller = createOrReuseController(`getProjectById-${id}`)
  try {
    const response = await axiosInstance.get(`/api/project/${id}/`, {
      signal: controller.signal,
    })
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// Function to call post API to create Project
export const addTargetFile = async (
  id,
  data: any,
  onUploadProgress: (progressEvent: any) => void,
  signal,
) => {
  try {
    const response = await axiosInstance.post(
      `/api/project/${id}/target_document/`,
      data,
      {
        onUploadProgress,
        signal,
      },
    )
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// Function to call post API to get target files
export const getTargetFiles = async (id, phraseId?: any) => {
  const controller = createOrReuseController(`getTargetFiles-${id}`)
  try {
    const response = await axiosInstance.get(
      `/api/project/${id}/target_document/`,
      {
        params: phraseId
          ? {
              source_phrase_id: phraseId,
              sort: 'best_rank',
            }
          : {
              sort: 'id',
            },
        signal: controller.signal,
      },
    )
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// Function to call get API to get target document by id
export const getTargetById = async (id: any, targetId: any) => {
  const controller = createOrReuseController(`getTargetById-${id}-${targetId}`)
  try {
    const response = await axiosInstance.get(
      `/api/project/${id}/target_document/${targetId}/`,
      {
        signal: controller.signal,
      },
    )
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// Function to call delete API to delete target document by id
export const deleteTargetById = async (id: any, targetId: any) => {
  const controller = createOrReuseController(
    `deleteTargetById-${id}-${targetId}`,
  )
  try {
    const response = await axiosInstance.delete(
      `/api/project/${id}/target_document/${targetId}/`,
      {
        signal: controller.signal,
      },
    )
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// Function to call post API to finalize target files
export const finalizeTargetFiles = async (id) => {
  const controller = createOrReuseController(`finalizeTargetFiles-${id}`)
  try {
    const response = await axiosInstance.post(
      `/api/project/${id}/target_document/finalize/`,
      {
        signal: controller.signal,
      },
    )
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

/* ----------Source Phrase and Area APIs------------ */

// Function to call post API to create Source Phrase
export const createSourcePhrase = async (id, data: any) => {
  const controller = createOrReuseController(`createSourcePhrase-${id}`)
  try {
    const response = await axiosInstance.post(
      `/api/project/${id}/source_phrase/`,
      data,
      {
        signal: controller.signal,
      },
    )
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// Function to call get API to fetch Area list
export const getAreaList = async (id, query?: string) => {
  const controller = createOrReuseController(`getAreaList-${id}`)
  try {
    const response = await axiosInstance.get(`/api/project/${id}/area/`, {
      params: {
        source_phrase_query: query,
      },
      signal: controller.signal,
    })
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// Function to call get API to fetch source phrases by area_id
export const getSourcePhrasesByAreaId = async (id, areaId, query?: any) => {
  const key = `getSourcePhrasesByAreaId-${id}`

  // Cancel the previous request if it's still pending
  if (controllers[key]) {
    controllers[key].abort()
  }

  // Create a new AbortController
  const controller = new AbortController()
  controllers[key] = controller

  try {
    const response = await axiosInstance.get(
      `/api/project/${id}/area/${areaId}/source_phrase/`,
      {
        params: { query },
        signal: controller.signal,
      },
    )
    return response
  } catch (error) {
    if (axios.isCancel(error)) {
      console.debug('Request canceled:', error.message)
    } else {
      console.debug('Error:', error)
    }
    throw error
  }
}
// Function to call delete API to delete area by id
export const deleteAreaById = async (id, areaId, confirm?: boolean) => {
  const controller = createOrReuseController(`deleteAreaById-${id}-${areaId}`)
  const config = {
    signal: controller.signal, // For request cancellation
    params: confirm !== undefined ? { confirm } : {}, // Conditionally include confirm in query params
  }

  try {
    const response = await axiosInstance.delete(
      `/api/project/${id}/area/${areaId}/`,
      config, // Include signal and params in config
    )
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// Function to call patch API to update area name
export const updateAreaName = async (id, data, areaId) => {
  const controller = createOrReuseController(`updateAreaName-${id}-${areaId}`)
  try {
    const response = await axiosInstance.patch(
      `/api/project/${id}/area/${areaId}/`,
      data,
      {
        signal: controller.signal,
      },
    )
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// Function to call post API to finalize area by area_id
export const finalizeAreaByAreaId = async (id, areaId, confirm?: boolean) => {
  const controller = createOrReuseController(
    `finalizeAreaByAreaId-${id}-${areaId}`,
  )

  // Prepare the config object with query params if confirm is provided
  const config = {
    signal: controller.signal, // For request cancellation
    params: confirm !== undefined ? { confirm } : {}, // Conditionally include confirm in query params
  }
  try {
    const response = await axiosInstance.post(
      `/api/project/${id}/area/${areaId}/finalize/`,
      null, // No body data payload, so set to null
      config, // Include signal and params in config
    )
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// Function to call post API to verify area by area_id
export const finalizeVerificationAreaByAreaId = async (id, areaId) => {
  const controller = createOrReuseController(
    `finalizeVerificationAreaByAreaId-${id}-${areaId}`,
  )
  try {
    const response = await axiosInstance.post(
      `/api/project/${id}/area/${areaId}/verify/`,
      {
        signal: controller.signal,
      },
    )
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// Function to call get API to fetch source phrase by phrase_id
export const getSourcePhraseByPhraseId = async (id, phraseId) => {
  const controller = createOrReuseController(
    `getSourcePhraseByPhraseId-${id}-${phraseId}`,
  )
  try {
    const response = await axiosInstance.get(
      `/api/project/${id}/source_phrase/${phraseId}/`,
      {
        signal: controller.signal,
      },
    )
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// Function to call delete API to delete source phrase by phrase_id
export const deleteSourcePhraseByPhraseId = async (id, phraseId) => {
  const controller = createOrReuseController(
    `deleteSourcePhraseByPhraseId-${id}-${phraseId}`,
  )
  try {
    const response = await axiosInstance.delete(
      `/api/project/${id}/source_phrase/${phraseId}/`,
      {
        signal: controller.signal,
      },
    )
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// Function to call patch API to update source phrase by phrase_id
export const updateSourcePhraseByPhraseId = async (id, data, phraseId) => {
  const controller = createOrReuseController(
    `updateSourcePhraseByPhraseId-${id}-${phraseId}`,
  )
  try {
    const response = await axiosInstance.patch(
      `/api/project/${id}/source_phrase/${phraseId}/`,
      data,
      {
        signal: controller.signal,
      },
    )
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

/* ----------ROI APIs------------ */

// Function to call post API to create ROI
export const createROI = async (id, data: any) => {
  const controller = createOrReuseController(`createROI-${id}`)
  try {
    const response = await axiosInstance.post(`/api/project/${id}/roi/`, data, {
      signal: controller.signal,
    })
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// Function to call get API to fetch ROIs
export const getROIs = async (id) => {
  const controller = createOrReuseController(`getROIs-${id}`)
  try {
    const response = await axiosInstance.get(`/api/project/${id}/roi/`, {
      signal: controller.signal,
    })
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// Function to call post API to retry ROI
export const retryROI = async (id, roiId) => {
  const controller = createOrReuseController(`retryROI-${id}-${roiId}`)
  try {
    const response = await axiosInstance.post(
      `/api/project/${id}/roi/${roiId}/retry/`,
      {
        signal: controller.signal,
      },
    )
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// Function to call patch API to update ROI
export const updateROI = async (id, data, roiId) => {
  const controller = createOrReuseController(`updateROI-${id}-${roiId}`)
  try {
    const response = await axiosInstance.patch(
      `/api/project/${id}/roi/${roiId}/`,
      data,
      {
        signal: controller.signal,
      },
    )
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// Function to call delete API to delete ROI
export const deleteROI = async (id, roiId) => {
  const controller = createOrReuseController(`deleteROI-${id}-${roiId}`)
  try {
    const response = await axiosInstance.delete(
      `/api/project/${id}/roi/${roiId}/`,
      {
        signal: controller.signal,
      },
    )
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}
// Function to call patch API to acknowledge collation by id
export const acknowledgePhrase = async (id, phraseId) => {
  try {
    const response = await axiosInstance.patch(
      `/api/project/${id}/source_phrase/${phraseId}/acknowledge/`,
    )
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

/* ----------Collation APIs------------ */

// Function to call post API to create manual collation
export const createManualCollation = async (id, data: any, phraseId) => {
  const controller = createOrReuseController(
    `createManualCollation-${id}-${phraseId}`,
  )
  try {
    const response = await axiosInstance.post(
      `/api/project/${id}/source_phrase/${phraseId}/manual_collation/`,
      data,
      {
        signal: controller.signal,
      },
    )
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// Function to call post API to create collation
export const createCollation = async (id, data: any, phraseId) => {
  const controller = createOrReuseController(
    `createCollation-${id}-${phraseId}`,
  )
  try {
    const response = await axiosInstance.post(
      `/api/project/${id}/source_phrase/${phraseId}/collation/`,
      data,
      {
        signal: controller.signal,
      },
    )
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// Function to call get API to fetch all collations
export const getCollation = async (id, phraseId, targetId) => {
  const controller = createOrReuseController(
    `getCollation-${id}-${phraseId}-${targetId}`,
  )
  try {
    const response = await axiosInstance.get(
      `/api/project/${id}/source_phrase/${phraseId}/collation/`,
      {
        params: {
          target_document_id: targetId,
        },
        signal: controller.signal,
      },
    )
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// Function to call patch API to feedback collation by id
export const feedbackCollation = async (id, phraseId, collationId, data) => {
  const controller = createOrReuseController(
    `feedbackCollation-${id}-${phraseId}-${collationId}`,
  )
  try {
    const response = await axiosInstance.patch(
      `/api/project/${id}/source_phrase/${phraseId}/collation/${collationId}/`,
      data,
      {
        signal: controller.signal,
      },
    )
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// Function to call patch API to update collation by id
export const updateCollation = async (id, phraseId, collationId, data) => {
  const controller = createOrReuseController(
    `updateCollation-${id}-${phraseId}-${collationId}`,
  )
  try {
    const response = await axiosInstance.patch(
      `/api/project/${id}/source_phrase/${phraseId}/collation_phrase/${collationId}/`,
      data,
      {
        signal: controller.signal,
      },
    )
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// Function to call delete API to delete collation by id
export const deleteCollation = async (id, phraseId, collationId) => {
  const controller = createOrReuseController(
    `deleteCollation-${id}-${phraseId}-${collationId}`,
  )
  try {
    const response = await axiosInstance.delete(
      `/api/project/${id}/source_phrase/${phraseId}/collation/${collationId}/`,
      {
        signal: controller.signal,
      },
    )
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

// Function to call patch API to acknowledge collation by id
export const acknowledgeCollation = async (id, phraseId, collationId) => {
  const controller = createOrReuseController(
    `acknowledgeCollation-${id}-${phraseId}-${collationId}`,
  )
  try {
    const response = await axiosInstance.patch(
      `/api/project/${id}/source_phrase/${phraseId}/collation_phrase/${collationId}/acknowledge/`,
      {
        signal: controller.signal,
      },
    )
    return response
  } catch (error) {
    console.debug('Error:', error)
    throw error
  }
}

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
} from '@mui/material'
import '../../auth/signup/authpage.scss'

import { Controller, useForm } from 'react-hook-form'
import InputBox from '../../../components/input/input'
import TextButton from '../../../components/button/textButton'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ReusableForm from '../../../components/input/reuseableForm'
import { ReactComponent as EyeClosed } from '../../../assets/images/eye_closed.svg'
import { ReactComponent as EyeOpen } from '../../../assets/images/eye.svg'
import { Password } from '@mui/icons-material'
import { enqueueSnackbar } from 'notistack'
import { login } from './auth-services'
import { useAuth } from '../../../utils/AuthContext'
import { useTranslation } from 'react-i18next'
import { getLocalizedPath } from '../../../utils/shareLocalizePath'
import axios from 'axios'
import { useRecaptcha } from '../../../components/reCaptcha/ReCaptcha'
import { useConditionalRecaptcha } from '../../../components/reCaptcha/useConditionalRecaptcha'

// import { useAuth } from '../../../utils/AuthContext'

interface Props {}
interface FormData {
  email: string
  password: string
  remember_me: boolean
}

function Login({}: Props): JSX.Element {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormData>({ mode: 'onChange' })
  const navigate = useNavigate()
  const [apiStatus, setApiResponse] = useState(false)
  const [type, setType] = useState<string>('password')
  const [icon, setIcon] = useState(<EyeOpen />)
  const { loginUser } = useAuth()
  const { t } = useTranslation()
  // const { isAuthenticated, loginUser } = useAuth()
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language
  // State to hold the reCAPTCHA token
  const [reCaptchaToken, setReCaptchaToken] = useState<string | null>(null)
  const { executeRecaptcha, isRecaptchaReady } = useConditionalRecaptcha()

  // Check environment variable
  const ENABLE_RECAPTCHA = process.env.REACT_APP_ENABLE_RECAPTCHA === 'true'

  useEffect(() => {
    const verify = async () => {
      if (ENABLE_RECAPTCHA && isRecaptchaReady && executeRecaptcha) {
        const recaptchaToken = await executeRecaptcha('login')
        setReCaptchaToken(recaptchaToken)
      }
    }
    verify()
  }, [ENABLE_RECAPTCHA, executeRecaptcha, isRecaptchaReady])

  const handleToggle = () => {
    if (type === 'password') {
      setIcon(<EyeOpen />)
      setType('text')
    } else {
      setIcon(<EyeClosed />)
      setType('password')
    }
  }

  const onSubmitLoginForm = (data: FormData) => {
    if (!reCaptchaToken && ENABLE_RECAPTCHA) {
      enqueueSnackbar(t('Please complete the reCAPTCHA verification'), {
        variant: 'warning',
      })
      return
    }

    setApiResponse(true)
    // Include the reCAPTCHA token in the login data
    const loginData = {
      ...data,
      ...(ENABLE_RECAPTCHA && { token: reCaptchaToken }), // Add reCAPTCHA token to the request payload if reCAPTCHA is enabled
    }
    login(loginData)
      .then((response) => {
        if (response) {
          setApiResponse(false)
          response?.role?.id && loginUser(response?.role?.id as string)
          navigate(`${getLocalizedPath(currentLanguage, '/home')}`)
        }
      })
      .catch((error) => {
        setApiResponse(false)
        if (error?.response?.data?.detail && error?.code === 'ERR_NETWORK') {
          enqueueSnackbar(`${error.response?.message} No internet`, {
            variant: 'error',
            persist: true,
          })
        } else if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.status !== 502
        ) {
          enqueueSnackbar(`${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        } else {
          enqueueSnackbar(t(`Something went wrong`), {
            variant: 'error',
            persist: true,
          })
        }
      })
  }

  return (
    <div className="auth-global-page-wrapper">
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ height: '500px' }}
      >
        <h3>{t('Document Collation App')}</h3>
        <form>
          <div className="contents">
            <div className="input-field-wrapper col-start-center">
              <h6 className="heading"> {t('Email')}</h6>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                  required: t('Email is mandatory'),
                  pattern: {
                    value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                    message: t('Please enter a valid email'),
                  },
                }}
                render={({ field }) => (
                  <>
                    <TextField
                      {...field}
                      error={!!errors.email}
                      id="outlined-basic"
                      variant="outlined"
                      className="input-fields "
                      helperText={errors.email?.message}
                    />
                  </>
                )}
              />
            </div>

            <div className="input-field-wrapper col-start-center">
              <h6 className="heading"> {t('Password')} </h6>
              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{
                  required: t('Password field is mandatory'),
                  // minLength: {
                  //   value: 12,
                  //   message: 'Password must be at least 12 characters long',
                  // },
                  // pattern: {
                  //   value:
                  //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
                  //   message:
                  //     'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character',
                  // },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.password}
                    type={type}
                    id="outlined-basic"
                    variant="outlined"
                    className="input-fields"
                    helperText={errors.password?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ cursor: 'pointer' }}
                          onClick={handleToggle}
                        >
                          {type === 'password' ? <EyeOpen /> : <EyeClosed />}
                        </InputAdornment>
                      ),
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        handleSubmit(onSubmitLoginForm)()
                      }
                    }}
                  />
                )}
              />

              <Stack
                display={'flex'}
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                className="action-wrapper"
                gap={33.3}
              >
                <Stack direction={'row'} alignItems={'center'}>
                  <Controller
                    name="remember_me"
                    defaultValue={false}
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        size="small"
                        style={{ padding: 0 }}
                        {...field}
                      />
                    )}
                  />
                  <span style={{ marginLeft: '2px' }}> {t('Remember Me')}</span>
                </Stack>

                <Link
                  to={getLocalizedPath(currentLanguage, '/forgot-password')}
                >
                  {t('Forgot Password?')}
                </Link>
              </Stack>
            </div>

            <TextButton
              text={t('Log In')}
              color={'#fff'}
              bgColor={
                !isValid || apiStatus
                  ? 'var(--primary-300)' // Use primary-300 for the disable color
                  : 'var(--primary-600)'
              }
              disabled={!isValid || apiStatus}
              onClick={handleSubmit(onSubmitLoginForm)}
              style={{
                width: '100%',
                fontSize: '14px',
                textTransform: 'capitalize',
                boxShadow: 'none',
                padding: '8px 0',
              }}
            />
          </div>
        </form>
        <div className="recaptcha-usage">
          {t('Protected by reCAPTCHA and subject to the Google')}{' '}
          <a href="https://www.google.com/intl/en/policies/privacy/">
            {t('Privacy Policy')}
          </a>{' '}
          {t('and')} &nbsp;
          <a href="https://www.google.com/intl/en/policies/terms/">
            {t('Terms of Service')}
          </a>
          .
        </div>
      </Stack>
    </div>
  )
}

export default Login

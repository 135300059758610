import { Navigate } from 'react-router-dom'
import { useAuth } from './AuthContext'
import { CircularProgress } from '@mui/material'

interface Props {
  children: JSX.Element
  requiredRoles?: string[]
}

const ProtectedRoute = ({ children, requiredRoles }: Props) => {
  const { isAuthenticated, userRole, loading } = useAuth()

  if (loading) {
    return (
      <div
        style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <CircularProgress />
      </div>
    ) // or a spinner component
  }
  if (!isAuthenticated) {
    // user is not authenticated
    return <Navigate to="/" />
  }

  if (requiredRoles && (!userRole || !requiredRoles.includes(userRole))) {
    // user does not have the required role
    return <Navigate to="/" />
  }

  return children
}

export default ProtectedRoute

import React, { useState, useEffect, useRef } from 'react'
import { Tooltip } from '@mui/material'

interface Props {
  row: {
    id: any
    name: string
  }
}

const ProjectNameWithTooltip = ({ row }: Props): JSX.Element => {
  const [isOverflowing, setIsOverflowing] = useState(false)
  const projectNameRef = useRef<HTMLDivElement>(null)

  const checkOverflow = () => {
    const element = projectNameRef.current
    if (element) {
      return element.scrollWidth > element.clientWidth
    }
    return false
  }

  useEffect(() => {
    const handleResize = () => {
      setIsOverflowing(checkOverflow())
    }

    handleResize() // Check initially

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [row.name])

  useEffect(() => {
    setIsOverflowing(checkOverflow())
  }, [row.name])

  return (
    <Tooltip
      title={isOverflowing ? row.name : 'Go to collations'}
      placement="top"
    >
      <div
        ref={projectNameRef}
        id={`projectName${row.id}`}
        className="project-name"
        onClick={() => {
          localStorage.setItem(
            `project_name_with_id_${row.id}`,
            JSON.stringify(row.name),
          )
        }}
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: '200px', // Adjust this value as needed
        }}
      >
        {row.name}
      </div>
    </Tooltip>
  )
}

export default ProjectNameWithTooltip

import { useSearchParams } from 'react-router-dom'
import { viewportToScaled } from '../lib/utils/coordinates'
import type {
  IHighlight,
  LTWH,
  LTWHP,
  Position,
  Scaled,
  ScaledPosition,
} from '../types'

interface HighlightLayerProps<T_HT> {
  highlightsByPage: { [pageNumber: string]: Array<T_HT> }
  pageNumber: string
  scrolledToHighlightId: string
  highlightTransform?: (
    highlight: any,
    isScrolledTo: boolean,
    index: number,
    setTip: (highlight: any, callback: (highlight: any) => JSX.Element) => void,
    hideTip: () => void,
    viewportToScaled: (rect: LTWHP) => Scaled,
    screenshot: (position: LTWH) => string,
  ) => JSX.Element
  tip: {
    highlight: any
    callback: (highlight: any) => JSX.Element
  } | null
  scaledPositionToViewport: (scaledPosition: ScaledPosition) => Position
  hideTipAndSelection: () => void
  viewer: any
  screenshot: (
    position: LTWH,
    pageNumber: number,
    pagesRotation: number,
  ) => string
  showTip: (highlight: any, content: JSX.Element) => void
  setState: (state: any) => void
  highlightAllStates?: any
  areaId?: any
}

export function HighlightLayer<T_HT extends IHighlight>({
  highlightsByPage,
  scaledPositionToViewport,
  pageNumber,
  scrolledToHighlightId,
  highlightTransform,
  tip,
  hideTipAndSelection,
  viewer,
  screenshot,
  showTip,
  setState,
  areaId,
  highlightAllStates,
}: HighlightLayerProps<T_HT>) {
  const currentHighlights = highlightsByPage[pageNumber] ?? []
  return (
    <div>
      {/* <div>
        {currentHighlights.map((highlight, index) => {
          return <div key={highlight.id}>Test Highlight {index}</div>
        })}
      </div> */}
      {currentHighlights?.map(({ position, id, ...highlight }, index) => {
        if (
          areaId &&
          !highlightAllStates[areaId] &&
          id !== scrolledToHighlightId &&
          id
        ) {
          return null // Skip rendering this highlight
        } else {
          const viewportHighlight: any = {
            id,
            position: scaledPositionToViewport(position),
            ...highlight,
          }

          if (tip && tip.highlight.id === id) {
            showTip(tip.highlight, tip.callback(viewportHighlight))
          }

          const isScrolledTo = Boolean(scrolledToHighlightId === id)

          return highlightTransform
            ? highlightTransform(
                viewportHighlight,
                isScrolledTo,
                index,
                (highlight, callback) => {
                  setState({
                    tip: { highlight, callback },
                  })

                  showTip(highlight, callback(highlight))
                },
                hideTipAndSelection,
                (rect) => {
                  const viewport: any = viewer.getPageView(
                    rect.pageNumber ?? parseInt(pageNumber, 10) - 1,
                  ).viewport

                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                  return viewportToScaled(rect, viewport)
                },
                (boundingRect, pagesRotation = 0) =>
                  screenshot(
                    boundingRect,
                    parseInt(pageNumber, 10),
                    pagesRotation,
                  ),
              )
            : null
        }
      })}
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon,
  Box,
  Stack,
  TextField,
} from '@mui/material'
import { ReactComponent as XIcon } from '../../assets/images/x.svg'
import InfoIcon from '../../assets/images/error.svg'
import '../form-dialog/cancel-conformation.scss'
import TextButton from '../button/textButton'
import OutlineTextButton from '../button/textOutlineButton'
import { useTranslation } from 'react-i18next'
interface DynamicCancelDialogProps {
  open: boolean
  message: string
  children?: React.ReactNode
  actions?: React.ReactNode
  onClose?
}

const CancelConformationFormDialog: React.FC<DynamicCancelDialogProps> = ({
  open,
  message,
  children,
  actions,
  onClose,
}) => {
  const disableSaveButton = false
  const [disableCancelButton, setDeleteBtn] = useState(true)

  const [deleteText, setDeleteConfirmText] = useState('')
  const { t } = useTranslation()
  useEffect(() => {
    if (deleteText === 'DELETE') {
      setDeleteBtn(false)
    } else {
      setDeleteBtn(true)
    }
  }, [deleteText])

  const handleDeleteText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeleteConfirmText(event.target.value)
  }
  const onArchive = (e?: any) => {
    onClose('archive')
  }
  const onDeleteProject = (e?: any) => {
    setDeleteBtn(true)
    setDeleteConfirmText('')
    onClose('delete') // Close dialog
  }

  const close = () => {
    onClose()
  }
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={close}
      aria-labelledby="form-dialog-title"
    >
      <Box
        width={'full'}
        height={'32px'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'end'}
        padding={'5px 15px 0 0'}
      >
        <Icon className="global-close-btn">
          <XIcon title="Close" onClick={close} />
        </Icon>
      </Box>

      <Stack display={'flex'} direction={'column'} alignItems={'center'}>
        <Box>
          <img src={InfoIcon} alt="Info" className="infoIcon" />
        </Box>
        <DialogTitle paddingTop={'0px !important'} className="message">
          {message}
        </DialogTitle>
        <div
          style={{
            width: '90%',
            fontWeight: '500',
            fontSize: '14px',
            color: 'var(--gray-600)',
            marginBottom: '12px',
          }}
        >
          <div style={{ margin: '0 0 4px 0' }}>
            {t('Type DELETE to confirm')}
          </div>
          <TextField
            id="delete"
            variant="outlined"
            size="small"
            value={deleteText}
            onChange={handleDeleteText}
            style={{
              width: '100%',
            }}
            autoComplete="off"
          />
        </div>
        <DialogContent>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'start',
              gap: '30px',
            }}
          >
            <TextButton
              text={t('Archive Project')}
              color={'#fff'}
              bgColor={disableSaveButton ? 'var(--red-100)' : 'var(--red-600)'}
              disabled={disableSaveButton}
              onClick={onArchive}
              style={{
                padding: '8px 18px',
              }}
            />
            <OutlineTextButton
              text={t('Delete Project')}
              color={disableCancelButton ? '#fff' : 'var(--gray-800)'}
              outlineColor={'var(--gray-200)'}
              // bgColor="#fff"
              bgColor={disableCancelButton ? 'var(--gray-200)' : '#fff'}
              disabled={disableCancelButton}
              onClick={onDeleteProject}
              style={{
                padding: '8px 18px',
              }}
            />
          </div>
        </DialogContent>

        {actions && <DialogActions>{actions}</DialogActions>}
      </Stack>
    </Dialog>
  )
}
export default CancelConformationFormDialog

import React, { useContext, useEffect, useState } from 'react'
import PdfLoader from '../../lib/PdfLoader'
import { Spinner } from '../spinner/spinner'
import { PdfHighlighter } from '../../lib/PdfHighlighter'
import Highlight from '../../lib/Highlight'
import Popup from '../../lib/Popup'
import { testHighlights as _testHighlights } from '../../lib/test-highlights'

import {
  ProjectContext,
  TargetContext,
  highlightsContext,
} from '../../context/globalContext'
import { Card, CardContent, Typography } from '@mui/material'
import Tip from '../../lib/Tip'
import {
  createCollation,
  createManualCollation,
  getCollation,
  getSourcePhraseByPhraseId,
  updateCollation,
} from '../../services/collation-service'
import { enqueueSnackbar } from 'notistack'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

interface Props {
  isTarget?: boolean
  setIstarget?: (isTarget: boolean) => void
  targetTitle: string
  currentPage: number
  setCurrentPage: (page: number) => void
  handleGetTargetFiles
}

const parseIdFromHash = () => {
  const hash = window.location.hash.substr(1)
  const parts = hash.split(';')
  const state = {
    phrase: parts[0].slice('#phrase-'.length) || '',
    collation: parts.length > 1 ? parts[1].slice('collation-'.length) : '',
  }
  return state.collation
}
// const parseIdFromHash = (): string =>
//   document.location.hash.slice('#collation-'.length)

export const TargetPdf = ({
  isTarget,
  setIstarget,
  targetTitle,
  currentPage,
  setCurrentPage,
  handleGetTargetFiles,
}: Props) => {
  const [pdfTitle, setPdfTitle] = useState<string>(targetTitle)
  const [numPages, setNumPages] = useState<number>(0)
  const [pagesRotation, setPagesRotation] = useState<number>(0)
  const [validHighlights, setValidHighlights] = useState<any>([])
  const { t } = useTranslation()
  // For PDF page layout
  const {
    selectedCollation,
    setSelectedCollation,
    showTargetPdfAndCollation,
    manualCollation,
    collationList,
    setCollationList,
    setManualCollation,
    isCollationEditMode,
    setIsCollationEditMode,
    setIsCollationUpdating,
    areaList,
    setAreaList,
    darkColor,
    phraseId,
    setPhraseId,
    setSourcePhrases,
  } = useContext(highlightsContext)
  const [url, setUrl] = useState<string>('')
  const { targetId } = useContext(TargetContext)
  const { projectId } = useContext(ProjectContext)

  const [tempHighlight, setTempHighlight] = useState<any>([])

  const [queryParameters]: any = useSearchParams()
  useEffect(() => {
    setPdfTitle(targetTitle)
  }, [targetTitle])

  useEffect(() => {
    if (manualCollation.phraseId) {
      setPhraseId(manualCollation.phraseId)
    }
    if (isCollationEditMode.phraseId) {
      setPhraseId(isCollationEditMode.phraseId)
    }
  }, [manualCollation, isCollationEditMode])

  useEffect(() => {
    if (!selectedCollation) {
      setSelectedCollation([])
      return
    }
    const filterHighlights = selectedCollation?.filter(isValidHighlight)
    setValidHighlights(filterHighlights?.length > 0 ? filterHighlights : [])
  }, [selectedCollation])
  useEffect(() => {
    if (projectId && targetId) {
      setUrl(`/api/project/${projectId}/target_document/${targetId}/`)
    }
  }, [targetId, showTargetPdfAndCollation])

  let scrollViewerTo: any = (highlight: any) => {}

  const scrollToHighlightFromHash = () => {
    const highlightId = parseIdFromHash()
    const highlight = getHighlightById(highlightId)
    if (highlight) {
      scrollViewerTo(highlight)
    }
  }

  useEffect(() => {
    window.addEventListener('hashchange', scrollToHighlightFromHash, false)
    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('hashchange', scrollToHighlightFromHash, false)
    }
  }, []) // Empty dependency array to run the effect only once on component mount

  const getHighlightById = (id: string) => {
    return selectedCollation?.find((highlight: any) => highlight.id === +id)
  }

  const handleAddCollation = (highlight: any) => {
    const phraseFormData = {
      target_document_id: +targetId,
      text: highlight.content.text,
      position: highlight.position,
    }
    createCollation(projectId, phraseFormData, phraseId)
      .then((res) => {
        if (res.status === 200 && phraseId) {
          handleGetCollation(phraseId)
          setManualCollation({
            isEditMode: false,
            phraseId: null,
          })
          handleGetTargetFiles(projectId, '', phraseId)
        }
      })
      .catch((error) => {
        setManualCollation({
          isEditMode: false,
          phraseId: null,
        })
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }
  const handleUpdateCollation = (highlight: any) => {
    const phraseFormData = {
      target_document_id: +targetId,
      text: highlight.content.text,
      position: highlight.position,
    }

    updateCollation(
      projectId,
      isCollationEditMode.phraseId,
      isCollationEditMode.collation.id,
      phraseFormData,
    )
      .then((res) => {
        if (res.status === 200 && isCollationEditMode.phraseId) {
          setIsCollationUpdating(true)
          handleGetTargetFiles(projectId, '', phraseId)
          const updatedList = collationList?.map((highlight) => {
            if (highlight.id === isCollationEditMode.collation.id) {
              return res.data.data
            }
            return highlight
          })
          setCollationList(updatedList)
          handleGetPhraseByPhraseId(phraseId)
        }
      })
      .catch((error) => {
        setIsCollationEditMode({
          isEditMode: false,
          phraseId: null,
          collation: null,
        })
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }

  const handleGetCollation = (phraseId) => {
    // getCollation(1, 1, 9)
    getCollation(projectId, phraseId, targetId)
      .then((res) => {
        if (res.status === 200) {
          res.data.data?.length > 0 &&
            res.data.data?.forEach((highlight) => {
              highlight?.collation_results?.length > 0 &&
                highlight?.collation_results?.forEach((result) => {
                  const color = darkColor(result.verification.status)
                  return {
                    ...result,
                    colour: color,
                  }
                })
            })
          setCollationList(res.data.data)
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }
  const handleGetPhraseByPhraseId = (id) => {
    getSourcePhraseByPhraseId(projectId, id)
      .then((res: any) => {
        setSourcePhrases((prev) => {
          // Assuming res contains the updated item data and has an 'id' property
          const updatedItemId = res.data.id
          // Create a new array with the updated item
          const updatedPhrases = prev.map((item) =>
            item.id === updatedItemId ? { ...item, ...res.data } : item,
          )
          // Return the updated array as the new state
          return updatedPhrases
        })
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
            variant: 'error',
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }
  const isValidPosition = (position: any) => {
    // Define the expected keys
    const requiredKeys = [
      'x1',
      'x2',
      'y1',
      'y2',
      'width',
      'height',
      'pageNumber',
    ]

    // Check if all required keys exist and have meaningful values
    return (
      position &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      Object.keys(position).length > 0 &&
      requiredKeys.every(
        (key) =>
          Object.prototype.hasOwnProperty.call(position, key) &&
          position[key] !== undefined,
      )
    )
  }

  const isValidHighlight = (highlight) => {
    // Validate the rects array and boundingRect
    return (
      highlight.position &&
      isValidPosition(highlight.position.boundingRect) &&
      Array.isArray(highlight.position.rects) &&
      highlight.position.rects.every(isValidPosition)
    )
  }

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      {url && (
        <PdfLoader
          url={url}
          beforeLoad={<Spinner />}
          setPdfTitle={setPdfTitle}
          setNumPages={setNumPages}
          setCurrentPage={setCurrentPage}
        >
          {(pdfDocument) =>
            manualCollation.isEditMode || isCollationEditMode.isEditMode ? (
              <PdfHighlighter
                pdfDocument={pdfDocument}
                highlights={
                  tempHighlight.length > 0
                    ? tempHighlight
                    : validHighlights?.length > 0
                      ? validHighlights
                      : []
                }
                setTempHighlight={setTempHighlight}
                setIstarget={setIstarget}
                isTarget={isTarget}
                fromTarget={true}
                page={currentPage}
                totalPages={numPages}
                pagesRotation={pagesRotation}
                highlightAllStates={{}}
                setPagesRotation={setPagesRotation}
                pdfTitle={pdfTitle}
                t={t}
                setCurrentPage={setCurrentPage}
                scrollRef={(scrollTo) => {
                  scrollViewerTo = scrollTo
                  scrollToHighlightFromHash()
                }}
                onSelectionFinished={(
                  position,
                  content,
                  hideTipAndSelection,
                  transformSelection,
                ) => (
                  <Tip
                    onOpen={transformSelection}
                    handleClose={() => {
                      hideTipAndSelection()
                      setManualCollation({
                        isEditMode: false,
                        phraseId: null,
                      })
                      setIsCollationEditMode({
                        isEditMode: false,
                        phraseId: null,
                        collation: null,
                      })
                    }}
                    isEditMode={
                      manualCollation.isEditMode ||
                      isCollationEditMode.isEditMode
                    }
                    onConfirm={(comment) => {
                      if (manualCollation.isEditMode) {
                        handleAddCollation({ content, position })
                      }
                      if (isCollationEditMode.isEditMode) {
                        handleUpdateCollation({ content, position })
                      }
                      hideTipAndSelection()
                    }}
                    t={t}
                  />
                )}
                highlightTransform={(
                  highlight,
                  isScrolledTo,
                  index,
                  setTip,
                  hideTip,
                ) => {
                  const component = (
                    <Highlight
                      isScrolledTo={isScrolledTo}
                      position={highlight.position}
                      color={highlight.colour}
                      fromTarget={true}
                      selectedId={parseIdFromHash}
                      highlightId={highlight.id}
                    />
                  )
                  return (
                    <Popup
                      onMouseOver={(popupContent) => {
                        setTip(highlight, () => <>{popupContent}</>)
                      }}
                      key={index}
                      onMouseOut={hideTip}
                      popupContent={<></>}
                    >
                      {component}
                    </Popup>
                  )
                }}
                targetId={targetId}
              />
            ) : (
              <PdfHighlighter
                pdfDocument={pdfDocument}
                highlights={
                  tempHighlight.length > 0
                    ? tempHighlight
                    : validHighlights?.length > 0
                      ? validHighlights
                      : []
                }
                setTempHighlight={setTempHighlight}
                setIstarget={setIstarget}
                isTarget={isTarget}
                fromTarget={true}
                page={currentPage}
                totalPages={numPages}
                pagesRotation={pagesRotation}
                highlightAllStates={{}}
                setPagesRotation={setPagesRotation}
                pdfTitle={pdfTitle}
                setCurrentPage={setCurrentPage}
                t={t}
                scrollRef={(scrollTo) => {
                  scrollViewerTo = scrollTo
                  scrollToHighlightFromHash()
                }}
                highlightTransform={(
                  highlight,
                  isScrolledTo,
                  index,
                  setTip,
                  hideTip,
                ) => {
                  const component = (
                    <Highlight
                      isScrolledTo={isScrolledTo}
                      position={highlight.position}
                      color={highlight.colour}
                      fromTarget={true}
                    />
                  )
                  return (
                    <Popup
                      onMouseOver={(popupContent) => {
                        setTip(highlight, () => <>{popupContent}</>)
                      }}
                      key={index}
                      onMouseOut={hideTip}
                      popupContent={<></>}
                    >
                      {component}
                    </Popup>
                  )
                }}
                targetId={targetId}
              />
            )
          }
        </PdfLoader>
      )}
    </div>
  )
}

const formatScore = (score: number): string => {
  if (score >= 0 && score < 1) {
    return score.toFixed(4)
  }
  if (score > 1) {
    return score.toFixed(3).padStart(6, '0')
  }
  if (score < 0) {
    return '-' + Math.abs(score).toFixed(2).padStart(5, '0')
  }
  return ''
}
// interface HighlightCommentProps {
//   highlight: {
//     is_selected?: boolean
//     rank?: number
//     maxScore?: number
//   }
// }
// export const HighlightComment = ({ highlight }: HighlightCommentProps) => {
//   return (
//     <Card
//       style={{
//         height: 'fit-content',
//         width: 'fit-content',
//         borderRadius: '1rem',
//         boxShadow:
//           '0 0.125rem 0.5rem rgba(0, 0, 0, .3), 0 0.0625rem 0.125rem rgba(0, 0, 0, .2)',
//         padding: '0px !important',
//         display: highlight?.is_selected ? 'flex' : 'none',
//       }}
//     >
//       <CardContent
//         sx={{ padding: '8px !important' }}
//         style={{
//           display: 'flex',
//           flexDirection: 'column',
//           justifyContent: 'center',
//           alignItems: 'start',
//           gap: '5px',
//         }}
//       >
//         <Typography
//           style={{ fontWeight: '500', fontSize: '14px' }}
//           component="div"
//         >
//           Rank:{' '}
//           <span style={{ fontWeight: '400', fontSize: '13px' }}>
//             {highlight.rank}
//           </span>
//         </Typography>
//         <Typography
//           style={{ fontWeight: '500', fontSize: '14px' }}
//           component="div"
//         >
//           Score:{' '}
//           <span style={{ fontWeight: '400', fontSize: '13px' }}>
//             {formatScore(highlight.maxScore ?? 0)}
//           </span>
//         </Typography>
//       </CardContent>
//     </Card>
//   )
// }

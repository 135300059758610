import React from 'react'
import '../../styles/variables.scss'
import { useTranslation } from 'react-i18next'

interface ByteConverterProps {
  bytes: number
  decimal?: number
  only?: string // Corrected type to string
}

const ByteConverter: React.FC<ByteConverterProps> = ({
  bytes,
  decimal = 2, // Default value for decimal
  only,
}) => {
  const { t } = useTranslation()

  const byteConverter = (bytes: number, decimals: number, only?: string) => {
    const K_UNIT = 1024
    const SIZES = [t('bytes'), t('kb'), t('mb'), t('gb'), t('tb'), t('pb')]

    if (bytes === 0) return t('bytes', { count: 0 }) // Using count for pluralization if necessary

    if (only === 'MB')
      return (bytes / (K_UNIT * K_UNIT)).toFixed(decimals) + ` ${t('mb')}`

    const i = Math.floor(Math.log(bytes) / Math.log(K_UNIT))
    const resp =
      parseFloat((bytes / Math.pow(K_UNIT, i)).toFixed(decimals)) +
      ' ' +
      SIZES[i]

    return resp
  }

  return <div>{byteConverter(bytes, decimal, only)} </div>
}

export default ByteConverter

import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon,
  Box,
  type DialogProps,
} from '@mui/material'
import { ReactComponent as XIcon } from '../../assets/images/x.svg'
import { useTranslation } from 'react-i18next'

interface DynamicDialogProps {
  open: boolean
  title?: string
  children: React.ReactNode
  actions?: React.ReactNode
  onClose: () => void
  setFile?: any
  setProjectName?: any
  setUploadProgress?: any
  disableClose?: any
}

const FormDialog: React.FC<DynamicDialogProps> = ({
  open,
  title,
  children,
  actions,
  onClose,
  setProjectName,
  setFile,
  setUploadProgress,
  disableClose,
}) => {
  const { t } = useTranslation()
  const handleClose: DialogProps['onClose'] = (e: any, reason: any) => {
    // Check if the reason for closing is the backdrop click
    if (reason === 'backdropClick') {
      return // Do nothing if the backdrop was clicked
    }
    if (setProjectName) {
      // setProjectName('')
    }
    setFile(null)
    setUploadProgress(null)
    onClose() // Close dialog
  }
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableEscapeKeyDown={Boolean(disableClose)}
    >
      <Box
        width={'full'}
        height={'32px'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'end'}
        padding={'5px 15px 0 0'}
      >
        {title === 'Edit' && !disableClose ? (
          ''
        ) : !disableClose ? (
          <Icon className="global-close-btn">
            <XIcon
              title="Close"
              onClick={(event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
                !disableClose && handleClose(event, 'escapeKeyDown')
              }}
            />
          </Icon>
        ) : (
          ''
        )}
      </Box>

      <DialogTitle
        fontWeight={600}
        fontSize={'20px'}
        paddingTop={'0px !important'}
      >
        {title}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  )
}

export default FormDialog

import React, {
  PointerEventHandler,
  PureComponent,
  RefObject,
  StrictMode,
} from 'react'
import ReactDom from 'react-dom'
import debounce from 'lodash.debounce'
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  OutlinedInput,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import RotateRightIcon from '@mui/icons-material/RotateRight'
import EastIcon from '@mui/icons-material/East'
import WestIcon from '@mui/icons-material/West'
import ZoomInIcon from '../../src/assets/images/Plus.svg'
import ZoomOutIcon from '../../src/assets/images/Minus.svg'
import RotateIcon from '../../src/assets/images/restart.svg'
import kebabIcon from '../../src/assets/images/kebab.svg'
import searhIcon from '../../src/assets/images/magnifer.svg'
import SearchOffIcon from '@mui/icons-material/SearchOff'
import {
  EventBus,
  PDFViewer,
  PDFLinkService,
  NullL10n,
  PDFSinglePageViewer,
} from 'pdfjs-dist/legacy/web/pdf_viewer'

import 'pdfjs-dist/web/pdf_viewer.css'
import './style/pdf_viewer.css'
import './style/PdfHighlighter.scss'

import getBoundingRect from './utils/get-bounding-rect'
import getClientRects from './utils/get-client-rects'
import getAreaAsPng from './utils/get-area-as-png'
import {
  asElement,
  getPagesFromRange,
  getPageFromElement,
  getWindow,
  findOrCreateContainerLayer,
  isHTMLElement,
} from './utils/pdfjs-dom'

import TipContainer from './TipContainer'
import MouseSelection from './MouseSelection'

import { scaledToViewport, viewportToScaled } from './utils/coordinates'

import type {
  Position,
  ScaledPosition,
  IHighlight,
  Scaled,
  LTWH,
  LTWHP,
} from '../types'
import type { PDFDocumentProxy } from 'pdfjs-dist'
import { enqueueSnackbar } from 'notistack'
import { Link } from 'react-router-dom'
import { Root, createRoot } from 'react-dom/client'
import { HighlightLayer } from './HighlightLayer'
type T_ViewportHighlight<T_HT> = { position: Position } & T_HT

interface State<T_HT> {
  ghostHighlight: {
    position: ScaledPosition
    content?: { text?: string; image?: string }
  } | null
  isCollapsed: boolean
  range: Range | null
  tip: {
    highlight: T_ViewportHighlight<T_HT>
    callback: (highlight: T_ViewportHighlight<T_HT>) => JSX.Element
  } | null
  tipPosition: Position | null
  tipChildren: JSX.Element | null
  isAreaSelectionInProgress: boolean
  scrolledToHighlightId: string
  currentPage: number
  pdfScaleValue: any
  snackBarOpen: boolean
  message: string
}

interface Props<T_HT> {
  highlightTransform?: (
    highlight: T_ViewportHighlight<T_HT>,
    isScrolledTo,
    index: any,
    setTip: (
      highlight: T_ViewportHighlight<T_HT>,
      callback: (highlight: T_ViewportHighlight<T_HT>) => JSX.Element,
    ) => void,
    hideTip: () => void,
    viewportToScaled: (rect: LTWHP) => Scaled,
    screenshot: (position: LTWH) => string,
  ) => JSX.Element
  singleHighlightTransform?: (
    highlight: T_ViewportHighlight<T_HT>,
    isScrolledTo,
    setTip: (
      highlight: T_ViewportHighlight<T_HT>,
      callback: (highlight: T_ViewportHighlight<T_HT>) => JSX.Element,
    ) => void,
    hideTip: () => void,
    viewportToScaled: (rect: LTWHP) => Scaled,
    screenshot: (position: LTWH) => string,
  ) => any

  highlights?: any
  searchHighlight?: any

  onScrollChange?: () => void
  scrollRef?: (scrollTo: (highlight: T_HT) => void) => void
  pdfDocument: PDFDocumentProxy
  isSearchPossible?: any
  onSelectionFinished?: (
    position: ScaledPosition,
    content: { text?: string; image?: string },
    hideTipAndSelection: () => void,
    transformSelection: () => void,
  ) => JSX.Element | null
  enableAreaSelection?: (event: MouseEvent) => boolean
  page: number
  totalPages: number
  pagesRotation: number
  setPagesRotation: any
  isImageHighlight?: boolean
  pdfTitle?: string
  targetOnly?: any
  sourceOnly?: any
  isTarget?: boolean
  setIstarget?: any
  isLayoutReset?: boolean
  fromTarget?: boolean
  hideTargetControls?: boolean
  hideSourceControls?: boolean
  setCurrentPage?: any
  handleScroll?: any
  highlightAllStates?: any
  areaId?: any
  t: any
  setTempHighlight?: any
  sourceId?: any
  targetId?: any
}

const EMPTY_ID = 'empty-id'

export class PdfHighlighter<T_HT extends IHighlight> extends PureComponent<
  Props<T_HT>,
  State<T_HT>
> {
  inputRef = React.createRef()
  state: State<T_HT> = {
    ghostHighlight: null,
    isCollapsed: true,
    range: null,
    scrolledToHighlightId: EMPTY_ID,
    isAreaSelectionInProgress: false,
    tip: null,
    tipPosition: null,
    tipChildren: null,
    currentPage: 1,
    pdfScaleValue: 'page-width',
    snackBarOpen: false,
    message: '',
  }

  eventBus = new EventBus()
  linkService = new PDFLinkService({
    eventBus: this.eventBus,
    externalLinkTarget: 2,
  })

  viewer!: PDFViewer

  resizeObserver: ResizeObserver | null = null
  containerNode?: HTMLDivElement | null = null
  containerNodeRef: RefObject<HTMLDivElement>
  highlightRoots: {
    [page: number]: { reactRoot: Root; container: Element }
  } = {}
  unsubscribe = () => {}

  constructor(props: Props<T_HT>) {
    super(props)
    if (typeof ResizeObserver !== 'undefined') {
      this.resizeObserver = new ResizeObserver(this.debouncedScaleValue)
    }
    this.containerNodeRef = React.createRef()
  }

  componentDidMount() {
    this.init()
  }
  /** Set state of currentPage on Pdf page change **/
  onPageChange = (e: any) => {
    this.setState({ currentPage: e.pageNumber })
  }
  attachRef = () => {
    const { eventBus, resizeObserver: observer } = this
    const ref = (this.containerNode = this.containerNodeRef!.current)
    this.unsubscribe()

    if (ref) {
      const { ownerDocument: doc } = ref
      eventBus.on('textlayerrendered', this.onTextLayerRendered)
      eventBus.on('pagesinit', this.onDocumentReady)
      /** On pagechanging event run in function  **/
      eventBus.on('pagechanging', this.onPageChange)
      doc.addEventListener('selectionchange', this.onSelectionChange)
      doc.addEventListener('keydown', this.handleKeyDown)
      doc.defaultView?.addEventListener('resize', this.debouncedScaleValue)
      if (observer) observer.observe(ref)

      this.unsubscribe = () => {
        eventBus.off('pagesinit', this.onDocumentReady)
        eventBus.off('textlayerrendered', this.onTextLayerRendered)
        doc.removeEventListener('selectionchange', this.onSelectionChange)
        doc.removeEventListener('keydown', this.handleKeyDown)
        doc.defaultView?.removeEventListener('resize', this.debouncedScaleValue)
        if (observer) observer.disconnect()
      }
    }
  }

  componentDidUpdate(prevProps: Props<T_HT>) {
    if (prevProps.pdfDocument !== this.props.pdfDocument) {
      this.init()
      return
    }

    if (prevProps.highlights !== this.props.highlights) {
      this.renderHighlightLayers(this.props)
    }

    if (prevProps.pagesRotation != this.props.pagesRotation) {
      this.viewer.pagesRotation = this.props.pagesRotation
    }

    // Check for changes in showAllHighlight
    if (prevProps.highlightAllStates !== this.props.highlightAllStates) {
      this.renderHighlightLayers() // Call method to update the highlight layer
    }
    if (prevProps.page != this.props.page) {
      if (this.viewer?._pages && this.viewer?._pages?.length > 0) {
        this.handleScrollToPage(+this.props.page)
      }
    }
    // To call scrollTo funtion on hash change
    window.addEventListener('hashchange', this.onDocumentReady, false)
  }

  // This function initializes the PDF viewer component.
  init() {
    // Get the `pdfDocument` object from `props`.
    const { pdfDocument } = this.props
    this.attachRef()

    // Create a new `PDFSinglePageViewer` instance for the viewer if it is not already present.

    this.viewer =
      this.viewer ||
      new PDFViewer({
        // Set the container DOM node where the viewer should be rendered.
        container: this.containerNodeRef!.current!,
        // Pass in the event bus to handle viewer events.
        eventBus: this.eventBus,

        // Set the text layer mode to "2" (for improved performance).
        textLayerMode: 2,
        // Remove page borders to make the viewer more sleek.
        removePageBorders: true,
        // Pass in the link service to handle hyperlink clicks.
        linkService: this.linkService,
        // Provide the l10n localization library (currently `NullL10n`).
        l10n: NullL10n,
      })

    // Update the link service properties with the `pdfDocument` reference.
    this.linkService.setDocument(pdfDocument)
    this.linkService.setViewer(this.viewer)

    // Associate the viewer with the `pdfDocument` object.
    this.viewer.setDocument(pdfDocument)
    this.viewer._setScale('page-width')

    // Debugging: Expose the PdfViewer object on the window object for debugging purposes.
    ;(window as any).PdfViewer = this
    this.setState({ currentPage: 1 })
  }

  componentWillUnmount() {
    this.unsubscribe()
    this.viewer.container.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('hashchange', this.onDocumentReady, false)
  }

  findOrCreateHighlightLayer(page: number) {
    if (!this.props.highlightTransform || !this.props.highlights) {
      throw new Error('Missing props')
    }

    const { textLayer } = this.viewer.getPageView(page - 1) ?? {}

    if (!textLayer) {
      return null
    }

    return findOrCreateContainerLayer(
      textLayer.textLayerDiv,
      'PdfHighlighter__highlight-layer',
    )
  }
  // for single highlight layer
  findOrCreateSingleHighlightLayer(page: number) {
    if (this.props.singleHighlightTransform && this.props.searchHighlight) {
      const { textLayer } = this.viewer.getPageView(page - 1) || {}

      if (!textLayer) {
        return null
      }

      return findOrCreateContainerLayer(
        textLayer.textLayerDiv,
        'PdfHighlighter__highlight-layer',
      )
    }
    // throw new Error("Missing props");
    return
  }

  // To group highlights in ghostHighlight state based on pageNumber
  groupHighlightsByPage(highlights: Array<T_HT> | any): {
    [pageNumber: string]: Array<T_HT>
  } {
    const groupedHighlights = {} as Record<number, Array<T_HT>>
    if (highlights.length > 0) {
      const { ghostHighlight } = this.state
      const allHighlights = [...highlights, ghostHighlight].filter(Boolean)
      const pageNumbers = new Set<number>()

      for (const highlight of allHighlights) {
        if (highlight.position && Array.isArray(highlight.position.rects)) {
          pageNumbers.add(highlight.position.pageNumber)

          for (const rect of highlight.position.rects) {
            if (rect && rect.pageNumber) {
              pageNumbers.add(rect.pageNumber)
            }
          }
        }
      }

      for (const pageNumber of pageNumbers) {
        groupedHighlights[pageNumber] = groupedHighlights[pageNumber] || []

        for (const highlight of allHighlights) {
          if (highlight.position && Array.isArray(highlight.position.rects)) {
            const pageSpecificHighlight = {
              ...highlight,
              position: {
                pageNumber,
                boundingRect: highlight.position.boundingRect,
                rects: [],
                usePdfCoordinates: highlight.position.usePdfCoordinates,
              } as ScaledPosition,
            }

            let anyRectsOnPage = false
            for (const rect of highlight.position.rects) {
              if (
                pageNumber ===
                (rect.pageNumber || highlight.position.pageNumber)
              ) {
                pageSpecificHighlight.position.rects.push(rect)
                anyRectsOnPage = true
              }
            }

            if (
              anyRectsOnPage ||
              pageNumber === highlight.position.pageNumber
            ) {
              groupedHighlights[pageNumber].push(pageSpecificHighlight)
            }
          }
        }
      }
    }
    return groupedHighlights
  }

  // To group highlights in ghostHighlight state based on pageNumber
  groupSingleHighlightByPage(highlight: any): any {
    const { ghostHighlight } = this.state
    const allHighlights = highlight ? [highlight] : []
    if (ghostHighlight) {
      allHighlights.push(ghostHighlight)
    }
    const groupedHighlights = {} as Record<number, T_HT>

    if (highlight) {
      const { position } = highlight
      const pageNumber = position.pageNumber
      const pageSpecificHighlight = {
        ...highlight,
        position: {
          pageNumber,
          boundingRect: position.boundingRect,
          rects: position.rects.filter(
            (rect) => rect.pageNumber === pageNumber,
          ),
          usePdfCoordinates: position.usePdfCoordinates,
        } as ScaledPosition,
      }
      groupedHighlights[pageNumber] = pageSpecificHighlight
    }

    return groupedHighlights
  }

  showTip(highlight: T_ViewportHighlight<T_HT>, content: JSX.Element) {
    const { isCollapsed, ghostHighlight, isAreaSelectionInProgress } =
      this.state

    const highlightInProgress = !isCollapsed || ghostHighlight

    if (highlightInProgress || isAreaSelectionInProgress) {
      return
    }

    this.setTip(highlight.position, content)
  }

  scaledPositionToViewport({
    pageNumber,
    boundingRect,
    rects,
    usePdfCoordinates,
  }: ScaledPosition): Position {
    const viewport = this.viewer.getPageView(pageNumber - 1).viewport
    return {
      boundingRect: scaledToViewport(
        boundingRect,
        viewport,
        usePdfCoordinates,
        this.viewer.pagesRotation,
      ),
      rects: (rects || [])?.map((rect) =>
        scaledToViewport(
          rect,
          viewport,
          usePdfCoordinates,
          this.viewer.pagesRotation,
        ),
      ),
      pageNumber,
    }
  }

  viewportPositionToScaled({
    pageNumber,
    boundingRect,
    rects,
  }: Position): ScaledPosition | any {
    const viewport = this.viewer.getPageView(pageNumber - 1).viewport
    if (!viewport) {
      console.debug(`Page view not found for page number ${pageNumber}`)
      return
    }

    return {
      boundingRect: viewportToScaled(
        boundingRect,
        viewport,
        this.viewer.pagesRotation,
      ),
      rects: (rects || [])?.map((rect) =>
        viewportToScaled(rect, viewport, this.viewer.pagesRotation),
      ),
      pageNumber,
    }
  }

  screenshot(position: LTWH, pageNumber: number, pagesRotation: number) {
    const canvas = this.viewer.getPageView(pageNumber - 1).canvas
    return getAreaAsPng(canvas, position, pagesRotation)
  }

  hideTipAndSelection = () => {
    this.setState({
      tipPosition: null,
      tipChildren: null,
    })

    this.setState({ ghostHighlight: null, tip: null }, () =>
      this.renderHighlightLayers(this.props),
    )
    this.props.setTempHighlight([])
  }

  setTip(position: Position, inner: JSX.Element | null) {
    this.setState({
      tipPosition: position,
      tipChildren: inner,
    })
  }

  renderTip = () => {
    const { tipPosition, tipChildren } = this.state
    if (!tipPosition) return null

    const { boundingRect, pageNumber } = tipPosition
    const page = {
      node: this.viewer.getPageView((boundingRect.pageNumber || pageNumber) - 1)
        .div,
      pageNumber: boundingRect.pageNumber || pageNumber,
    }

    const pageBoundingClientRect = page.node.getBoundingClientRect()

    const pageBoundingRect = {
      bottom: pageBoundingClientRect.bottom,
      height: pageBoundingClientRect.height,
      left: pageBoundingClientRect.left,
      right: pageBoundingClientRect.right,
      top: pageBoundingClientRect.top,
      width: pageBoundingClientRect.width,
      x: pageBoundingClientRect.x,
      y: pageBoundingClientRect.y,
      pageNumber: page.pageNumber,
    }

    return (
      <TipContainer
        scrollTop={this.viewer.container.scrollTop}
        pageBoundingRect={pageBoundingRect}
        style={{
          left:
            page.node.offsetLeft + boundingRect.left + boundingRect.width / 2,
          top: boundingRect.top + page.node.offsetTop,
          bottom: boundingRect.top + page.node.offsetTop + boundingRect.height,
        }}
      >
        {tipChildren}
      </TipContainer>
    )
  }

  onTextLayerRendered = () => {
    this.renderHighlightLayers(this.props)
  }
  scrollTo = (highlight: T_HT) => {
    const { pageNumber, boundingRect, usePdfCoordinates } = highlight.position
    this.viewer.container.removeEventListener('scroll', this.onScroll)

    const pageView = this.viewer?.getPageView(pageNumber - 1)

    if (!pageView) {
      console.debug(`Page view not found for page number ${pageNumber}`)
      return
    }

    const pageViewport = pageView.viewport

    const scrollMargin = 10

    this.viewer.scrollPageIntoView({
      pageNumber,
      destArray: [
        null,
        { name: 'XYZ' },
        ...pageViewport.convertToPdfPoint(
          0,
          scaledToViewport(
            boundingRect,
            pageViewport,
            usePdfCoordinates,
            this.viewer.pagesRotation,
          ).top - scrollMargin,
        ),
        0,
      ],
    })

    this.setState(
      {
        scrolledToHighlightId: highlight.id,
      },
      () => this.renderHighlightLayers(this.props),
    )

    setTimeout(() => {
      this.viewer.container.addEventListener('scroll', this.onScroll)
    }, 100)
  }

  onDocumentReady = () => {
    const { scrollRef } = this.props
    this.handleScaleValue()
    let rotationFromLS = localStorage.getItem(
      `${this.props.fromTarget ? `target_${this.props.targetId}` : `source_${this.props.sourceId}`}_rotation`,
    )
    this.viewer.pagesRotation = rotationFromLS ? JSON.parse(rotationFromLS) : 0
    if (scrollRef) {
      scrollRef(this.scrollTo)
    }
  }
  processRangeForNewlines(range) {
    let text = ''
    const iterator = document.createNodeIterator(
      range.commonAncestorContainer,
      NodeFilter.SHOW_TEXT | NodeFilter.SHOW_ELEMENT,
      {
        acceptNode(node) {
          return NodeFilter.FILTER_ACCEPT
        },
      },
    )

    let currentNode
    while ((currentNode = iterator.nextNode())) {
      if (range.intersectsNode(currentNode)) {
        if (currentNode.nodeType === Node.TEXT_NODE) {
          const nodeRange = document.createRange()
          nodeRange.selectNodeContents(currentNode)

          if (
            range.compareBoundaryPoints(Range.START_TO_START, nodeRange) > 0
          ) {
            nodeRange.setStart(range.startContainer, range.startOffset)
          }
          if (range.compareBoundaryPoints(Range.END_TO_END, nodeRange) < 0) {
            nodeRange.setEnd(range.endContainer, range.endOffset)
          }

          text += nodeRange.toString()
        } else if (
          currentNode.tagName === 'BR' ||
          currentNode.tagName === 'P'
        ) {
          text += '\n' // Add a newline for <br> or <p> tags
        }
      }
    }

    return text
  }

  onSelectionChange = () => {
    const container = this.containerNode
    const selection = getWindow(container).getSelection()
    if (!selection) {
      return
    }

    const range = selection.rangeCount > 0 ? selection.getRangeAt(0) : null

    if (selection.isCollapsed) {
      this.setState({ isCollapsed: true })
      return
    }

    if (
      !range ||
      !container ||
      !container.contains(range.commonAncestorContainer)
    ) {
      return
    }

    this.setState({
      isCollapsed: false,
      range,
    })

    this.debouncedAfterSelection()
  }

  onScroll = () => {
    if (this.props.onScrollChange && this.props.scrollRef) {
      const { onScrollChange } = this.props
      onScrollChange()
      this.setState(
        {
          scrolledToHighlightId: EMPTY_ID,
        },
        () => this.renderHighlightLayers(this.props),
      )

      this.viewer.container.removeEventListener('scroll', this.onScroll)
    }
  }

  onMouseDown: PointerEventHandler = (event) => {
    if (!isHTMLElement(event.target)) {
      return
    }

    if (asElement(event.target).closest('.PdfHighlighter__tip-container')) {
      return
    }

    this.hideTipAndSelection()
    this.togglePointerEvents(false) // Disable pointer events during selection
  }

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.code === 'Escape') {
      this.hideTipAndSelection()
    }
  }

  afterSelection = () => {
    const { onSelectionFinished, singleHighlightTransform } = this.props

    const { isCollapsed, range } = this.state

    if (!range || isCollapsed) {
      this.togglePointerEvents(true) // Re-enable pointer events after selection
      return
    }

    const pages = getPagesFromRange(range)

    if (!pages || pages?.length === 0) {
      this.togglePointerEvents(true) // Re-enable pointer events after selection
      return
    }

    const rects = getClientRects(range, pages)

    if (rects?.length === 0) {
      this.togglePointerEvents(true) // Re-enable pointer events after selection
      return
    }

    const boundingRect = getBoundingRect(rects)

    const viewportPosition: Position = {
      boundingRect,
      rects,
      pageNumber: pages[0].number,
    }

    const selectedTextWithNewLines = this.processRangeForNewlines(range)

    const content = {
      // Assign the processed text to the content object
      text: selectedTextWithNewLines,
    }
    const scaledPosition = this.viewportPositionToScaled(viewportPosition)
    if (onSelectionFinished) {
      this.props.setTempHighlight([
        { id: null, text: content.text, position: scaledPosition, temp: true },
      ])
      this.setTip(
        viewportPosition,
        onSelectionFinished(
          scaledPosition,
          content,
          () => {
            this.hideTipAndSelection()
            this.togglePointerEvents(true) // Re-enable pointer events after selection
          },
          () =>
            this.setState(
              {
                ghostHighlight: { position: scaledPosition },
              },
              () => this.renderHighlightLayers(this.props),
            ),
        ),
      )
    }
  }

  debouncedAfterSelection: () => void = debounce(this.afterSelection, 500)

  toggleTextSelection(flag: boolean) {
    this.viewer.viewer!.classList.toggle(
      'PdfHighlighter--disable-selection',
      flag,
    )
  }

  togglePointerEvents(enable: boolean) {
    const textLayerDivs = document.querySelectorAll('.textLayer div')
    textLayerDivs.forEach((div: any) => {
      div.style.pointerEvents = enable ? 'auto' : 'none'
    })
  }
  handleScaleValue = () => {
    if (this.viewer) {
      let scaleFromLS = localStorage.getItem(
        `${this.props.fromTarget ? `target_${this.props.targetId}` : `source_${this.props.sourceId}`}_scale`,
      )
      const scaleValue = scaleFromLS ? JSON.parse(scaleFromLS) : 'page-width'

      // Ensure the viewer element is present and has the currentScaleValue property
      if (this.viewer && this.viewer.currentScaleValue !== undefined) {
        this.viewer.currentScaleValue = scaleValue

        this.renderHighlightLayers(this.props)
      } else {
        console.error('Viewer or currentScaleValue is not available')
      }
    }
  }

  debouncedScaleValue: () => void = debounce(this.handleScaleValue, 500)
  /** On press enter scroll towards target page number **/
  handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      let pageNumber = Number(event.target.value)
      let destArray: any
      let allowNegativeOffset: any
      let ignoreDestinationZoom: any
      this.viewer.scrollPageIntoView({
        pageNumber,
        destArray,
        allowNegativeOffset,
        ignoreDestinationZoom,
      })
    }
  }
  handleScrollToPage = (pageNumber: number) => {
    this.viewer.scrollPageIntoView({
      pageNumber,
    })
  }

  /** reset value as per current page **/
  handleChange = (e: any) => {
    if (e.target.value > this.props.totalPages) {
      const randomType = 'info'
      enqueueSnackbar(`Maximum number of pages are ${this.props.totalPages}`, {
        variant: randomType,
      })
      this.setState({ currentPage: this.props.totalPages })
    } else {
      this.setState({ currentPage: e.target.value })
    }
  }

  handleRotationRight = () => {
    if (this.props.pagesRotation >= 0) {
      if (this.props.pagesRotation === 270) {
        this.props.setPagesRotation(0)
        localStorage.setItem(
          `${this.props.fromTarget ? `target_${this.props.targetId}` : `source_${this.props.sourceId}`}_rotation`,
          JSON.stringify(0),
        )
      } else {
        this.props.setPagesRotation(this.props.pagesRotation + 90)
        localStorage.setItem(
          `${this.props.fromTarget ? `target_${this.props.targetId}` : `source_${this.props.sourceId}`}_rotation`,
          JSON.stringify(this.props.pagesRotation + 90),
        )
      }
      // this.zoomInWhenRotatedHorizontally()
    }
  }

  zoomInWhenRotatedHorizontally = () => {
    if (this.props.pagesRotation === 0 || this.props.pagesRotation === 180) {
      this.viewer.increaseScale()
    } else {
      this.viewer.decreaseScale()
    }
    this.setState({ pdfScaleValue: this.viewer.currentScale })
  }
  switchScreen = (e) => {
    if (this.props.isTarget === false) {
      // reupdate the hash value to reload pdf
      let updatedHash = document.location.hash
      document.location.hash = ''
      document.location.hash = updatedHash
      this.props.setIstarget(true)
    } else if (this.props.isTarget === true) {
      this.props.setIstarget(false)
    }
  }
  render() {
    const { onSelectionFinished, enableAreaSelection } = this.props
    return (
      //  When the pointer is pressed down on the div, call the onMouseDown function
      <div
        onPointerDown={this.onMouseDown}
        style={{ position: 'relative', height: 'calc(100vh - 200px)' }}
      >
        {/* Pdf controls part Start Here */}
        <div
          className="sourcePdf-actions"
          style={{
            display: this.props.page < 1 ? 'none' : 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              // flex:
              //   this.props.hideSourceControls || this.props.hideTargetControls
              //     ? '1 0 auto'
              //     : '1 1 auto',
              display: '-webkit-box',
              WebkitLineClamp: 1, // Number of lines to show
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {/* tooltip for target pdf title */}

            <Tooltip title={this.props.pdfTitle} placement="top">
              <Typography>{this.props.pdfTitle} </Typography>
            </Tooltip>
          </div>

          <Stack
            direction={'row'}
            gap={1}
            alignItems={'center'}
            style={{
              marginLeft: '10px',
              overflow: 'hidden',
              flex:
                this.props.hideSourceControls || this.props.hideTargetControls
                  ? '1 1 auto'
                  : '1 0 auto',
              whiteSpace: 'pre',
              textOverflow:
                this.props.hideSourceControls || this.props.hideTargetControls
                  ? 'ellipsis'
                  : 'unset',
              justifyContent: 'center',
            }}
          >
            {/* This part contains the "Zoom in" & "Zoom out" buttons */}

            {/* To Zoom in */}
            <Tooltip title={this.props.t('Zoom In')} placement="top">
              <IconButton
                style={{ padding: 0 }}
                aria-label="Zoom In"
                onClick={() => {
                  this.viewer.increaseScale()
                  this.setState({ pdfScaleValue: this.viewer.currentScale })
                  localStorage.setItem(
                    `${this.props.fromTarget ? `target_${this.props.targetId}` : `source_${this.props.sourceId}`}_scale`,
                    JSON.stringify(this.viewer.currentScale),
                  )
                }}
              >
                <img src={ZoomInIcon} />
              </IconButton>
            </Tooltip>

            {/* To Zoom out */}
            <Tooltip title={this.props.t('Zoom out')} placement="top">
              <IconButton
                style={{ padding: 0 }}
                aria-label="Zoom Out"
                onClick={() => {
                  this.viewer.decreaseScale()
                  this.setState({ pdfScaleValue: this.viewer.currentScale })
                  localStorage.setItem(
                    `${this.props.fromTarget ? `target_${this.props.targetId}` : `source_${this.props.sourceId}`}_scale`,
                    JSON.stringify(this.viewer.currentScale),
                  )
                }}
              >
                <img src={ZoomOutIcon} />
              </IconButton>
            </Tooltip>
            <Divider orientation="vertical" sx={{ height: '25px' }} />
            {/* This div contains an input field for selecting a specific page number */}
            {/* Label for the page number input field */}
            {/* Button for going to previous page. It is disabled if on the first page. */}
            <Tooltip
              title={this.props.t('Move to Previous page')}
              placement="top"
            >
              <span>
                <IconButton
                  aria-label="Previous"
                  style={{ padding: 0 }}
                  disabled={this.state.currentPage <= 1}
                  onClick={() => this.viewer.previousPage()}
                >
                  <WestIcon fontSize="inherit" />
                </IconButton>
              </span>
            </Tooltip>
            {/* Input field for entering a page number. It has a minimum value of 1 and a maximum value of totalPages */}
            <Tooltip
              title={this.props.t('Type to jump to a page number')}
              placement="top"
            >
              <OutlinedInput
                id="pagenumber"
                type="number"
                className="pagenumber-input"
                sx={{
                  input: {
                    textAlign: 'center',
                  },
                }}
                inputProps={{ min: 1, max: this.props.totalPages }}
                value={this.state.currentPage}
                onChange={this.handleChange}
                onKeyPress={this.handleKeyPress}
              />
              {/* Label showing the current page and total number of pages */}
            </Tooltip>
            <label
              style={{
                display:
                  this.props.hideSourceControls || this.props.hideTargetControls
                    ? 'none'
                    : 'flex',
                color: 'black',
              }}
              htmlFor="pagenumber"
            >
              {' '}
              of {this.props.totalPages}
            </label>

            {/* Button for going to next page. It is disabled if on the last page. */}
            <Tooltip title={this.props.t('Move to Next page')} placement="top">
              <IconButton
                aria-label="Next"
                style={{ padding: 0 }}
                disabled={this.state.currentPage === this.props.totalPages}
                onClick={() => this.viewer.nextPage()}
              >
                <EastIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
            <Divider orientation="vertical" sx={{ height: '25px' }} />

            <Tooltip title={this.props.t('Rotate Right')} placement="top">
              <IconButton
                style={{ padding: 0 }}
                onClick={this.handleRotationRight}
              >
                <img src={RotateIcon} />
              </IconButton>
            </Tooltip>

            {/* To reset zoom */}
            {/* <Tooltip title={'Zoom In'} placement="top">
                            <IconButton
                              style={{ padding: 0 }}
                              aria-label="Reset Zoom"
                              onClick={() => {
                                this.setState({ pdfScaleValue: 'auto' })
                                this.debouncedScaleValue()
                              }}
                            >
                              <SearchOffIcon fontSize="inherit" />
                            </IconButton>
                          </Tooltip> */}
          </Stack>
          <Box>
            {}
            {/* <img src={kebabIcon} alt="" /> */}
            {/* <img src={searhIcon} alt="" /> */}
          </Box>
        </div>
        <Divider />
        {/* Pdf controls part End Here */}
        <div
          ref={this.containerNodeRef}
          className="PdfHighlighter"
          onContextMenu={(e) => e.preventDefault()}
        >
          <div className="pdfViewer" />
          {this.renderTip()}
          {typeof enableAreaSelection === 'function' ? (
            <MouseSelection
              onDragStart={() => this.toggleTextSelection(true)}
              onDragEnd={() => this.toggleTextSelection(false)}
              onChange={(isVisible) =>
                this.setState({ isAreaSelectionInProgress: isVisible })
              }
              shouldStart={(event) =>
                enableAreaSelection(event) &&
                isHTMLElement(event.target) &&
                Boolean(asElement(event.target).closest('.page'))
              }
              onSelection={(startTarget, boundingRect, resetSelection) => {
                const page = getPageFromElement(startTarget)

                if (!page) {
                  return
                }

                const pageBoundingRect = {
                  ...boundingRect,
                  top: boundingRect.top - page.node.offsetTop,
                  left: boundingRect.left - page.node.offsetLeft,
                  pageNumber: page.number,
                }

                const viewportPosition = {
                  boundingRect: pageBoundingRect,
                  rects: [],
                  pageNumber: page.number,
                }

                const scaledPosition =
                  this.viewportPositionToScaled(viewportPosition)

                const image = this.screenshot(
                  pageBoundingRect,
                  pageBoundingRect.pageNumber,
                  this.props.pagesRotation,
                )

                {
                  if (onSelectionFinished) {
                    this.props.setTempHighlight([
                      {
                        id: null,
                        position: scaledPosition,
                        temp: true,
                        content: { image },
                      },
                    ])
                    this.setTip(
                      viewportPosition,
                      onSelectionFinished(
                        scaledPosition,
                        { image },
                        () => this.hideTipAndSelection(),
                        () =>
                          this.setState(
                            {
                              ghostHighlight: {
                                position: scaledPosition,
                                content: { image },
                              },
                            },
                            () => {
                              resetSelection()
                              this.renderHighlightLayers(this.props)
                            },
                          ),
                      ),
                    )
                  }
                }
              }}
            />
          ) : null}
        </div>
      </div>
    )
  }
  private renderHighlightLayers(nextProps?: Props<T_HT>) {
    const { pdfDocument, highlights, highlightTransform, fromTarget } =
      nextProps || this.props
    if (highlights && highlightTransform) {
      for (
        let pageNumber = 1;
        pageNumber <= pdfDocument.numPages;
        pageNumber++
      ) {
        const highlightRoot = this.highlightRoots[pageNumber]
        /** Need to check if container is still attached to the DOM as PDF.js can unload pages. */
        if (highlightRoot && highlightRoot.container.isConnected) {
          this.renderHighlightLayer(highlightRoot.reactRoot, pageNumber)
        } else {
          const highlightLayer = this.findOrCreateHighlightLayer(pageNumber)
          if (highlightLayer) {
            const reactRoot = createRoot(highlightLayer)
            this.highlightRoots[pageNumber] = {
              reactRoot,
              container: highlightLayer,
            }
            this.renderHighlightLayer(reactRoot, pageNumber)
          }
        }
      }
    }
  }
  // renderSingleHighlight(nextProps?: Props<T_HT>) {
  //   const { pdfDocument, searchHighlight, singleHighlightTransform } =
  //     nextProps || this.props
  //   if (
  //     searchHighlight &&
  //     searchHighlight.position &&
  //     Object.keys(searchHighlight.position).length !== 0 &&
  //     singleHighlightTransform
  //   ) {
  //     const highlightsByPage = this.groupSingleHighlightByPage(searchHighlight)

  //     for (
  //       let pageNumber = 1;
  //       pageNumber <= pdfDocument.numPages;
  //       pageNumber++
  //     ) {
  //       const highlightRoot = this.highlightRoots[pageNumber]

  //       if (highlightRoot && highlightRoot.container.isConnected) {
  //         this.singleHighlightLayer(highlightRoot.reactRoot, pageNumber)
  //       } else {
  //         const highlightLayer =
  //           this.findOrCreateSingleHighlightLayer(pageNumber)
  //         if (highlightLayer) {
  //           if (highlightLayer) {
  //             const reactRoot = createRoot(highlightLayer)
  //             this.highlightRoots[pageNumber] = {
  //               reactRoot,
  //               container: highlightLayer,
  //             }
  //             this.singleHighlightLayer(reactRoot, pageNumber)
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  private renderHighlightLayer(root: Root, pageNumber: number) {
    const { highlightTransform, highlights, highlightAllStates, fromTarget } =
      this.props
    const { tip, scrolledToHighlightId } = this.state
    if (fromTarget) {
      root.render(
        <StrictMode>
          <HighlightLayer
            highlightsByPage={this.groupHighlightsByPage(highlights)}
            pageNumber={pageNumber.toString()}
            scrolledToHighlightId={scrolledToHighlightId}
            highlightTransform={highlightTransform}
            tip={tip}
            scaledPositionToViewport={this.scaledPositionToViewport.bind(this)}
            hideTipAndSelection={this.hideTipAndSelection.bind(this)}
            viewer={this.viewer}
            screenshot={this.screenshot.bind(this)}
            showTip={this.showTip.bind(this)}
            setState={this.setState.bind(this)}
            highlightAllStates={this.props.highlightAllStates}
          />
        </StrictMode>,
      )
    } else {
      if (highlights) {
        root.render(
          <StrictMode>
            <HighlightLayer
              key={`highlight-layer-${pageNumber}-${String(this.props.highlightAllStates[this.props.areaId])}`}
              highlightsByPage={this.groupHighlightsByPage(
                highlights.ROI_boxs ? highlights?.ROI_boxs : highlights,
              )}
              pageNumber={pageNumber.toString()}
              scrolledToHighlightId={scrolledToHighlightId}
              highlightTransform={highlightTransform}
              tip={tip}
              scaledPositionToViewport={this.scaledPositionToViewport.bind(
                this,
              )}
              hideTipAndSelection={this.hideTipAndSelection.bind(this)}
              viewer={this.viewer}
              screenshot={this.screenshot.bind(this)}
              showTip={this.showTip.bind(this)}
              setState={this.setState.bind(this)}
              highlightAllStates={this.props.highlightAllStates}
              areaId={this.props.areaId}
            />
          </StrictMode>,
        )
      }
    }
  }
  // private singleHighlightLayer(root: Root, pageNumber: number) {
  //   const { singleHighlightTransform, searchHighlight } = this.props
  //   const { tip, scrolledToHighlightId } = this.state
  //   root.render(
  //     <SingleHighlightLayer
  //       highlightsByPage={this.groupSingleHighlightByPage(searchHighlight)}
  //       pageNumber={pageNumber.toString()}
  //       scrolledToHighlightId={scrolledToHighlightId}
  //       singleHighlightTransform={singleHighlightTransform}
  //       tip={tip}
  //       scaledPositionToViewport={this.scaledPositionToViewport.bind(this)}
  //       hideTipAndSelection={this.hideTipAndSelection.bind(this)}
  //       viewer={this.viewer}
  //       screenshot={this.screenshot.bind(this)}
  //       showTip={this.showTip.bind(this)}
  //       setState={this.setState.bind(this)}
  //       searchHighlight={searchHighlight}
  //     />,
  //   )
  // }
}

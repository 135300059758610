import { isHTMLCanvasElement } from './pdfjs-dom'
import type { LTWHP } from '../../types'

const getAreaAsPNG = (
  canvas: HTMLCanvasElement,
  position: LTWHP,
  pagesRotation: number,
): string => {
  const { left, top, width, height } = position

  const doc = canvas ? canvas.ownerDocument : null
  // @TODO: cache this?
  const newCanvas = doc?.createElement('canvas')

  if (!newCanvas || !isHTMLCanvasElement(newCanvas)) {
    return ''
  }

  newCanvas.width = width
  newCanvas.height = height

  const newCanvasContext = newCanvas.getContext('2d')

  if (!newCanvasContext || !canvas) {
    return ''
  }

  const dpr: number = window.devicePixelRatio
  // When pagesRotation value is 90 degree then rotatating canvas to 0 degree
  if (pagesRotation === 90) {
    newCanvasContext.rotate((-90 * Math.PI) / 180)
    newCanvasContext.translate(-height, 0)
  }
  // When pagesRotation value is 180 degree then rotatating canvas to 0 degree
  else if (pagesRotation === 180) {
    newCanvasContext.rotate((-180 * Math.PI) / 180)
    newCanvasContext.translate(-width, -height)
  }
  // When pagesRotation value is 270 degree then rotatating canvas to 0 degree
  else if (pagesRotation === 270) {
    newCanvasContext.rotate((-270 * Math.PI) / 180)
    newCanvasContext.translate(0, -width)
  }
  newCanvasContext.drawImage(
    canvas,
    left * dpr,
    top * dpr,
    width * dpr,
    height * dpr,
    0,
    0,
    width,
    height,
  )
  return newCanvas.toDataURL('image/png')
}

export default getAreaAsPNG

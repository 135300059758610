import {
  Box,
  Button,
  Divider,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useContext, useState } from 'react'
import { ReactComponent as InsertUploadIcon } from '../../assets/images/insert_upload.svg'
import { ReactComponent as CloseIcon } from '../../assets/images/close_circle.svg'
import { ReactComponent as ActiveCloseIcon } from '../../assets/images/filled_circle_close.svg'
import { ReactComponent as CheckIcon } from '../../assets/images/circle_filled_check.svg'
import { enqueueSnackbar } from 'notistack'
import { styled } from '@mui/material/styles'
import './multiUpload.scss'
import { StatusContext } from '../../context/globalContext'
import { Trans, useTranslation } from 'react-i18next'
import { AnyMxRecord } from 'dns'

const UploadButton = styled(Button)({
  border: '2px dashed var(--gray-200)',
  borderRadius: '10px',
  padding: '20px',
  background: 'var(--gray-50)',
  textAlign: 'center',
  '&:hover': {
    border: '2px dashed var(--gray-500)',
  },
})

interface Props {
  selectedFiles: any
  files
  setFiles
  uploadProgress
  handleFileChange
  isProgressComplete
  removeSelectedTargetFile
  fileUploadLimit: number
  targetFiles
  setNoOfTargetFiles
}
function MultiUpload({
  selectedFiles,
  setFiles,
  files,
  uploadProgress,
  handleFileChange,
  isProgressComplete,
  removeSelectedTargetFile,
  fileUploadLimit,
  targetFiles,
  setNoOfTargetFiles,
}: Props) {
  const [isDragged, setIsDragged] = useState<boolean>(false)
  const { projectStatus, targetDocumentStatus, collationStatus } =
    useContext(StatusContext)
  const { t } = useTranslation()
  const MAX_FILE_SIZE_MB = 30
  const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024
  // Handle Drag over events
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setIsDragged(true) // to show box-shadow effect when dragging
  }

  // Handle Drag leave events
  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    // setIsDragged(false) // to remove box-shadow effect when dragging leave
  }
  // Function to normalize file names by removing unwanted suffixes
  const normalizeFileName = (fileName: string) => {
    // Regular expression to match unwanted suffixes
    const suffixPattern = /\(\d+\)\s*\(copy\)?|-?\d{2}:\d{2}:\d{2}:\d{2}$/
    return fileName.replace(suffixPattern, '').trim()
  }
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    // setIsDragged(false)

    // Check if files are actually dropped
    const droppedFiles = Array.from(e.dataTransfer.files).map((file) => {
      const normalizedFile = new File([file], normalizeFileName(file.name), {
        type: file.type,
      })
      return normalizedFile
    })

    if (e.dataTransfer && e.dataTransfer.files.length > 0) {
      // Filter out non-PDF files and notify the user if any are found
      const validFiles = droppedFiles.filter((file: any) => {
        const isPdf =
          file.type === 'application/pdf' || file.name.endsWith('.pdf')
        if (!isPdf) {
          enqueueSnackbar(
            t(`Only PDF files are allowed. "${file.name}" is not a PDF file.`),
            {
              variant: 'error',
              persist: true,
            },
          )
        }

        const isValidSize = file.size <= MAX_FILE_SIZE_BYTES
        if (!isValidSize) {
          enqueueSnackbar(
            t(
              `"${file.name}" exceeds the maximum allowed size of ${MAX_FILE_SIZE_MB} MB.`,
            ),
            {
              variant: 'error',
              persist: true,
            },
          )
        }

        return isPdf && isValidSize
      })

      // Check if the number of valid files exceeds the upload limit
      if (selectedFiles.length >= 30) {
        enqueueSnackbar(
          t(
            `Only the first ${fileUploadLimit} PDF files have been selected as the upload limit is ${fileUploadLimit} files at a time.`,
          ),
          {
            variant: 'error',
            persist: true,
          },
        )
      }

      const firstFilesUpToLimit = validFiles.slice(0, fileUploadLimit)

      setFiles((prevFiles: any) => [...prevFiles, ...firstFilesUpToLimit])
      setNoOfTargetFiles((preVal) => preVal - firstFilesUpToLimit.length)
    } else {
      console.log('files  dropped not successful')
    }

    // // Slice the array to enforce the file upload limit and add unique IDs for React key purposes
    // const filesToAdd = validFiles
    //   .slice(0, fileUploadLimit)
    //   .map((file: any, index) => Object.assign(file, { id: index }))

    // setNoOfTargetFiles((preVal) => preVal - filesToAdd.length)
    // // Update the state with the new files, ensuring not to exceed the upload limit
    // setFiles((prevFiles: any) => {
    //   // If adding the new files doesn't exceed the limit
    //   const totalPotentialFiles = prevFiles.length + filesToAdd.length
    //   if (totalPotentialFiles <= fileUploadLimit) {
    //     return [...prevFiles, ...filesToAdd]
    //   } else {
    //     // Calculate how many new files can be added without exceeding the limit
    //     const spaceForNewFiles = fileUploadLimit - prevFiles.length
    //     const filesThatFit = filesToAdd.slice(0, spaceForNewFiles)
    //     enqueueSnackbar(
    //       t(
    //         `You can only add ${spaceForNewFiles} more file(s) due to the limit of ${fileUploadLimit} files.`,
    //       ),
    //       {
    //         variant: 'warning',
    //         persist: true,
    //       },
    //     )
    //     return [...prevFiles, ...filesThatFit]
    //   }
    // })
  }
  const formatFileSize = (sizeInBytes) => {
    const oneKB = 1000
    const oneMB = 1000 * oneKB

    if (sizeInBytes < oneMB) {
      return t(`${(sizeInBytes / oneKB).toFixed(1)} KB`) // Size in kilobytes
    } else {
      return t(`${(sizeInBytes / oneMB).toFixed(1)} MB`) // Size in megabytes
    }
  }

  return (
    <>
      <div
        id={'drag-over'}
        onDrop={(event) => {
          handleDrop(event)
        }}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        className="file-upload-box"
        onClick={() => document.getElementById('upload-button-file')?.click()}
        style={{
          borderRadius: '16px',
          height: '134px',
          opacity:
            projectStatus === 'COLLATION_VERIFICATION' ||
            projectStatus === 'WAITING_FOR_COLLATION_APPROVAL'
              ? 0.5
              : 1,
          boxShadow:
            projectStatus === 'COLLATION_VERIFICATION' ||
            projectStatus === 'WAITING_FOR_COLLATION_APPROVAL'
              ? 'none'
              : 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
          pointerEvents:
            (targetDocumentStatus === 'DONE' &&
              targetFiles?.some(
                (file) =>
                  file.status !== 'uploaded' &&
                  file.status !== 'embedded' &&
                  file.status !== 'failed',
              )) ||
            projectStatus === 'COLLATION_IN_PROGRESS' ||
            projectStatus === 'PREPARING_FOR_COLLATION' ||
            projectStatus === 'INHERITING_COLLATIONS' ||
            projectStatus === 'COLLATION_VERIFICATION' ||
            projectStatus === 'WAITING_FOR_COLLATION_APPROVAL' ||
            !isProgressComplete
              ? 'none'
              : 'auto',
        }}
      >
        <label htmlFor="upload-button-file">
          <input
            id="upload-button-file"
            hidden
            accept="application/pdf"
            onChange={handleFileChange}
            multiple
            type="file"
            style={{ width: '120px', height: '300px', backgroundColor: 'red' }}
          />
        </label>
        <UploadButton fullWidth variant="outlined">
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <InsertUploadIcon />
            <div
              style={{
                color: 'var(--gray-500)',
                textTransform: 'none',
              }}
            >
              <Trans
                values={{ fileUploadLimit }}
                components={{ b: <strong />, br: <br /> }}
                tOptions={{
                  interpolation: { escapeValue: true },
                }}
                shouldUnescape
              >
                <strong>Click to upload</strong> or drag and drop{' '}
                {{ fileUploadLimit }} Files Allowed
                <br />
                Maximum Upload File Size: 30 MB Per File
              </Trans>
            </div>
          </Box>
        </UploadButton>
      </div>
      {selectedFiles && selectedFiles.length > 0 && (
        <Stack
          direction="column"
          spacing={2}
          mt={2}
          mb={2}
          className="selected-file-display"
        >
          <div className="files-container">
            {selectedFiles?.map((file, index) => {
              const progressItem = uploadProgress.find(
                (item) => item.filename === file.name,
              )
              return (
                <div
                  className="each-file"
                  style={{
                    alignItems: isProgressComplete ? 'center' : 'start',
                    borderTop: '1px solid var(--gray-200)',
                  }}
                  key={file.name}
                >
                  <Stack
                    direction="column"
                    style={{
                      height: '54px',
                      padding: '10px  0 0 0',
                    }}
                  >
                    <div className="file-name">
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'start',
                          flexDirection: 'column',
                          gap: 4,
                          textAlign: 'left',
                          width:
                            progressItem?.progress !== undefined
                              ? '40%'
                              : '95%',
                        }}
                      >
                        <Tooltip title={file.name} placement="top-start">
                          <span
                            style={{
                              fontWeight: '500',
                              fontSize: '14px',
                              color: 'var(--gray-600)',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              textWrap: 'nowrap',
                              width: '100%',
                            }}
                          >
                            {file.name}
                          </span>
                        </Tooltip>
                        <span
                          className="file-size"
                          style={{
                            fontWeight: '400',
                            fontSize: '12px',
                            color: 'var(--gray-600)',
                          }}
                        >
                          {formatFileSize(file.size)}
                        </span>
                      </div>
                      {progressItem && progressItem?.updated === false ? (
                        <Box
                          display={'flex'}
                          alignItems={'start'}
                          justifyContent={'center'}
                          gap={1}
                          width={'45%'}
                          flexDirection={'column'}
                        >
                          <div className="progress-display">
                            <span>{progressItem.progress}%</span>
                          </div>
                          {progressItem.progress !== undefined && (
                            <LinearProgress
                              className="webkit-fill-available moz-available fill-available"
                              sx={{ width: '100%' }}
                              variant="determinate"
                              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                              value={Number(progressItem.progress)}
                            />
                          )}
                        </Box>
                      ) : isProgressComplete ? (
                        <span className="icon-wrapper">
                          <CloseIcon
                            className="action default-icon"
                            style={{ cursor: 'pointer' }}
                            onClick={() => removeSelectedTargetFile(file.name)}
                          />
                          <ActiveCloseIcon
                            className="action active-icon"
                            style={{ cursor: 'pointer' }}
                            onClick={() => removeSelectedTargetFile(file.name)}
                          />
                        </span>
                      ) : (
                        progressItem?.updated && (
                          <CheckIcon
                            className="action"
                            style={{ cursor: 'pointer' }}
                          />
                        )
                      )}
                    </div>
                  </Stack>
                </div>
              )
            })}
          </div>
        </Stack>
      )}
    </>
  )
}

export default MultiUpload

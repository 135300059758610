import React from 'react'
import moment from 'moment'
interface DateFormatterProps {
  dateString: string
}

class DateFormatter extends React.Component<DateFormatterProps> {
  render() {
    const { dateString } = this.props

    // Convert the provided date string to the desired format
    const formattedDate = moment(dateString).format('YYYY/MM/DD')

    const formattedTime = moment(dateString).format('HH:mm')

    return (
      <>
        <div>{formattedDate}</div>
        <div>{formattedTime}</div>
      </>
    )
  }
}

export default DateFormatter

import React, { Component } from 'react'

import './style/Highlight.css'

import type { LTWHP } from '../types.js'

interface Props {
  position: {
    boundingRect: LTWHP
    rects: Array<LTWHP>
  }
  color?: string
  onClick?: () => void
  onMouseOver?: () => void
  onMouseOut?: () => void
  isScrolledTo?: boolean
  areaId?: any
  highlightAllStates?: any
  fromTarget?: boolean
  selectedId?: any
  highlightId?: any
}

export class Highlight extends Component<Props> {
  // myRef = React.createRef();
  private readonly myRef = React.createRef<HTMLDivElement>()
  render() {
    const {
      position,
      color,
      onClick,
      onMouseOver,
      onMouseOut,
      isScrolledTo,
      areaId,
      highlightAllStates,
      fromTarget,
      selectedId,
      highlightId,
    } = this.props
    const { rects, boundingRect } = position
    return (
      <div
        className={`Highlight ${isScrolledTo ? 'Highlight--scrolledTo' : ''}`}
      >
        <div className="Highlight__parts">
          {rects?.map((rect, index) => (
            <div key={index}>
              {fromTarget ? (
                <div
                  ref={this.myRef}
                  onMouseOver={onMouseOver}
                  onMouseOut={onMouseOut}
                  onClick={onClick}
                  key={index}
                  style={{
                    left: rect.left,
                    top: rect.top,
                    width: rect.width,
                    height: rect.height,
                    background: color ?? '#ffe28f',
                  }}
                  className={`Highlight__part`}
                />
              ) : (
                <div
                  ref={this.myRef}
                  onMouseOver={onMouseOver}
                  onMouseOut={onMouseOut}
                  onClick={onClick}
                  key={index}
                  style={{
                    left: rect.left,
                    top: rect.top,
                    width: rect.width,
                    height: rect.height,
                    background:
                      areaId &&
                      highlightAllStates[areaId] &&
                      +selectedId === highlightId
                        ? '#e7b11b'
                        : '#ffe28f',
                  }}
                  className={`Highlight__part`}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default Highlight

import React, { Component } from 'react'

import './style/AreaHighlight.css'

import type { ViewportHighlight } from '../types'

interface Props {
  highlight: ViewportHighlight
  isScrolledTo: boolean
}

export class AreaHighlight extends Component<Props> {
  render() {
    const { highlight, isScrolledTo } = this.props

    return (
      <div
        className={`AreaHighlight ${
          isScrolledTo ? 'AreaHighlight--scrolledTo' : ''
        }`}
        style={{
          position: 'absolute',
          top: highlight.position.boundingRect.top,
          left: highlight.position.boundingRect.left,
          width: highlight.position.boundingRect.width,
          height: highlight.position.boundingRect.height,
        }}
        // onClick={(event: Event) => {
        //   event.stopPropagation()
        //   event.preventDefault()
        // }}
      />
    )
  }
}

export default AreaHighlight

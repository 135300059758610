import InputBase from '@mui/material/InputBase'
import { styled, alpha } from '@mui/material/styles'
import type { InputBaseProps } from '@mui/material/InputBase/InputBase'
import { useState } from 'react'

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(2), // Adjusted to match the label spacing
  },
  '& .MuiInputBase-input': {
    borderRadius: '8px', // Assuming the radius is correct as per the image
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : '#1A2027', // Changed to white for light mode
    border: '1px solid #ced4da', // Adjusted border color to match the image
    fontSize: 14, // Assuming the font size is correct as per the image
    width: '100% !important', // Changed to full width
    padding: '0px 12px !important', // Assuming padding is correct as per the image
    marginTop: '8px',
    height: '38px',

    // transition: theme.transitions.create([
    //   'border-color',
    //   'background-color',
    //   'box-shadow',
    // ]),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.1rem`,
      borderColor: theme.palette.primary.main,
    },
  },

  '& .MuiFormLabel': {
    fontWeight: '500',
  },
}))

interface CustomInputProps extends InputBaseProps {
  inputProps?: InputBaseProps['inputProps'] // Accept inputProps
}

export default function InputBox(props: CustomInputProps) {
  return <BootstrapInput {...props} />
}

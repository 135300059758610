import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material'
import { useState, useRef, useEffect } from 'react'
import '../../auth/signup/authpage.scss'
import { useParams, useNavigate } from 'react-router-dom'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { resetPassword } from './auth-services'
import { enqueueSnackbar } from 'notistack'
import TextButton from '../../../components/button/textButton'
import { ReactComponent as EyeClosed } from '../../../assets/images/eye_closed.svg'
import { ReactComponent as EyeOpen } from '../../../assets/images/eye.svg'
import { useTranslation } from 'react-i18next'
import { getLocalizedPath } from '../../../utils/shareLocalizePath'
import { useRecaptcha } from '../../../components/reCaptcha/ReCaptcha'
import PasswordStrengthMeter from '../../../components/password-strength/PasswordStrengthMenter'
import { useConditionalRecaptcha } from '../../../components/reCaptcha/useConditionalRecaptcha'

interface Props {}

interface FormData {
  email: string
  reset_password_code: string
  new_password: string
  confirm_password: string
  remember_me: boolean
}

function ResetPasswordCode({}: Props): JSX.Element {
  const params = useParams()
  const resetPasswordToken = params?.token
  const [apiStatus, setApiResponse] = useState(false)
  const resetPasswordTokenAvailable =
    resetPasswordToken !== null && resetPasswordToken !== undefined
  const [type1, setType1] = useState<string>('password')
  const [type2, setType2] = useState<string>('password')
  const [icon, setIcon] = useState(<EyeOpen />)
  const inputRef = useRef<any>(null)
  const { t, i18n } = useTranslation()
  const currentLanguage = i18n.language
  const navigate = useNavigate()
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
    getValues,
    trigger,
    setError,
  } = useForm<FormData>({ mode: 'onChange' })
  const [reCaptchaToken, setReCaptchaToken] = useState<string | null>(null)
  const { executeRecaptcha, isRecaptchaReady } = useConditionalRecaptcha()

  const newPassword = useWatch({
    control,
    name: 'new_password',
  })

  useEffect(() => {
    trigger('confirm_password')
  }, [newPassword, trigger])

  // Check environment variable
  const ENABLE_RECAPTCHA = process.env.REACT_APP_ENABLE_RECAPTCHA === 'true'
  useEffect(() => {
    const verify = async () => {
      if (ENABLE_RECAPTCHA && isRecaptchaReady && executeRecaptcha) {
        const recaptchaToken = await executeRecaptcha('resetPassword')
        setReCaptchaToken(recaptchaToken)
      }
    }
    verify()
  }, [ENABLE_RECAPTCHA, executeRecaptcha, isRecaptchaReady])

  /***
   * on form submitmode: "all"
   */
  const onSubmitResetPasswordForm = (data: FormData) => {
    if (!reCaptchaToken && ENABLE_RECAPTCHA) {
      enqueueSnackbar(t('Please complete the reCAPTCHA verification'), {
        variant: 'warning',
      })
      return
    }
    setApiResponse(true)
    const resetPasswordData = {
      ...data,
      ...(ENABLE_RECAPTCHA && { token: reCaptchaToken }), // Add reCAPTCHA token to the request payload if reCAPTCHA is enabled
    }
    resetPassword(resetPasswordData)
      .then((response) => {
        if (response) {
          setApiResponse(false)
          // reset form
          reset()
          inputRef.current.value = ''
          navigate(`${getLocalizedPath(currentLanguage, '/')}`)
          enqueueSnackbar(
            t(`Password updated successfully. Please login to continue.`),
            {
              variant: 'success',
              persist: true,
            },
          )
          // navigate('/home')
        }
      })
      .catch((error) => {
        setApiResponse(false)
        // reset form
        reset()
        inputRef.current.value = ''
        if (
          error?.response?.data?.detail &&
          error?.response?.data?.type === 'validation_error' &&
          error?.response?.status === 400
        ) {
          const errorMessage = error?.response?.data?.detail
          if (errorMessage) {
            setError('new_password', {
              type: 'manual',
              message: errorMessage,
            })
          }
        }
        if (error?.response?.data?.detail && error?.response?.status === 422) {
          enqueueSnackbar(`${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }

  /** toggle password 1 visibility */
  const handleToggle1 = () => {
    if (type1 === 'password') {
      setIcon(<EyeOpen />)
      setType1('text')
    } else {
      setIcon(<EyeClosed />)
      setType1('password')
    }
  }

  /** toggle password 2 visibility */
  const handleToggle2 = () => {
    if (type2 === 'password') {
      setIcon(<EyeOpen />)
      setType2('text')
    } else {
      setIcon(<EyeClosed />)
      setType2('password')
    }
  }

  return (
    <div className="auth-global-page-wrapper">
      <Stack display={'flex'} direction={'column'} gap={2}>
        <h3>{t('Document Collation App')}</h3>

        <form onSubmit={handleSubmit(onSubmitResetPasswordForm)}>
          <Stack
            direction="column"
            justifyContent="start"
            alignItems="start"
            className="contents"
          >
            <Stack
              direction="column"
              justifyContent="start"
              alignItems="start"
              className="input-field-wrapper"
            >
              <h6 className="heading"> {t('Reset Password Code')} </h6>
              <Controller
                name="reset_password_code"
                control={control}
                defaultValue={resetPasswordToken}
                rules={{ required: t('Invite Code is mandatory') }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    ref={inputRef}
                    error={!!errors.reset_password_code}
                    id="outlined-basic"
                    InputProps={{
                      readOnly: resetPasswordTokenAvailable,
                    }}
                    variant="outlined"
                    className="input-fields"
                    helperText={errors.reset_password_code?.message}
                    value={resetPasswordToken}
                  />
                )}
              />
            </Stack>

            <Stack
              direction="column"
              justifyContent="start"
              alignItems="start"
              className="input-field-wrapper"
            >
              <h6 className="heading"> {t('New Password')} </h6>
              <Controller
                name="new_password"
                control={control}
                defaultValue=""
                rules={{
                  required: t('New password is mandatory'),
                  minLength: {
                    value: 12,
                    message: t('Password must be at least 12 characters long'),
                  },
                  maxLength: {
                    value: 128,
                    message: t(
                      'You have reached your maximum limit of allowed characters',
                    ),
                  },
                  // pattern: {
                  //   value:
                  //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s])[A-Za-z\d\S ]+$/,
                  //   message: t(
                  //     'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character',
                  //   ),
                  // },
                }}
                render={({ field }) => (
                  <>
                    <TextField
                      {...field}
                      error={!!errors.new_password}
                      type={type1}
                      onBlur={() => {
                        const trimmedValue = field.value.trim()
                        field.onChange(trimmedValue) // Update the field value
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{ cursor: 'pointer' }}
                            onClick={handleToggle1}
                          >
                            {type1 === 'password' ? <EyeOpen /> : <EyeClosed />}
                          </InputAdornment>
                        ),
                      }}
                      id="outlined-basic"
                      variant="outlined"
                      className="input-fields"
                      helperText={
                        errors.new_password ? errors.new_password.message : ''
                      }
                    />
                    <PasswordStrengthMeter password={field.value} />
                  </>
                )}
              />
            </Stack>

            <Stack
              direction="column"
              justifyContent="start"
              alignItems="start"
              className="input-field-wrapper"
            >
              <h6 className="heading">{t('Confirm Password')} </h6>

              <Controller
                name="confirm_password"
                control={control}
                defaultValue=""
                rules={{
                  // required: t('Confirm password is mandatory'),
                  validate: (value) => {
                    const newPasswordValue = getValues('new_password').trim()
                    const confirmPasswordValue = value.trim()
                    return (
                      newPasswordValue === confirmPasswordValue ||
                      t('Passwords do not match')
                    )
                  },
                }}
                render={({ field }) => (
                  <>
                    <TextField
                      {...field}
                      error={!!errors.confirm_password}
                      id="outlined-basic"
                      type={type2}
                      onBlur={() => {
                        const trimmedValue = field.value.trim()
                        field.onChange(trimmedValue) // Update the field value
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{ cursor: 'pointer' }}
                            onClick={handleToggle2}
                          >
                            {type2 === 'password' ? <EyeOpen /> : <EyeClosed />}
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      className="input-fields"
                      helperText={
                        errors.confirm_password
                          ? errors.confirm_password.message
                          : ''
                      }
                    />
                  </>
                )}
              />
            </Stack>

            <TextButton
              onClick={handleSubmit(onSubmitResetPasswordForm)}
              text={t('Reset Password')}
              color={'#fff'}
              style={{
                width: '100%',
                fontSize: '14px',
                textTransform: 'capitalize',
                boxShadow: 'none',
                padding: '8px 0',
              }}
              bgColor={
                !isValid || apiStatus
                  ? 'var(--primary-300)' // Use primary-300 for the disable color
                  : 'var(--primary-600)'
              }
              disabled={!isValid || apiStatus}
            ></TextButton>
          </Stack>
        </form>
        <div className="recaptcha-usage">
          {t('Protected by reCAPTCHA and subject to the Google')}{' '}
          <a href="https://www.google.com/intl/en/policies/privacy/">
            {t('Privacy Policy')}
          </a>{' '}
          {t('and')} &nbsp;
          <a href="https://www.google.com/intl/en/policies/terms/">
            {t('Terms of Service')}
          </a>
          .
        </div>
      </Stack>
    </div>
  )
}

export default ResetPasswordCode

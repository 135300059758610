// import { type GridRowsProp, type GridColDef } from '@mui/x-data-grid'
import { ReactComponent as DownloadIcon } from '../../assets/images/download_minimalistic.svg'
import { ReactComponent as ActiveDownloadIcon } from '../../assets/images/active_download_minimalistic.svg'
import { ReactComponent as Settings } from '../../assets/images/settings.svg'
import { ReactComponent as ActiveSettings } from '../../assets/images/active_settings.svg'

import { ReactComponent as ResultIcon } from '../../assets/images/download.svg'
import { ReactComponent as ActiveResultIcon } from '../../assets/images/active_download.svg'
import { ReactComponent as OpenIcon } from '../../assets/images/clipboard_check.svg'
import { ReactComponent as ActiveOpenIcon } from '../../assets/images/active_clipboard_check.svg'
import { ReactComponent as UploadIcon } from '../../assets/images/light_upload.svg'
import { ReactComponent as MagniferIcon } from '../../assets/images/magnifer.svg'
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  Typography,
  Button,
  InputLabel,
  Stack,
  IconButton,
  InputAdornment,
  CircularProgress,
  TablePagination,
  Badge,
  Tooltip,
  FormHelperText,
} from '@mui/material'

import type { SelectChangeEvent } from '@mui/material/Select'
import { useContext, useEffect, useRef, useState, useTransition } from 'react'

import FormDialog from '../../components/form-dialog/formDialog'
import { SingleUpload } from '../../components/file-upload/singleUpload'
import InputBox from '../../components/input/input'
import { enqueueSnackbar } from 'notistack'
import TableComponent from '../../components/table/table'
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_PaginationState,
  type MRT_SortingState,
} from 'material-react-table'
import { BorderAllRounded } from '@mui/icons-material'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { GridSearchIcon } from '@mui/x-data-grid'
import {
  archiveAPI,
  createProjectAPI,
  deleteProject,
  getProjects,
} from '../home/home-api-services'
import '../home/home.scss'
import SimpleDateTime from 'react-simple-timestamp-to-date'
import RefreshIcon from '../../assets/images/refresh.svg'
import ActiveRefreshIcon from '../../assets/images/active_refresh.svg'
import ButtonIcon from '../../components/button/iconButton'
import TextButton from '../../components/button/textButton'
import ByteConverter from '../../components/file-upload/byteConverter'
import { upload } from '@testing-library/user-event/dist/upload'
import OutlineTextButton from '../../components/button/textOutlineButton'
import CancelConformationFormDialog from '../../components/form-dialog/cancel-conformation'
import CancelDeleteConformationFormDialog from '../../components/form-dialog/cancel-delete-conformation'
import DateFormatter from '../../utils/dateFormatter'
import LightTooltip from '../../components/tooltip/tooltip'
import { ReactComponent as XIcon } from '../../assets/images/x.svg'
import TruncateText from '../../components/tooltip/truncate'
import { downloadAPI } from '../results/results-api-service'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { getLocalizedPath } from '../../utils/shareLocalizePath'
import axios from 'axios'
import ProjectNameWithTooltip from '../../components/tooltip/dynamicTooltip'
import { StatusContext, UserContext } from '../../context/globalContext'

interface Props {}
interface RowData {
  id: any
  name: any
  created_at: Date
  source_document: string
  target_document_count: number
  accepted_collations_count: number
  managers: any
  last_modified_at: string
  deleted_by: string
  deleted_at: Date
  status: string
}

function Home(props: Props): JSX.Element {
  const navigate = useNavigate()
  const location = useLocation()
  const inputRef = useRef<any>(null)
  const controllerRef = useRef<AbortController | null>(null)

  const [isMounted, setIsMounted] = useState<boolean | any>(false)
  // Home page filters
  const [searchTerm, setNewSearchTerm] = useState('')
  const [completeSearchTerm, setCompleteSearchTerm] = useState('')

  const [filter, setFilter] = useState<string>('active')

  // File upload data store state
  const [isCreateProjectOpen, setIsCreateProjectOpen] = useState<boolean | any>(
    false,
  )
  const [projectName, setProjectName] = useState<string>('')
  const [file, setFile] = useState<File | null>(null)
  const [uploadProgress, setUploadProgress] = useState<any>(null)
  const [isProgressComplete, setIsProgressComplete] = useState<boolean>(false)
  const [isFileSizeValid, setFileSizeValidation] = useState<boolean>(true)

  // Home page data state
  const [isHomeDataLoading, setHomeDataLoading] = useState<boolean | any>(true)
  const [projects, setProjects] = useState<any[]>([])
  const [filteredProjects, setFilteredProjects] = useState<any[]>([])
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState(() => {
    const savedRowsPerPage = localStorage.getItem('homeRowsPerPage')

    return savedRowsPerPage ? parseInt(savedRowsPerPage, 10) : 10
  })
  const [rowCount, setRowCount] = useState<any>(1)
  const [sorting, setSorting] = useState<MRT_SortingState>([])

  const [disableDisableDownloadCSV, setDisableDownloadCSV] = useState<
    boolean | any
  >(false)

  // Cancel upload
  const [isCancelUploadConfirmDialogOpen, setCancelUploadConfirmDialogOpen] =
    useState(false)
  const [cancelResult, setCancelResult] = useState<string>()
  const [currentSortVal, setCurretSortVal] = useState<string>()
  const [isCancelArchiveConfirmDialogOpen, setCancelArchiveConfirmDialogOpen] =
    useState(false)
  const [isCancelDeleteConfirmDialogOpen, setCancelDeleteConfirmDialogOpen] =
    useState(false)
  const [isDeleteConfirmDialogOpen, setDeleteDialogOpen] = useState(false)
  const [currentProjectId, setCurrentProjectId] = useState<any>(0)
  const [currentProjectName, setCurrentProjectName] = useState<any>('')
  const disableCreateButton =
    !projectName || !file || uploadProgress !== null || !isFileSizeValid
  const rows: RowData[] = []
  const maxFileSize = 30 * 1024 * 1024
  const { setProjectStatus } = useContext<any>(StatusContext)
  const { userRole } = useContext<any>(UserContext)
  const { t } = useTranslation()
  // Use Effect
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const filterVal = searchParams.get('filter')

    if (filterVal === 'archived') {
      setFilter('archived')
    } else if (filterVal === 'deleted') {
      setFilter('deleted')
    } else {
      setFilter('active')
    }
    const savedRowsPerPage = localStorage.getItem('homeRowsPerPage')
    if (savedRowsPerPage) {
      setRowsPerPage(parseInt(savedRowsPerPage, 10))
    }
  }, [location])

  useEffect(() => {
    setIsMounted(true) // Set isMounted to true when component is mounted

    return () => {
      setIsMounted(false) // Set isMounted to false when component is unmounted
      if (controllerRef.current) {
        controllerRef.current.abort()
      }
    }
  }, [])
  useEffect(() => {
    getSortValue()
  }, [sorting])

  useEffect(() => {
    if (isMounted) {
      getProjectsData()
      setProjectStatus('')
    }
  }, [isMounted, currentSortVal, filter, page, rowsPerPage, completeSearchTerm])

  useEffect(() => {
    const savedRowsPerPage = localStorage.getItem('homeRowsPerPage')
    if (savedRowsPerPage) {
      setRowsPerPage(parseInt(savedRowsPerPage, 10))
    }
  }, [filter])
  // To handle cancelation of file upload.
  useEffect(() => {
    if (cancelResult === 'confirm') {
      handleFileUploadCancel()
    } else {
      setCancelUploadConfirmDialogOpen(false)
    }
  }, [cancelResult])
  useEffect(() => {
    localStorage.setItem('homeRowsPerPage', rowsPerPage.toString())
  }, [rowsPerPage])

  const formatDateToLocalTime = (utcDateString) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const date = moment(utcDateString)
    const formattedDate = date.format('YYYY/MM/DD')
    const formattedTime = date.format('HH:mm')
    return `${formattedDate} ${formattedTime}`
  }

  const [isHovered, setIsHovered] = useState(false)

  // Columns for home page
  const columns: Array<MRT_ColumnDef<RowData>> = [
    {
      accessorKey: 'name',
      minSize: 110,
      accessorFn: (row) => (
        <div
          style={{
            width: 'inherit',
          }}
        >
          {filter === 'active' ? (
            <Link
              to={getLocalizedPath(
                currentLanguage,
                `/projects?project_id=${row.id}`,
              )}
            >
              <ProjectNameWithTooltip row={row} />
            </Link>
          ) : (
            <div
              className="project-name-grey"
              onClick={() => {
                localStorage.setItem(
                  `project_name_with_id_${row.id}`,
                  JSON.stringify(row.name),
                )
              }}
            >
              {row?.name}
            </div>
          )}

          <span className="created-at">
            <DateFormatter dateString={formatDateToLocalTime(row.created_at)} />
          </span>
        </div>
      ),
      id: 'name',
      header: t('PROJECT NAME'),
      enableSorting: false,
    },

    {
      accessorKey: 'source_document',
      header: t('SOURCE DOCUMENT'),
      enableSorting: false,
      enableResizing: false,
      accessorFn: (row) => (
        <div
          className="source-doc"
          style={{
            width: '200px',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {row.source_document}
          {/* {row.source_document?.length > 30 ? (
            <TruncateText text={row.source_document} maxLength={30} />
          ) : (
            row.source_document
          )} */}
        </div>
      ),
    },
    {
      accessorKey: 'target_document_count',
      header: t('TARGET DOCS'),
      size: 95,
      enableSorting: false,
      enableResizing: false,
    },
    {
      accessorKey: 'accepted_collations_count',
      header: t('COLLATIONS'),
      size: 90,
      enableSorting: false,
      enableResizing: false,
    },
    {
      accessorKey: 'managers',
      header: t('PROJECT MANAGERS'),
      size: 125,
      enableSorting: false,
      enableResizing: false,
      accessorFn: (row) => (
        <div className="managers">
          <div>
            {row.managers?.length > 0 ? (
              <span>{row.managers[0].name}</span>
            ) : (
              <span>-</span>
            )}
          </div>

          {row?.managers?.length > 1 && (
            <div>
              <LightTooltip
                title={row.managers
                  .slice(1)
                  .map((manager) => manager.name)
                  .join(', ')}
                placement="top-start"
              >
                <Badge
                  badgeContent={'+' + (row.managers?.length - 1)}
                  color="primary"
                  sx={{ margin: '0 0 2px 0' }}
                ></Badge>
              </LightTooltip>
            </div>
          )}
        </div>
      ), // Use an accessor function to return the formatted string
    },

    {
      accessorKey: 'last_modified_at',
      accessorFn: (row) => (
        <div className="source-doc">
          <DateFormatter
            dateString={formatDateToLocalTime(row?.last_modified_at)}
          />
        </div>
      ), // Use an accessor function to return the formatted string
      header: t('LAST MODIFIED'),
      maxSize: 120,
      enableSorting: true,
      enableResizing: false,
    },

    {
      accessorKey: 'status',
      header: t('STATUS'),
      enableSorting: false,
      maxSize: 150,
      enableResizing: false,
    },
  ]
  //  Add Actions column only for active and Archived projects
  if (filter === 'active' || filter === 'archived') {
    columns.push({
      accessorKey: 'actions',
      enableSorting: false,
      maxSize: 170,
      enableResizing: false,
      accessorFn: (row) => (
        <div>
          {filter === 'active' ? (
            <ActionIcons
              row={row}
              filter={filter}
              t={t}
              currentLanguage={currentLanguage}
              downloadCSV={downloadCSV}
              gotoProjectSettings={gotoProjectSettings}
              userRole={userRole}
            />
          ) : filter === 'archived' ? (
            <div className="archived-project-btn">
              <div className="archived-project-btn">
                <OutlineTextButton
                  text={t('Delete')}
                  bgColor={'#fff'}
                  color={'var(--primary-600)'}
                  outlineColor={'var(--primary-600)'}
                  // bgColor={
                  //   disableInviteButton
                  //     ? 'var(--primary-300)' // Use primary-300 for the disable color
                  //     : 'var(--primary-600)'
                  // }
                  // disabled={disableInviteButton}
                  onClick={() => {
                    handleOpenDeleteProjectMessageDialog(row.id, row.name)
                  }}
                  style={{
                    width: '71px',
                    height: '34px',
                    fontSize: '12px',
                    fontWeight: '500',
                    lineHeight: '18px',
                  }}
                />

                <TextButton
                  text={t('Unarchive')}
                  color={'#fff'}
                  bgColor={'var(--primary-600)'}
                  disabled={false}
                  onClick={() => {
                    handleOpenArchiveConfirmDialog(row.id, row.name)
                  }}
                  style={{
                    width: '76px',
                    height: '34px',
                    fontSize: '12px',
                    fontWeight: '500',
                    lineHeight: '18px',
                  }}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      ), // Use an accessor function to return the formatted string
      header: t('ACTIONS'),
    })
  } else if (filter === 'deleted') {
    // Remove "Actions" column
    const actionsIndex = columns.findIndex(
      (column) => column.accessorKey === 'actions',
    )
    if (actionsIndex !== -1) {
      columns.splice(actionsIndex, 1)
    }

    // Add "Deleted By" column
    columns.push({
      accessorKey: 'deleted_by',
      header: t('DELETED BY'),
      maxSize: 130,
      accessorFn: (row) => (
        <div>
          <div className="project-name" style={{ color: 'var(--grey-600)' }}>
            {row?.deleted_by}
          </div>
          <span className="created-at">
            <DateFormatter
              dateString={formatDateToLocalTime(row?.deleted_at)}
            />
          </span>
        </div>
      ),
    })
  }

  //  Handle Search
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewSearchTerm(event.target.value)
  }

  const handleSearch = () => {
    setPage(0)

    setCompleteSearchTerm(searchTerm)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }
  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    navigate(
      `${getLocalizedPath(currentLanguage, `/home?filter=${event.target.value}`)}`,
      {
        replace: true,
      },
    )
    setNewSearchTerm('')
    setCompleteSearchTerm('')
    setFilter(event.target.value)
  }

  //  To set Create project dialog to open and colse
  const handleOpenCreateProject = () => {
    setIsCreateProjectOpen(true)
  }
  const handleCloseCreateProject = () => {
    setProjectName('')
    setProjects([])
    setFileSizeValidation(true)
    setIsCreateProjectOpen(false)
    setUploadProgress(null)
    getProjectsData()
  }
  const handleProjectNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setProjectName(event.target.value.trimStart())
  }

  const getSortValue = () => {
    if (sorting?.length > 0) {
      setCurretSortVal(`${sorting[0]?.desc ? '-' : ''}${sorting[0]?.id}`)
    } else {
      setCurretSortVal(`-last_modified_at`)
    }
  }
  // function to call getProjects API to get the projects data
  const getProjectsData = () => {
    setHomeDataLoading(true)
    const params = {
      page_size: rowsPerPage,
      page: page + 1,
      type: filter,
      search: completeSearchTerm,
      sort: currentSortVal,
    }

    getProjects(params)
      .then((res) => {
        if (res) {
          if (!currentSortVal?.includes('-')) {
            setProjects([...res.data.reverse()])
          } else {
            setProjects([...res.data])
          }

          setHomeDataLoading(false)
          setFilteredProjects([...res.data])
          setRowCount(res.total_count)
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          setHomeDataLoading(false)
          enqueueSnackbar(`${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          setHomeDataLoading(false)
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }

  const refresh = () => {
    getProjectsData()
  }

  // To set file and project name through props & close dialog
  const createProject = (e: any) => {
    setIsProgressComplete(false)
    // Set up AbortController
    const controller = new AbortController()
    controllerRef.current = controller
    var productFormData = new FormData()

    if (file) {
      const onUploadProgress = (progressEvent: any) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        )
        setUploadProgress(
          (prevProgress: { filename: string; progress: number }) => ({
            filename: file.name,
            progress: Math.max(progress, prevProgress?.progress ?? 0),
          }),
        )
      }

      productFormData.append('name', projectName)
      productFormData.append('source_document', file)

      createProjectAPI(productFormData, onUploadProgress, controller.signal)
        .then((res) => {
          if (res.status === 201) {
            setProjectName('')
            setFile(null)
            setUploadProgress(null)
            setIsProgressComplete(true)
            handleCloseCreateProject()
            enqueueSnackbar(t(`Project ${projectName} created successfully`), {
              variant: 'success',
              persist: true,
            })
            setCancelUploadConfirmDialogOpen(false)
            getProjectsData()
          }
        })
        .catch((error) => {
          setFile(null)
          setUploadProgress(null)
          setProjectName('')
          setFileSizeValidation(true)
          if (
            error?.response?.data?.detail &&
            error?.response?.request?.status !== 500 &&
            error?.response?.request?.status !== 502 &&
            error?.response?.request?.status !== 413
          ) {
            if (error?.response?.data?.detail)
              enqueueSnackbar(`${error?.response?.data?.detail}`, {
                variant: 'error',
                persist: true,
              })
          }
          if (error?.response?.request?.status === 502) {
            enqueueSnackbar(`Something went wrong`, {
              variant: 'error',
              persist: true,
            })
          } else if (error?.response?.request?.status === 413) {
            enqueueSnackbar(
              `${error?.response?.data?.detail ? error?.response?.data?.detail : 'Please check if the file size is as per specification'}`,
              {
                variant: 'error',
                persist: true,
              },
            )
          }
        })
    }
  }

  const downloadCSV = (projectId) => {
    setDisableDownloadCSV(true)
    downloadAPI(projectId)
      .then((res) => {
        if (res.status === 200) {
          setDisableDownloadCSV(false)
          const blob = new Blob([res.data], { type: 'text/csv' })
          const contentDispositionHeader: string | undefined =
            res.headers?.['content-disposition']
          let filename = 'collation_results.csv'
          if (contentDispositionHeader) {
            const index = contentDispositionHeader.indexOf('filename=')
            if (index !== -1) {
              filename = contentDispositionHeader
                .substring(index + 9)
                .replace(/['"]/g, '')
            }
          }
          const a = document.createElement('a')
          a.href = window.URL.createObjectURL(blob)
          a.download = filename
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileSizeValidation(true)
    const selectedFile = e.target.files ? e.target.files[0] : null
    if (selectedFile) {
      setFile(selectedFile)
      if (selectedFile != null && selectedFile.size < maxFileSize) {
        setFileSizeValidation(true)
      } else {
        setFileSizeValidation(false)
      }
    }
  }

  // To set cancel conformation dialog box
  const handleOpenCancelUploadConfirmDialog = () => {
    setFile(file)
    setCancelUploadConfirmDialogOpen(true)
  }
  const handlCloseCancelUploadConfirmDialog = (result: string) => {
    setCancelResult(result) // Set the result received from the dialog
    setCancelUploadConfirmDialogOpen(false)
    getProjectsData()
  }

  const handleFileUploadCancel = () => {
    if (controllerRef.current) {
      controllerRef.current.abort()
      setCancelUploadConfirmDialogOpen(false)
      enqueueSnackbar(
        t(
          `Project creation cancelation is requested.  In case the project is created, please delete it from the project settings page.`,
        ),
        {
          variant: 'error',
          persist: true,
        },
      )
      setFile(null)
      setProjectName('')
      setUploadProgress(null)
      setCancelResult('')
    }
    handleCloseCreateProject()
    setIsCreateProjectOpen(false)
  }

  const gotoProjectSettings = (id: any) => {
    navigate(
      `${getLocalizedPath(currentLanguage, `/project-setting?projectId=${id}`)}`,
    )
  }

  //  To handle pagination
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const deleteProjectById = (id: any) => {
    deleteProject(id)
      .then((res) => {
        enqueueSnackbar(`${res.detail}`, {
          variant: 'success',
          persist: true,
        })
        getProjectsData()
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }

  const archiveOrUnarchiveProjects = (id: any, val: boolean) => {
    const params = {
      archive: val,
    }
    archiveAPI(id, params)
      .then((res) => {
        getProjectsData()
        enqueueSnackbar(`${res?.detail}`, {
          variant: 'success',
          persist: true,
        })
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }

  // Open  archive  dialog
  const handleOpenArchiveConfirmDialog = (id: any, name: any) => {
    setCurrentProjectName(name)
    setCurrentProjectId(id)
    setCancelArchiveConfirmDialogOpen(true)
  }
  const handlCloseArchiveConfirmDialog = (result?: string) => {
    if (result === 'confirm') {
      archiveOrUnarchiveProjects(currentProjectId, false)
    }
    setCancelArchiveConfirmDialogOpen(false)
  }
  // Open Delete project confirm dialog
  const handleOpenDeleteProjectMessageDialog = (id: any, name: any) => {
    setCurrentProjectName(name)
    setCurrentProjectId(id)
    setCancelDeleteConfirmDialogOpen(true)
  }

  // On close of Delete project; On confirm
  const handlCloseDeleteMessageDialog = (result?: string) => {
    if (result === 'confirm') {
      setDeleteDialogOpen(true)
      setCancelDeleteConfirmDialogOpen(false)
    } else {
      setCancelDeleteConfirmDialogOpen(false)
    }
  }

  const handleCloseDeleteConfirmDialog = (result?: string) => {
    if (result === 'delete') {
      deleteProjectById(currentProjectId)
    }
    setDeleteDialogOpen(false)
  }
  const dialogUnarchiveMessage = t('Are you sure, you want to Unarchive')
  const dialogDeleteMessage = t('Are you sure, you want to Delete')
  return (
    <div className="global-page-wrapper">
      <Stack className="content-wrapper">
        <div className="filters-container">
          <div className="filters-contents">
            {/* Search Bar start here */}
            <div className="search-bar">
              <TextField
                id="search-projects"
                placeholder={t('Search Projects')}
                variant="standard"
                size="small"
                value={searchTerm}
                onChange={handleSearchChange}
                onKeyDown={handleKeyPress}
                sx={{
                  flex: 1,
                  border: '1px solid var(--gray-200) !important',
                  '& .MuiInput-root::before': {
                    border: 'none !important',
                  },
                  '& .MuiInput-root::after': {
                    border: 'none !important',
                  },
                  borderTopLeftRadius: '8px',
                  borderBottomLeftRadius: '8px',
                  borderTopRightRadius: '0px',
                  borderBottomRightRadius: '0px',
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MagniferIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchTerm?.length > 0 && (
                        <XIcon
                          className="global-close-btn"
                          onClick={() => {
                            setCompleteSearchTerm('')
                            setNewSearchTerm('')
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <TextButton
                text={t('Search')}
                color={'var(--gray-white)'}
                bgColor={
                  searchTerm ? 'var(--primary-600)' : 'var(--primary-300)'
                }
                disabled={!searchTerm}
                onClick={handleSearch}
                style={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  // To create the effect of the button being attached to the search bar
                }}
              />
            </div>
            {/* Search Bar End here */}
            {/* Filter start here */}

            <FormControl size="small" style={{ minWidth: 165 }}>
              <Select
                id="filter-select"
                value={filter}
                onChange={handleFilterChange}
                style={{
                  borderRadius: '8px',
                  fontSize: '14px',
                  padding: '1.5px 0',
                }}
                disabled={isHomeDataLoading}
              >
                <MenuItem style={{ fontSize: '14px' }} value="active" selected>
                  {t('Active Projects')}
                </MenuItem>
                <MenuItem style={{ fontSize: '14px' }} value="archived">
                  {t('Archived Projects')}
                </MenuItem>
                <MenuItem style={{ fontSize: '14px' }} value="deleted">
                  {t('Deleted Projects')}
                </MenuItem>
              </Select>
            </FormControl>
            {/* Filter start here */}
          </div>

          <div className="refresh-action-container">
            <span className="icon-wrapper">
              <img
                className="default-icon"
                src={RefreshIcon}
                alt="Edit"
                onClick={refresh}
              />
              <img
                className="active-icon"
                src={ActiveRefreshIcon}
                alt="Edit"
                onClick={refresh}
              />
            </span>
            {/* Invite button */}
            {filter === 'active' &&
              (userRole === 'admin' || userRole === 'manager') && (
                <ButtonIcon
                  text={t('New Project')}
                  color={'var(--gray-white)'}
                  IconComponent={UploadIcon}
                  bgColor={'var(--primary-600)'}
                  onClick={handleOpenCreateProject}
                />
              )}
          </div>
        </div>

        {/* Projects table component */}

        {isHomeDataLoading ? (
          <div style={{ padding: '15rem' }}>
            <CircularProgress />
          </div>
        ) : (
          <TableComponent
            rows={projects}
            columns={columns}
            enableBottomTool={true}
            errorMessage={t('No projects found')}
            sorting={sorting}
            setSorting={setSorting}
            enableColumnPinning={false}
            enableResize={true}
            isHomeDataLoading={isHomeDataLoading}
          />
        )}

        {projects?.length > 0 && !isHomeDataLoading && (
          <TablePagination
            component="div"
            page={page}
            count={rowCount}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 20, 30]}
            labelRowsPerPage={t('Items per page:')}
          />
        )}

        {/* Dialog Start here */}
        <FormDialog
          open={isCreateProjectOpen}
          title={t('Create new project')}
          onClose={() => {
            uploadProgress <= 0
              ? handleCloseCreateProject()
              : handleOpenCancelUploadConfirmDialog()
          }}
          setProjectName={setProjectName}
          setFile={setFile}
          setUploadProgress={setUploadProgress}
        >
          <Stack display={'flex'} direction={'column'} gap={2}>
            {/* Dialog ProjectName input field */}
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="name-input">
                {t('Project Name')} {isProgressComplete}
              </InputLabel>
              <InputBox
                id="name-input"
                placeholder={t('Enter Project Name')}
                value={projectName}
                onChange={handleProjectNameChange}
                disabled={uploadProgress && !isProgressComplete}
                error={projectName?.length > 255}
              />
              {projectName?.length > 255 && ( // Display error message if not valid
                <FormHelperText error>
                  {t('Project name must be 255 characters or less.')}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl variant="standard">
              {/* Dialog Upload Part using SingleUpload component */}
              <SingleUpload
                setFile={setFile}
                file={file}
                placeholder={t('Add a Source Document')}
                documentTitle={t('Source Document')}
                handleFileChange={handleFileChange}
                uploadProgress={uploadProgress}
                isProgressComplete={isProgressComplete}
                buttonName={t('Choose file')}
                color={'var(--gray-white)'}
                bgColor={
                  uploadProgress && !isProgressComplete
                    ? 'var(--primary-300)'
                    : 'var(--primary-600)'
                }
                style={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderTopRightRadius: '8px',
                  borderBottomRightRadius: '8px',
                  marginTop: '25px',
                  paddingTop: '8px',
                  paddingBottom: '8px',
                  minWidth: 'fit-content',
                }}
              />
              <div className="file-size-error">
                {!isFileSizeValid && <>{t('File exceeds maximum limit')}</>}
              </div>

              <div className="file-size-error">
                {file?.size === 0 && (
                  <>{t('File is empty, please choose appropriate file')}</>
                )}
              </div>
              <div className="selected-file-size">
                {file && uploadProgress >= 0 && (
                  <ByteConverter bytes={file?.size} decimal={2} />
                )}
              </div>
            </FormControl>
          </Stack>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '16px',
            }}
          >
            {/* Dialog Craete Button */}

            {uploadProgress <= 0 ? (
              <TextButton
                text={t(`Create`)}
                color={'#fff'}
                bgColor={
                  disableCreateButton ||
                  projectName?.length > 255 ||
                  file?.size === 0
                    ? 'var(--primary-300)' // Use primary-300 for the disable color
                    : 'var(--primary-600)'
                }
                disabled={
                  disableCreateButton ||
                  projectName?.length > 255 ||
                  file?.size === 0
                }
                onClick={createProject}
                style={{
                  width: '100%',
                  fontSize: '14px',
                  textTransform: 'capitalize',
                  boxShadow: 'none',
                  padding: '8px 0',
                }}
              />
            ) : (
              <OutlineTextButton
                text={t('Cancel')}
                bgColor={'#fff'}
                color={'var(--primary-600)'}
                outlineColor={'var(--primary-600)'}
                // bgColor={
                //   disableInviteButton
                //     ? 'var(--primary-300)' // Use primary-300 for the disable color
                //     : 'var(--primary-600)'
                // }
                // disabled={disableInviteButton}
                // onClick={onInviteClick}
                style={{
                  width: '100%',
                  height: '42px',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
                onClick={handleOpenCancelUploadConfirmDialog}
              />
            )}
          </div>
        </FormDialog>
        {/* Dialog End here */}
        {/*  On cancel of upload file */}
        <CancelConformationFormDialog
          open={isCancelUploadConfirmDialogOpen}
          cancelbtnText={'No'}
          onClose={handlCloseCancelUploadConfirmDialog}
          message={t('Your progress will be lost, Are you sure?')}
        ></CancelConformationFormDialog>

        <CancelConformationFormDialog
          open={isCancelArchiveConfirmDialogOpen}
          onClose={handlCloseArchiveConfirmDialog}
          message={`${t('Are you sure, you want to Unarchive this project?')} ${currentProjectName}`}
        />
        <CancelConformationFormDialog
          open={isCancelDeleteConfirmDialogOpen}
          onClose={handlCloseDeleteMessageDialog}
          message={`${t('Are you sure, you want to Delete this project?')} ${currentProjectName}`}
        />
        {/*  */}
        <CancelDeleteConformationFormDialog
          open={isDeleteConfirmDialogOpen}
          onClose={handleCloseDeleteConfirmDialog}
          message={t(
            'To avoid data loss, consider using the archive project feature instead. Once you Delete a project, it cannot be undone or recovered',
          )}
        ></CancelDeleteConformationFormDialog>
      </Stack>
    </div>
  )
}

export default Home

export const ActionIcons = ({
  row,
  filter,
  t,
  currentLanguage,
  downloadCSV,
  gotoProjectSettings,
  userRole,
}: any) => {
  const [hoveredIcon, setHoveredIcon] = useState<string | any>('')

  const handleMouseEnter = (icon) => {
    setHoveredIcon(icon)
  }

  const handleMouseLeave = (icon) => {
    setTimeout(() => {
      if (hoveredIcon === icon) {
        setHoveredIcon('')
      }
    }, 100)
  }

  return (
    <div className="actions">
      <Link
        to={getLocalizedPath(currentLanguage, `/projects?project_id=${row.id}`)}
      >
        <Tooltip title={t('Go to collations')} placement="top">
          <IconButton
            className="icons"
            onClick={() => {
              localStorage.setItem(
                `project_name_with_id_${row.id}`,
                JSON.stringify(row.name),
              )
            }}
          >
            <span className="icon-wrapper">
              <ResultIcon className="default-icon" />
              <ActiveResultIcon className="active-icon" />
            </span>
          </IconButton>
        </Tooltip>
      </Link>

      <Link
        to={getLocalizedPath(currentLanguage, `/result?project_id=${row.id}`)}
      >
        <Tooltip title={t('Go to result ')} placement="top">
          <IconButton className="icons">
            <span className="icon-wrapper">
              <OpenIcon className="default-icon" />
              <ActiveOpenIcon className="active-icon" />
            </span>
          </IconButton>
        </Tooltip>
      </Link>

      <Tooltip title={t('Download')} placement="top">
        <IconButton className="icons" onClick={() => downloadCSV(row.id)}>
          <span className="icon-wrapper">
            <DownloadIcon className="default-icon" />
            <ActiveDownloadIcon className="active-icon" />
          </span>
        </IconButton>
      </Tooltip>
      {(userRole === 'admin' || userRole === 'manager') && (
        <Tooltip title={t('Go to project-settings')} placement="top">
          <IconButton
            className="icons"
            onClick={() => {
              gotoProjectSettings(row.id)
              localStorage.setItem(
                `project_name_with_id_${row.id}`,
                JSON.stringify(row.name),
              )
            }}
          >
            <span className="icon-wrapper">
              <Settings className="default-icon" />
              <ActiveSettings className="active-icon" />
            </span>
          </IconButton>
        </Tooltip>
      )}
    </div>
  )
}

/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useEffect, useState } from 'react'
import '../../styles/variables.scss'
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Tab,
  TablePagination,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import '../project-setting/project-setting.scss'
import OutlineTextButton from '../../components/button/textOutlineButton'
import TextButton from '../../components/button/textButton'
import { ReactComponent as MagniferIcon } from '../../assets/images/magnifer.svg'
import TableComponent from '../../components/table/table'
import type { MRT_ColumnDef } from 'material-react-table'
import type { SelectChangeEvent } from '@mui/material/Select'
import ButtonIcon from '../../components/button/iconButton'
import { ReactComponent as AddIcon } from '../../assets/images/add.svg'
import { TargetDocumentTab } from './target-document'
import AddMembersDialog, { ActionDialog } from './addMembers'
import CancelConformationFormDialog from '../../components/form-dialog/cancel-conformation'
import { useTranslation } from 'react-i18next'
import FormDialog from '../../components/form-dialog/formDialog'

import { ReactComponent as CheckedIcon } from '../../assets/images/filled_checkbox.svg'
import { ReactComponent as DefaultCheckIcon } from '../../assets/images/checkbox.svg'
import { enqueueSnackbar } from 'notistack'
import {
  assignUsersToProject,
  getMembersList,
  removeUserToProject,
} from '../../services/user-management-service'
import axios from 'axios'
import moment from 'moment'
interface Props {
  projectId
}

interface RowDataMembers {
  id: any
  name: string
  email: string
  role: any
  access_granted_at: string
}

export const MembersTabs: React.FC<Props> = ({ projectId }) => {
  const [activeMembers, setActiveMembers] = useState<RowDataMembers[]>([])
  const [nonActiveMembers, setNonActiveMembers] = useState<RowDataMembers[]>([])
  const [searchedMember, setSearchedMember] = useState<RowDataMembers[]>([])
  const [searchTerm, setSearchTerm] = useState<any>('')
  const [searchMemberTerm, setSearchMemberTerm] = useState<any>('')
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [rowCount, setRowCount] = useState<any>(1)
  const [selectedMembers, setSelectedMembers] = useState<any>([])
  const [isNonActiveMembersLoading, setIsNonActiveMembersLoading] =
    useState(false)
  const [isActiveMembersLoading, setIsActiveMembersLoading] = useState(false)
  const [isAddMembersOpen, setIsAddMembersOpen] = useState(false)
  const [disableAddBtn, setDisableAddBtn] = useState(false)
  const [isMounted, setIsMounted] = useState<boolean>(false)
  const [confirmationDialogState, setConfirmationDialogState] = useState<any>({
    open: false,
    value: null,
  })

  const { t } = useTranslation()
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleOpenAddMemeber = () => {
    setIsNonActiveMembersLoading(true)
    setIsAddMembersOpen(true)
    handleGetMembersList('non-members')
  }
  const handleCloseAddMemeber = () => {
    setIsAddMembersOpen(false)
    setIsNonActiveMembersLoading(false)
    setSelectedMembers([])
    setSearchMemberTerm('')
    setSearchedMember([])
  }

  const handleOpenRemoveMemberConfirmDialog = (row) => {
    setConfirmationDialogState({ open: true, value: row })
  }
  const handleCloseRemoveMemberConfirmDialog = (result: string) => {
    if (result === 'confirm') {
      handleRemoveMember(confirmationDialogState?.value?.id)
    }
    setConfirmationDialogState({ open: false, value: null })
  }

  useEffect(() => {
    selectedMembers.length > 0
      ? setDisableAddBtn(false)
      : setDisableAddBtn(true)
  }, [selectedMembers])
  useEffect(() => {
    setIsMounted(true) // Set isMounted to true when component is mounted

    return () => {
      setIsMounted(false) // Set isMounted to false when component is unmounted
    }
  }, [])

  useEffect(() => {
    if (isMounted) {
      handleGetMembersList('active-members')
    }
  }, [isMounted, page, rowsPerPage])
  const formatDateToLocalTime = (utcDateString) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const date = moment(utcDateString)
    const formattedDate = date.format('YYYY/MM/DD')
    const formattedTime = date.format('HH:mm')
    return `${formattedDate} ${formattedTime}`
  }
  const columnsMembers: Array<MRT_ColumnDef<RowDataMembers>> = [
    {
      accessorFn: (row) => (
        <div>
          <div className="person-name">{row.name}</div>

          <span className="person-email">{row.email}</span>
        </div>
      ), // Use an accessor function to return the formatted string
      accessorKey: 'Name', // Unique ID for the column
      header: t('NAME'),
      maxSize: 120,
    },
    {
      accessorKey: 'role',
      header: t('ROLE'),
      maxSize: 120,
      accessorFn: (row) => <div>{row.role.name}</div>,
      // grow: true,
    },
    {
      accessorKey: 'access_granted_by',
      header: t('ACCESS GRANTED BY'),
      size: 1,
      accessorFn: (row) => (
        <div>
          {row.access_granted_at
            ? formatDateToLocalTime(row.access_granted_at)
            : '-'}
        </div>
      ),
    },
    {
      accessorKey: 'action',
      accessorFn: (row) => (
        <div>
          <OutlineTextButton
            text={'Remove'}
            bgColor={'#fff'}
            color={'var(--primary-600)'}
            outlineColor={'var(--primary-600)'}
            // bgColor={
            //   disableInviteButton
            //     ? 'var(--primary-300)' // Use primary-300 for the disable color
            //     : 'var(--primary-600)'
            // }
            // disabled={disableInviteButton}
            // onClick={() => {
            //   deleteProjectById(row.id)
            // }}
            onClick={() => {
              handleOpenRemoveMemberConfirmDialog(row)
            }}
            style={{
              width: '71px',
              height: '34px',
              fontSize: '12px',
              fontWeight: '500',
              lineHeight: '18px',
            }}
          />
        </div>
      ),
      header: t('ACTIONS'),
      maxSize: 40,
      grow: true,
    },
  ]

  const handleCheckboxChange = (name: any) => {
    if (selectedMembers.includes(name)) {
      setSelectedMembers(selectedMembers?.filter((member) => member !== name))
    } else {
      setSelectedMembers([...selectedMembers, name])
    }
  }
  const handleGetMembersList = (status) => {
    status === 'active-members'
      ? setIsActiveMembersLoading(true)
      : setIsNonActiveMembersLoading(true)
    const params: any = {}
    if (status === 'active-members') {
      params.page_size = rowsPerPage
      params.page = page + 1
    }
    getMembersList(projectId, status, params)
      .then((res: any) => {
        if (res.status === 200) {
          if (status === 'active-members') {
            setActiveMembers(res.data.data)
            setIsActiveMembersLoading(false)
          } else {
            setNonActiveMembers(res.data.data)
            setSearchedMember(res.data.data)
            setIsNonActiveMembersLoading(false)
          }
        }
      })
      .catch((error) => {
        setIsNonActiveMembersLoading(false)
        setIsActiveMembersLoading(false)
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }
  const handleAddMembers = () => {
    const payload = {
      user_ids: selectedMembers,
    }
    assignUsersToProject(projectId, payload)
      .then((res) => {
        if (res.status === 200) {
          setSelectedMembers([])
          handleGetMembersList('active-members')
          enqueueSnackbar(`${res?.data?.detail}`, {
            variant: 'success',
            persist: true,
          })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }

  const handleRemoveMember = (id) => {
    removeUserToProject(projectId, id)
      .then((res) => {
        if (res.status === 200) {
          handleGetMembersList('active-members')
          enqueueSnackbar(`${res?.data?.detail}`, {
            variant: 'success',
            persist: true,
          })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }

  const handleMemberSearch = (value: string) => {
    setSearchMemberTerm(value)
    const filteredProjects = nonActiveMembers.filter((member) =>
      member.name.toLowerCase().includes(value.toLowerCase()),
    )
    setSearchedMember(filteredProjects)
  }

  return (
    <div className="tabs-wrapper">
      <div className="search-bar-container">
        <div className="filters">
          <TextField
            id="search-projects"
            placeholder={t('Search Members')}
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleSearchChange}
            style={{
              width: '500px',
              flex: 1,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MagniferIcon />
                </InputAdornment>
              ),
              style: {
                borderTopLeftRadius: '8px',
                borderBottomLeftRadius: '8px',
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
              },
            }}
          />
          <TextButton
            text={t('Search')}
            color={'var(--gray-white)'}
            bgColor={searchTerm ? 'var(--primary-600)' : 'var(--primary-300)'}
            disabled={!searchTerm}
            style={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              // To create the effect of the button being attached to the search bar
            }}
          />
        </div>

        <div>
          <ButtonIcon
            text={t('Add Members')}
            color={'var(--gray-white)'}
            IconComponent={AddIcon}
            bgColor={'var(--primary-600)'}
            onClick={handleOpenAddMemeber}
          />
        </div>
      </div>

      <div className="tabs-table-container">
        {isActiveMembersLoading ? (
          <div style={{ padding: '15rem' }}>
            <CircularProgress />
          </div>
        ) : (
          <TableComponent
            rows={activeMembers}
            columns={columnsMembers}
            sorting={[]}
          />
        )}
      </div>
      {activeMembers.length > 0 && !isActiveMembersLoading && (
        <TablePagination
          component="div"
          page={page}
          count={rowCount}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 20, 30]}
          labelRowsPerPage={t('Items per page:')}
        />
      )}
      <ActionDialog
        open={isAddMembersOpen}
        title={t('Add Members')}
        onClose={handleCloseAddMemeber}
      >
        <Box>
          <Typography
            variant="h6"
            fontWeight={'600'}
            fontSize={'19px'}
            color={'var(--gray-600)'}
          >
            {t('Search Members')}
          </Typography>
          <Box
            sx={{
              width: '100%',
              height: '250px',
              borderRadius: '8px',
              margin: '10px 0',
              overflowY: 'hidden',
              border: '1px solid var(--gray-200)',
            }}
          >
            <TextField
              placeholder={t('Search Members')}
              variant="standard"
              size="small"
              fullWidth
              value={searchMemberTerm}
              onChange={(e) => {
                handleMemberSearch(e.target.value)
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MagniferIcon />
                  </InputAdornment>
                ),
              }}
              sx={{
                border: 'none',
                '& fieldset': { border: 'none' },
                '& .MuiInput-root::before': {
                  border: 'none !important',
                },
                '& .MuiInput-root::after': {
                  border: 'none !important',
                },
                borderBottom: '1px solid var(--gray-200) !important',
                background: 'var(--gray-50)',
                '& input::placeholder': {
                  fontSize: '15px',
                },
              }}
            />
            <List sx={{ overflowY: 'auto', height: '200px' }}>
              {isNonActiveMembersLoading ? (
                <CircularProgress
                  size={20}
                  sx={{
                    position: 'absolute',
                    left: '50%',
                    top: '40%',
                    transform: 'translate(-60%, -50%)',
                  }}
                />
              ) : (
                <>
                  {searchedMember.length > 0 &&
                    searchedMember.map((member, index) => (
                      <Stack
                        direction={'row'}
                        sx={{
                          paddingLeft: '5px !important',
                        }}
                        key={index}
                        justifyContent={'space-between'}
                        mr={1}
                        alignItems={'center !important'}
                        marginBottom={'5px !important'}
                      >
                        <Stack direction={'row'} alignItems={'center'}>
                          <Checkbox
                            checkedIcon={<CheckedIcon />}
                            icon={<DefaultCheckIcon />}
                            checked={selectedMembers.includes(member.id)}
                            onChange={() => {
                              handleCheckboxChange(member.id)
                            }}
                          />
                          <ListItemText
                            sx={{
                              color: 'var(--gray-900) !important',
                              '& .MuiTypography-root': {
                                fontSize: '14px !important',
                              },
                            }}
                            primary={member.name}
                          />
                        </Stack>
                        <div>
                          <Typography
                            sx={{
                              color: 'var(--gray-700) !important',
                              fontSize: '14px !important',
                            }}
                          >
                            {member.role.name}
                          </Typography>
                        </div>
                      </Stack>
                    ))}
                </>
              )}
            </List>
          </Box>

          <TextButton
            text={t('Add')}
            color={'#fff'}
            bgColor={
              disableAddBtn
                ? 'var(--primary-300)' // Use primary-300 for the disable color
                : 'var(--primary-600)'
            }
            disabled={disableAddBtn}
            onClick={() => {
              handleCloseAddMemeber()
              handleAddMembers()
            }}
            style={{
              width: '100%',
              height: '42px',
              fontSize: '14px',
              textTransform: 'capitalize',
              boxShadow: 'none',
              padding: '8px 0',
              marginTop: '10px',
            }}
          />
        </Box>
      </ActionDialog>
      {/* Assign Project ends here */}
      <CancelConformationFormDialog
        open={confirmationDialogState?.open}
        onClose={handleCloseRemoveMemberConfirmDialog}
        message={`Are you sure, you want to remove ${confirmationDialogState.value?.name} (${confirmationDialogState.value?.email}?`}
      ></CancelConformationFormDialog>
    </div>
  )
}

export default MembersTabs

import React from 'react'
import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'

interface OutlineTextButtonProps {
  text: string
  bgColor?: string
  outlineColor?: string
  color: string
  style?: React.CSSProperties // Changed from string to React.CSSProperties
  disabled?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void // Accept onClick props
}

const StyledButton = styled(Button)<{
  bgcolor?: string
  textcolor?: string
  outlinecolor?: string
}>(({ theme, bgcolor, textcolor, outlinecolor }) => ({
  backgroundColor: bgcolor ?? '#fff',
  border: `1px solid ${outlinecolor}` ?? 'var(--primary-600)',
  color: `${textcolor} !important`,
  borderRadius: '8px',
  width: 'fit-content',
  fontWeight: '500',
  height: '40px',
  padding: '10px auto',
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: bgcolor ?? 'var(--primary-300)',
    opacity: 0.9,
  },

  '&:disabled': {
    backgroundColor: 'white',
    color: 'var(--primary-600) !important',
    cursor: 'not-allowed',
    opacity: 0.7,
  },
}))

const OutlineTextButton: React.FC<OutlineTextButtonProps> = ({
  text,
  bgColor,
  outlineColor,
  color,
  style,
  disabled = false,
  onClick, // Destructure onClick from props
}) => {
  return (
    <StyledButton
      bgcolor={bgColor}
      outlinecolor={outlineColor}
      textcolor={color}
      disabled={disabled}
      style={style}
      onClick={onClick} // Pass onClick to the StyledButton
    >
      {text}
    </StyledButton>
  )
}

export default OutlineTextButton
